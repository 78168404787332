import 'src/index.scss';
import 'src/logger';

import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { KatSpinner } from '@amzn/katal-react';
import { MbmProvider } from '@amzn/react-arb-tools';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from 'src/components/App/App';
import { DEFAULT_LOCALE, LOCALE_LOCAL_STORAGE_KEY } from 'src/localization/locales';
import { resolveBundleUrlWithFallbacks } from 'src/localization/localization';
import { store } from 'src/store/store';
import arbManifest from 'translations/arbManifest';

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: new LocalizationContextBuilder()
    .withLocale(localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY) || DEFAULT_LOCALE)
    .withDefaultLocale(DEFAULT_LOCALE)
    .build(),
  resolveBundleUrl: (resourcePackPath: string | undefined, locale: string, bundle: string) =>
    resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback={<KatSpinner size='large' />}>
        <MbmProvider {...mbmOptions}>
          <App />
        </MbmProvider>
      </React.Suspense>
    </Provider>
  </React.StrictMode>,
);
