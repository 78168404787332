import React, { useEffect, useState } from 'react';
import { getCampaignData, isCampaignExpired } from 'src/api/campaign';
import ErrorModal from 'src/components/ErrorModal/ErrorModal';
import LoadingSpinnerWrapper from 'src/components/LoadingSpinner/LoadingSpinnerWrapper';
import { Campaign } from 'src/models/campaign';
import ExpiredCampaignPage from 'src/pages/ExpiredCampaignPage/ExpiredCampaignPage';
import ProductDetailPage from 'src/pages/ProductDetailPage/ProductDetailPage';

const BaseCampaignPage = () => {
  // Get campaign ID from URL
  // .filter(Boolean) handles cases with trailing slash and query params
  const campaignId = window.location.pathname.split('/').filter(Boolean).pop();
  if (!campaignId) {
    return <ErrorModal campaignId={'NO-CAMPAIGN-ID'} errorCodes={['CAMPAIGN_NOT_FOUND']} />;
  }

  // Get campaign data from remote file
  const [campaignData, setCampaignData] = useState<Campaign>();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    getCampaignData(campaignId)
      .then((data) => {
        setCampaignData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = campaignData?.artist.name ?? document.title;
  }, [campaignData]);

  // Add a loading spinner while fetching data
  // This prevents the user from seeing the error page while campaignData is undefined
  if (isLoading) {
    return <LoadingSpinnerWrapper />;
  }

  if (!campaignData) {
    return <ErrorModal campaignId={campaignId} errorCodes={['CAMPAIGN_NOT_FOUND']} />;
  }

  // Check if campaign has expired
  if (isCampaignExpired(campaignData)) {
    return <ExpiredCampaignPage />;
  }

  // Check type of campaign and render appropriate type of page
  const campaignType = campaignData.benefits[0].type;
  if (campaignType === 'MERCH') {
    return <ProductDetailPage campaign={campaignData} />;
  } else {
    return <ErrorModal campaignId={campaignId} errorCodes={['CAMPAIGN_NOT_FOUND']} />;
  }
};

export default BaseCampaignPage;
