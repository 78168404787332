import React from 'react';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const artistCelebrationBoxStyle: React.CSSProperties = {
  backgroundColor: BauhausColor.secondary.solid,
  borderRadius: BauhausBorder.radius.XL,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: BauhausSpacing.large,
  height: 480,
  maxWidth: 320,
  maxHeight: '100%',
  margin: 'auto',
};

export const celebrationTitleContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const artistLogoContainerStyle: React.CSSProperties = {
  display: 'flex',
  padding: BauhausSpacing.base,
  justifyContent: 'center',
  height: 40,
  width: 400,
  maxWidth: '100%',
};
