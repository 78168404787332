import { useLocalizationContext } from '@amzn/react-arb-tools';
import { BauhausColor } from 'src/bauhaus/color';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import Modal from 'src/components/Modal/Modal';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { DEFAULT_LOCALE } from 'src/localization/locales';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName, PageName } from 'src/metrics/constants';
import { goToAmazonLogin } from 'src/navigation/sites';

import { Spacer } from '../Spacer/Spacer';

export interface EligibilityInfoModalProps {
  campaignId: string;
  onClose: () => void;
}

const EligibilityInfoModal = (props: EligibilityInfoModalProps) => {
  const { localizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;

  const bundle = useStringsBundle();

  return (
    <Modal
      onClose={props.onClose}
      metrics={{
        pageName: PageName.ELIGIBILITY_INFO_MODAL,
        campaignId: props.campaignId,
      }}
    >
      <Text
        text={bundle.getMessage(StringIds.EligibilityInfoTitle)}
        preset={TextPreset.HEADLINE_4}
        color={BauhausColor.primary.solid}
        textAlign='center'
        testId={formatTestId(TestIds.Modal, TestIds.TitleText)}
      />
      <Spacer size='base' />
      <Text
        text={bundle.getMessage(StringIds.EligibilityInfoTextTopListener)}
        preset={TextPreset.BODY_1}
        color={BauhausColor.primary.solid}
        textAlign='center'
        testId={formatTestId(TestIds.Modal, TestIds.BodyText)}
      />
      <Spacer size='base' />
      <Button
        text={bundle.getMessage(StringIds.CtaSignInWithAmazonMusic)}
        onClick={() => {
          goToAmazonLogin(currentLocale);
        }}
        fullWidth
        testId={formatTestId(TestIds.Modal, TestIds.CtaButton)}
        metrics={{
          actionName: ActionName.GO_SIGN_IN,
          elementName: ElementName.SIGN_IN_BUTTON,
        }}
      />
      <Spacer size='base' />
      <Button
        text={bundle.getMessage(StringIds.CtaCancel).toUpperCase()}
        onClick={() => {
          props.onClose();
        }}
        preset={ButtonPreset.TRANSPARENT}
        testId={formatTestId(TestIds.Modal, TestIds.CancelButton)}
        metrics={{
          actionName: ActionName.CLOSE_MODAL,
          elementName: ElementName.CANCEL_BUTTON,
        }}
      />
    </Modal>
  );
};

export default EligibilityInfoModal;
