import { SVGProps } from 'react';

const CaretRightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M5.0005 21.9996C5.0005 21.4876 5.19649 20.9756 5.58647 20.5856L14.1721 12L5.58648 3.41436C4.80451 2.63239 4.80451 1.36844 5.58648 0.586475C6.36844 -0.195492 7.63239 -0.195492 8.41436 0.586476L18.4139 10.5861C19.1959 11.368 19.1959 12.632 18.4139 13.4139L8.41436 23.4135C7.63239 24.1955 6.36844 24.1955 5.58647 23.4135C5.19649 23.0235 5.0005 22.5116 5.0005 21.9996Z'
        fill={props.fill}
      />
    </svg>
  );
};
export default CaretRightIcon;
