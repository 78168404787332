import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { logger } from 'src/logger';
import { AmazonLoginCookies, isUserAuthenticated } from 'src/navigation/auth';
import type { RootState } from 'src/store/store';

export type Status = 'IDLE' | 'LOADING' | 'COMPLETE';

export interface AuthState {
  status: Status;
  sessionId?: string;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  status: 'IDLE',
  sessionId: undefined,
  isAuthenticated: false,
};

export const checkAuthState = createAsyncThunk('auth/checkAuthState', async () => {
  try {
    const sessionId: string | undefined = Cookies.get(AmazonLoginCookies.sessionId);
    const isAuthenticated = isUserAuthenticated();
    return { sessionId, isAuthenticated };
  } catch (error) {
    await logger.error(`Error checking auth state: ${error}`);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    replaceAuthState: (state, action: PayloadAction<AuthState>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthState.pending, (state) => {
        return {
          ...state,
          status: 'LOADING',
        };
      })
      .addCase(checkAuthState.fulfilled, (state, action) => {
        return {
          status: 'COMPLETE',
          sessionId: action?.payload?.sessionId,
          isAuthenticated: action?.payload?.isAuthenticated ?? false,
        };
      })
      .addCase(checkAuthState.rejected, (state) => {
        return {
          ...state,
          status: 'COMPLETE',
          sessionId: undefined,
          isAuthenticated: false,
        };
      });
  },
});

export const { replaceAuthState } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
