import { SVGProps } from 'react';

const VisitExternalIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 24' fill='current'>
      <path
        d='M23.8414 0.158899C23.7094 0.0268965 23.5206 -0.0254679 23.3374 0.0116236L13.519 2.19348C13.3248 2.23711 13.1699 2.38221 13.1132 2.57421C13.0576 2.76512 13.111 2.97131 13.2518 3.11204L16.2987 6.15899L0.319914 22.1378C-0.106638 22.5643 -0.106638 23.2538 0.319914 23.6804C0.532645 23.8931 0.811922 24 1.0912 24C1.37048 24 1.64975 23.8931 1.86248 23.6804L17.8413 7.70265L20.8882 10.7496C20.9908 10.8532 21.1304 10.9089 21.2733 10.9089C21.3246 10.9089 21.3759 10.9023 21.4261 10.8871C21.6181 10.8314 21.7632 10.6765 21.8068 10.4812L23.9887 0.662906C24.0279 0.480722 23.9734 0.290901 23.8414 0.158899Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default VisitExternalIcon;
