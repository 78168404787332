import React from 'react';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export type OrderSummaryItemPosition = 'top' | 'middle' | 'bottom';

export const orderSummaryItemContainerStyle = (
  position: OrderSummaryItemPosition = 'middle',
): React.CSSProperties => {
  const borderRadiusByPosition: Record<OrderSummaryItemPosition, string> = {
    top: `${BauhausBorder.radius.MD}px ${BauhausBorder.radius.MD}px 0 0`,
    middle: '0',
    bottom: `0 0 ${BauhausBorder.radius.MD}px ${BauhausBorder.radius.MD}px`,
  };

  return {
    display: 'flex',
    gap: BauhausSpacing.huge,
    padding: BauhausSpacing.medium,
    borderWidth: BauhausBorder.width.SM,
    borderColor: BauhausColor.primary.glass2,
    borderStyle: 'solid',
    borderRadius: borderRadiusByPosition[position],
  };
};

export const orderTotalContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const orderChargesItemStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: BauhausSpacing.small,
};
