import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import { getTextPresetStyle } from 'src/components/Text/style';
import { TextPreset } from 'src/components/Text/Text';

export const $legalFooterStyle: React.CSSProperties = {
  ...getTextPresetStyle(TextPreset.BODY_2),
  color: BauhausColor.primary.glass4,
  textAlign: 'center',
  marginTop: BauhausSpacing.huge,
  marginBottom: BauhausSpacing.huge,
};
