import { useLocalizationContext } from '@amzn/react-arb-tools';
import { ClickAwayListener, IconButton, ListItemIcon, Popper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import SelectionIcon from 'src/assets/icons/SelectionIcon';
import ThreeDotsIcon from 'src/assets/icons/ThreeDotsIcon';
import { BauhausColor } from 'src/bauhaus/color';
import { languageSelectorOptionImageStyle } from 'src/components/LanguageSelector/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { $menuDividerStyle, $menuOptionStyle, $menuStyle } from 'src/components/TopBarMenu/style';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import {
  DEFAULT_LOCALE,
  LOCALE_LOCAL_STORAGE_KEY,
  LocaleOption,
  LocaleOptions,
} from 'src/localization/locales';
import { getLocalizationContext } from 'src/localization/localization';
import { ActionName, ElementName } from 'src/metrics/constants';
import { getSignOutUrl } from 'src/navigation/auth';

export interface TopBarMenuProps {
  isLoggedIn?: boolean;
}

export const TopBarMenu = ({ isLoggedIn }: TopBarMenuProps) => {
  const elementName = ElementName.MENU;
  const { uiContentView, uiClick } = useUiMetrics();
  uiContentView({ elementName });

  const [showMenu, setShowMenu] = useState(false);

  const { localizationContext, setLocalizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;

  const changeLocale = (localeOption: LocaleOption) => {
    const locale = localeOption.value;
    if (locale !== currentLocale) {
      setLocalizationContext(getLocalizationContext(locale));
      localStorage.setItem(LOCALE_LOCAL_STORAGE_KEY, locale);
      setShowMenu(false);
      uiClick({
        elementName,
        actionType: ActionName.SELECT_LANGUAGE,
        itemText: locale,
      });
    }
  };

  const signOut = () => {
    window.location.replace(getSignOutUrl(currentLocale));
  };

  // The anchorRef serves as a point of reference from where the menu should be displayed
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  return (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <>
        <IconButton
          data-testid={formatTestId(TestIds.Menu, TestIds.Button)}
          onClick={() => {
            uiClick({
              elementName: elementName,
              actionType: ActionName.OPEN_MENU,
            });
            setShowMenu(!showMenu);
          }}
          ref={anchorRef}
        >
          <ThreeDotsIcon fill={BauhausColor.primary.solid} />
        </IconButton>
        <Popper
          style={$menuStyle}
          anchorEl={anchorRef.current}
          open={showMenu}
          data-testid={formatTestId(TestIds.Menu)}
          placement={'bottom-end'}
        >
          {LocaleOptions.map((locale) => (
            <MenuItem
              key={locale.value}
              style={$menuOptionStyle}
              onClick={() => changeLocale(locale)}
              data-testid={formatTestId(TestIds.Menu, TestIds.Option, locale.value)}
            >
              <Text preset={TextPreset.BODY_2} text={locale.label} />
              <ListItemIcon>
                <SelectionIcon
                  fill={BauhausColor.secondary.solid}
                  style={languageSelectorOptionImageStyle(locale.value === currentLocale)}
                />
              </ListItemIcon>
            </MenuItem>
          ))}
          {isLoggedIn && (
            <>
              <div style={$menuDividerStyle} />
              <MenuItem
                style={$menuOptionStyle}
                onClick={signOut}
                data-testid={formatTestId(TestIds.Menu, TestIds.Option, 'SignOut')}
              >
                <Text preset={TextPreset.BODY_2} text={'Sign out'} />
              </MenuItem>
            </>
          )}
        </Popper>
      </>
    </ClickAwayListener>
  );
};
