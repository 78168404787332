/**
 * IconButton and Button are separate because they have different preset styles.
 * If the styles become unified we can merge the components into one.
 */

import { BauhausColor } from 'src/bauhaus/color';
import { BauhausIconType } from 'src/bauhaus/iconography';
import Icon from 'src/components/Icon/Icon';
import { getIconButtonStyle, getIconSize, IconButtonSize } from 'src/components/IconButton/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ActionName, ElementName } from 'src/metrics/constants';

export type IconButtonSizeType = keyof typeof IconButtonSize;

export interface IconButtonProps {
  icon: BauhausIconType;
  onClick: () => void;
  size?: IconButtonSizeType;
  testId?: string;
  metrics?: {
    elementName: ElementName;
    actionName?: ActionName;
  };
}

const IconButton = (props: IconButtonProps) => {
  const elementName = props.metrics?.elementName ?? ElementName.ICON_BUTTON;
  const { uiClick, uiContentView } = useUiMetrics();
  uiContentView({ elementName });

  const iconButtonSizeType = props.size ?? 'MD';

  const testId = props.testId ?? TestIds.IconButton;
  return (
    <button
      style={getIconButtonStyle(iconButtonSizeType)}
      onClick={() => {
        props.onClick();
        uiClick({
          actionType: props.metrics?.actionName ?? ActionName.NONE,
          elementName,
        });
      }}
      data-testid={formatTestId(testId)}
    >
      <Icon
        icon={props.icon}
        color={BauhausColor.primary.solid}
        size={getIconSize(iconButtonSizeType)}
        testId={formatTestId(testId, TestIds.Icon)}
      />
    </button>
  );
};

export default IconButton;
