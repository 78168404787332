import { useLocalizationContext } from '@amzn/react-arb-tools';
import React, { Ref } from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import LegalFooter from 'src/components/LegalFooter/LegalFooter';
import SelectionBox from 'src/components/SelectionBox/SelectionBox';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { DEFAULT_LOCALE } from 'src/localization/locales';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName, EntityIdType, EntityType } from 'src/metrics/constants';
import { Product, ProductAvailabilityType, Variation } from 'src/models/product';
import { formatMonetaryValue } from 'src/models/utils';
import { goToAmazonAccount } from 'src/navigation/sites';
import {
  $sheetContainerStyle,
  $sheetHeaderStyle,
} from 'src/pages/ProductDetailPage/ActionSheet/style';

export interface ProductSelectionSheetProps {
  product: Product;
  quantity: number;
  onChangeQuantity: (quantity: number) => void;
  variation?: Variation;
  onChangeVariation?: (variation: Variation) => void;
  onClickBackButton: () => void;
  onClickCtaButton: () => void;
  ref?: Ref<HTMLDivElement>;
}

const ProductSelectionSheet = ({
  product,
  quantity,
  onChangeQuantity,
  variation,
  onChangeVariation,
  onClickBackButton,
  onClickCtaButton,
  ref,
}: ProductSelectionSheetProps) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({
    elementName: ElementName.ACTION_SHEET_SELECT_PRODUCT,
    entityId: product.asin,
    entityIdType: 'ASIN' as EntityIdType,
    entityType: 'MERCH' as EntityType,
  });

  const { localizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;
  const bundle = useStringsBundle();

  return (
    <div
      style={$sheetContainerStyle}
      data-testid={formatTestId(TestIds.ProductSelectionSheet)}
      ref={ref}
    >
      <div style={$sheetHeaderStyle}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: BauhausSpacing.base,
          }}
        >
          <Icon
            icon={'goBack'}
            color={BauhausColor.primary.solid}
            size={'SM'}
            onClick={onClickBackButton}
            testId={formatTestId(TestIds.ProductSelectionSheet, TestIds.GoBackIcon)}
          />
          <Text
            text={product.title}
            preset={TextPreset.HEADLINE_4}
            testId={formatTestId(TestIds.ProductSelectionSheet, 'ProductTitleText')}
          />
        </div>
        {product.price && (
          <Text
            text={formatMonetaryValue(currentLocale, product.price)}
            preset={TextPreset.HEADLINE_4}
            testId={formatTestId(TestIds.ProductSelectionSheet, 'ProductPriceText')}
          />
        )}
      </div>
      <SelectionBox
        productVariations={product.productVariations}
        maxQuantity={product.quantity.maxOrderQuantity}
        selectedQuantity={quantity}
        onChangeQuantity={onChangeQuantity}
        selectedVariation={variation}
        onChangeVariation={onChangeVariation}
      />
      <Spacer size={'huge'} />
      {product.availability.type === ProductAvailabilityType.OUT_OF_STOCK ? (
        <>
          <Text
            text={
              product.availability.primaryMessage ?? bundle.getMessage(StringIds.ProductUnavailable)
            }
            preset={TextPreset.BODY_1}
            testId={formatTestId(TestIds.ProductSelectionSheet, 'ProductUnavailableText')}
          />
          <Button
            text={bundle.getMessage(StringIds.CtaChangeCheckoutInfo).toLocaleUpperCase()}
            textColor={BauhausColor.accent.solid}
            preset={ButtonPreset.TRANSPARENT}
            horizontalPadding={0}
            onClick={() => {
              goToAmazonAccount('_blank');
            }}
            testId={formatTestId(TestIds.ProductSelectionSheet, TestIds.ChangeCheckoutInfoButton)}
            metrics={{
              actionName: ActionName.GO_AMZN_ACCOUNT,
              elementName: ElementName.CHANGE_CHECKOUT_INFO_BUTTON,
            }}
          />
        </>
      ) : (
        <Button
          preset={ButtonPreset.SOLID}
          size={'LG'}
          horizontalPadding={BauhausSpacing.large}
          text={bundle.getMessage(StringIds.CtaCheckoutWithAmazon)}
          onClick={onClickCtaButton}
          testId={formatTestId(TestIds.ProductSelectionSheet, TestIds.CheckoutButton)}
          metrics={{
            actionName: ActionName.GO_ORDER_CHECKOUT,
            elementName: ElementName.CHECKOUT_BUTTON,
          }}
        />
      )}
      <LegalFooter />
    </div>
  );
};

export default ProductSelectionSheet;
