import 'src/pages/ProductDetailPage/ProductDetailPage.css';

import { useLocalizationContext } from '@amzn/react-arb-tools';
import React, { useEffect, useState } from 'react';
import { isUserEligibleForCampaign } from 'src/api/eligibility';
import { getProductDetails } from 'src/api/shopping';
import ArtistTopBar from 'src/components/ArtistTopBar/ArtistTopBar';
import DescriptionBox from 'src/components/DescriptionBox/DescriptionBox';
import ErrorModal from 'src/components/ErrorModal/ErrorModal';
import { ImageBackground } from 'src/components/ImageBackground/ImageBackground';
import ImageCarousel from 'src/components/ImageCarousel/ImageCarousel';
import LoadingSpinnerWrapper from 'src/components/LoadingSpinner/LoadingSpinnerWrapper';
import { formatTestId, PageTestIds } from 'src/components/testIds';
import { UiMetricsWrapper } from 'src/components/UiMetricsWrapper/UiMetricsWrapper';
import { DEFAULT_LOCALE } from 'src/localization/locales';
import { PageName } from 'src/metrics/constants';
import { Campaign, MerchBenefit } from 'src/models/campaign';
import { ErrorCode, transformToErrorCodes } from 'src/models/error';
import { Product, Variation } from 'src/models/product';
import { formatMonetaryValue } from 'src/models/utils';
import { isUserAuthenticated } from 'src/navigation/auth';
import ActionSheet from 'src/pages/ProductDetailPage/ActionSheet/ActionSheet';
import {
  $productContainerStyle,
  $productContentStyle,
  $productDescriptionStyle,
} from 'src/pages/ProductDetailPage/style';

export interface ProductDetailPageProps {
  campaign: Campaign;
}

const ProductDetailPage = ({ campaign }: ProductDetailPageProps) => {
  const { localizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;

  // User experience variables
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showProductDetailsLoading, setShowProductDetailsLoading] = useState<boolean>(false);
  const [showEligibilityLoading, setShowEligibilityLoading] = useState<boolean>(false);
  const [initialVariantsSelected, setInitialVariantsSelected] = useState<boolean>(false);

  // User access variables
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isUserAuthenticated());
  const [isEligible, setEligible] = useState<boolean>(false);

  // Product variables
  const [product, setProduct] = useState<Product>();
  const [productVariation, setProductVariation] = useState<Variation>();
  const [errorCodes, setErrorCodes] = useState<ErrorCode[] | null>();

  // Check for user access once at page load
  useEffect(() => {
    if (isLoggedIn) {
      setShowEligibilityLoading(true);
      isUserEligibleForCampaign(campaign.campaignId)
        .then((response) => {
          setEligible(response);
        })
        .catch((error) => {
          const errorCodes = transformToErrorCodes(error);
          if (errorCodes.includes('ACCESS_DENIED')) {
            setIsLoggedIn(false);
          } else {
            handleError(error);
          }
        })
        .finally(() => {
          setShowEligibilityLoading(false);
        });
    }
  }, []);

  // Reload product details on login or locale change
  useEffect(() => {
    callGetProductDetails();
  }, [isLoggedIn, currentLocale]);

  // Set the default variation after the product is loaded
  useEffect(() => {
    if (product && !initialVariantsSelected) {
      // Default variant logic:
      // Select the first variant that matches the initial product ASIN (i.e. the campaign benefit ASIN),
      // If there is no match, select the first variant by index.
      const variations = product?.productVariations;
      const selectedVariation = variations?.variations?.find(
        (variation) => variation.asin === product?.asin,
      );
      setProductVariation(selectedVariation ?? variations?.variations?.[0]);
    }
  }, [product]);

  // Call GetProductDetails to update the information if product variation changes
  useEffect(() => {
    if (productVariation) {
      // Don't reload when setting the initial variant selections.
      if (!initialVariantsSelected) {
        setInitialVariantsSelected(true);
      } else {
        callGetProductDetails();
      }
    }
  }, [productVariation]);

  // Calls getProductDetails using the current selected product state
  const callGetProductDetails = () => {
    setShowProductDetailsLoading(true);
    getProductDetails(
      currentLocale,
      productVariation?.asin ?? (campaign.benefits[0] as MerchBenefit).asin,
      isLoggedIn,
      productVariation?.customId,
      productVariation?.customizationToken,
    )
      .then((response) => {
        setProduct(response);
      })
      .catch((error) => handleError(error))
      .finally(() => setShowProductDetailsLoading(false));
  };

  const handleOnChangeProductVariation = (variation: Variation) => {
    setProductVariation(variation);
  };

  const handleError = (error: any) => {
    setShowErrorModal(true);
    setErrorCodes(transformToErrorCodes(error));
  };

  return (
    <UiMetricsWrapper
      pageName={PageName.PRODUCT_DETAIL_PAGE}
      campaignId={campaign.campaignId}
      refMarker={new URLSearchParams(window.location.search)?.get('ref') ?? undefined}
      isLoading={false}
    >
      <ImageBackground image={campaign.artist.images.backgroundUrl} blur={'blur1'} />
      {(showEligibilityLoading || showProductDetailsLoading) && <LoadingSpinnerWrapper />}
      {product && (
        <div
          style={$productContainerStyle}
          className={'product-detail'}
          data-testid={PageTestIds.ProductDetailPage}
        >
          <ArtistTopBar artist={campaign.artist} isLoggedIn={isLoggedIn} />
          <div style={$productContentStyle}>
            <ImageCarousel
              asin={product.asin}
              images={product.productImages.images.map((image) => image.url)}
              height={400}
              showNavigationButtons={product?.productImages.images.length > 1}
            />
            <div style={$productDescriptionStyle}>
              <DescriptionBox
                title={product.title}
                subtitle={
                  product.price ? formatMonetaryValue(currentLocale, product.price) : undefined
                }
                text={product.shortDescriptionBullets}
                primeBadge={product.primeBadge}
                expandable
              />
              {campaign.additionalContent && campaign.additionalContent[currentLocale] && (
                <div
                  className={'product-detail__additional-content'}
                  dangerouslySetInnerHTML={{
                    __html: decodeURI(campaign.additionalContent[currentLocale]),
                  }}
                  data-testid={formatTestId(PageTestIds.ProductDetailPage, 'AdditionalContent')}
                />
              )}
            </div>
          </div>
          <ActionSheet
            campaign={campaign}
            product={product}
            productVariation={productVariation}
            onChangeVariation={handleOnChangeProductVariation}
            onError={handleError}
            isLoggedIn={isLoggedIn}
            isEligible={isEligible}
          />
        </div>
      )}
      {showErrorModal && errorCodes && (
        <ErrorModal
          campaignId={campaign.campaignId}
          errorCodes={errorCodes}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </UiMetricsWrapper>
  );
};

export default ProductDetailPage;
