import { SVGProps } from 'react';

const JumpBack15Icon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M5.67209 4.19727C7.42322 2.78636 9.60056 2 11.9206 2C17.432 2 21.9167 6.48636 21.9167 12C21.9167 17.5136 17.432 22 11.9206 22C8.74634 22 5.83203 20.5418 3.92368 18C3.62198 17.5991 3.70377 17.0291 4.10452 16.7273C4.50618 16.4264 5.07505 16.5073 5.37675 16.9082C6.93887 18.9891 9.3243 20.1818 11.9206 20.1818C16.4306 20.1818 20.0992 16.5118 20.0992 12C20.0992 7.48909 16.4306 3.81818 11.9206 3.81818C10.0922 3.81818 8.37558 4.42 6.97067 5.49636L8.15203 6.67818C8.27017 6.79727 8.31379 6.97091 8.26562 7.13182C8.21746 7.29182 8.08478 7.41273 7.9203 7.44545L3.37662 8.35455C3.34663 8.36091 3.31755 8.36364 3.28757 8.36364C3.16852 8.36364 3.0522 8.31636 2.96587 8.23091C2.85864 8.12273 2.8123 7.96909 2.84228 7.81909L3.75102 3.27364C3.78374 3.11 3.9046 2.97636 4.06454 2.92818C4.2272 2.87909 4.39986 2.92364 4.51799 3.04182L5.67209 4.19727Z'
        fill={props.fill}
      />
      <path
        d='M9.54927 14.7273H10.3508C10.4871 14.7273 10.5552 14.6591 10.5552 14.5227V9.26181C10.5552 9.12545 10.4871 9.05727 10.3508 9.05727H9.67195C9.60107 9.05727 9.53836 9.06272 9.48384 9.07363C9.43477 9.08454 9.37479 9.10636 9.30391 9.13908L8.06894 9.70363C7.99806 9.73636 7.94899 9.77181 7.92172 9.80999C7.89991 9.84818 7.88901 9.90545 7.88901 9.98181V10.5218C7.88901 10.6254 7.92718 10.6773 8.00351 10.6773C8.04168 10.6773 8.11528 10.6582 8.22433 10.62L9.3448 10.2518V14.5227C9.3448 14.6591 9.41296 14.7273 9.54927 14.7273Z'
        fill={props.fill}
      />
      <path
        d='M12.5667 14.58C13.0356 14.76 13.5345 14.85 14.0634 14.85C14.4996 14.85 14.8812 14.7736 15.2084 14.6209C15.5355 14.4627 15.7891 14.2418 15.969 13.9582C16.1544 13.6745 16.2471 13.3473 16.2471 12.9764C16.2471 12.6273 16.1707 12.3245 16.0181 12.0682C15.8654 11.8064 15.65 11.6045 15.3719 11.4627C15.0939 11.3209 14.7667 11.25 14.3905 11.25C14.276 11.25 14.1588 11.2582 14.0388 11.2745C13.9243 11.2854 13.8071 11.3045 13.6871 11.3318L13.7771 9.98999H15.7809C15.9172 9.98999 15.9853 9.92181 15.9853 9.78545V9.26181C15.9853 9.12545 15.9172 9.05727 15.7809 9.05727H12.9756C12.8393 9.05727 12.7657 9.1309 12.7548 9.27818L12.6239 11.4873V12.0027C12.6239 12.1391 12.6921 12.2073 12.8284 12.2073C12.8611 12.2073 12.902 12.2045 12.9511 12.1991C13.0056 12.1882 13.0819 12.18 13.1801 12.1745C13.2837 12.1636 13.4063 12.1554 13.5481 12.15C13.6899 12.1445 13.8289 12.1418 13.9652 12.1418C14.336 12.1418 14.6059 12.2127 14.7749 12.3545C14.9494 12.4909 15.0366 12.7064 15.0366 13.0009C15.0366 13.6009 14.6931 13.9009 14.0061 13.9009C13.7989 13.9009 13.5917 13.8818 13.3845 13.8436C13.1828 13.8054 12.9456 13.7482 12.673 13.6718C12.5912 13.65 12.5421 13.6391 12.5258 13.6391C12.4331 13.6391 12.3867 13.7018 12.3867 13.8273V14.3345C12.3867 14.4054 12.3976 14.4573 12.4195 14.49C12.4467 14.5227 12.4958 14.5527 12.5667 14.58Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default JumpBack15Icon;
