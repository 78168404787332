import React from 'react';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const orderDetailContainerStyle: React.CSSProperties = {
  display: 'flex',
  gap: BauhausSpacing.mini,
};

export const orderDetailContentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};
