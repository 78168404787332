import { SVGProps } from 'react';

const CaretLeftIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M18.9999 2.00042C18.9999 2.51239 18.8039 3.02437 18.4139 3.41436L9.8283 12L18.4139 20.5856C19.1959 21.3676 19.1959 22.6316 18.4139 23.4135C17.632 24.1955 16.368 24.1955 15.5861 23.4135L5.58648 13.4139C4.80451 12.632 4.80451 11.368 5.58648 10.5861L15.5861 0.586475C16.368 -0.195492 17.632 -0.195492 18.4139 0.586475C18.8039 0.976459 18.9999 1.48844 18.9999 2.00042Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default CaretLeftIcon;
