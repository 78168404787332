import React from 'react';
import AmazonMusicLogoImage from 'src/assets/images/am_logo_white.png';

const AmazonMusicLogo = () => {
  const $logoStyle: React.CSSProperties = {
    width: '100px',
    opacity: 0.3,
  };

  return (
    <div style={$logoStyle}>
      <img src={AmazonMusicLogoImage} alt={'Amazon Music logo'} width={'100%'} />
    </div>
  );
};

export default AmazonMusicLogo;
