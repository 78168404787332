export const MAX_RETRIES = 5;

// Refer to service SLA https://jira.music.amazon.dev/browse/ABW-1021
export const TIME_OUT_MILI_SEC = 10000;

/**
 * Website stage names (Beta|Gamma|Prod)
 */
export enum Stage {
  DEV = 'Dev',
  BETA = 'Beta',
  GAMMA = 'Gamma',
  PROD = 'Prod',
}

/**
 * Prod: Is a Katal deploy-time replacement property. This string is replaced with the website
 * stage during deployment. This defaults to map['Dev'] if Prod is not defined.
 *
 * LOCAL TESTING: Change default endpoints to ['Beta'], DO NOT COMMIT.
 *
 * @see https://katal.amazon.dev/build-and-deploy/tutorials/deployment-manifest-templating/
 */
export const getEndpointForStage = (endpoints: Record<Stage, string>) => {
  return endpoints['Prod' as Stage] || endpoints['Dev'];
};
