import { SVGProps } from 'react';

const ThreeDotsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22 12C22 13.2293 21.0056 14.225 19.7778 14.225C18.55 14.225 17.5556 13.2293 17.5556 12C17.5556 10.7707 18.55 9.77502 19.7778 9.77502C21.0056 9.77502 22 10.7707 22 12ZM12 9.77502C10.7722 9.77502 9.77778 10.7707 9.77778 12C9.77778 13.2293 10.7722 14.225 12 14.225C13.2278 14.225 14.2222 13.2293 14.2222 12C14.2222 10.7707 13.2278 9.77502 12 9.77502ZM4.22222 9.77502C2.99444 9.77502 2 10.7707 2 12C2 13.2293 2.99444 14.225 4.22222 14.225C5.45 14.225 6.44444 13.2293 6.44444 12C6.44444 10.7707 5.45 9.77502 4.22222 9.77502Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default ThreeDotsIcon;
