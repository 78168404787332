import { SVGProps } from 'react';

const CaretDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M2.00042 6.0005C2.51239 6.0005 3.02437 6.19649 3.41436 6.58647L12 15.1721L20.5856 6.58648C21.3676 5.80451 22.6316 5.80451 23.4135 6.58648C24.1955 7.36845 24.1955 8.63239 23.4135 9.41436L13.4139 19.4139C12.632 20.1959 11.368 20.1959 10.5861 19.4139L0.586475 9.41435C-0.195492 8.63239 -0.195492 7.36844 0.586475 6.58647C0.976459 6.19649 1.48844 6.0005 2.00042 6.0005Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default CaretDownIcon;
