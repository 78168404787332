import * as ElectricFeelModel from '@amzn/electricfeel-purchase-client';
import { Order, OrderCharge, OrderItem } from 'src/models/order';

const transformToOrderCharge = (charge: ElectricFeelModel.Charge): OrderCharge => {
  return {
    chargeAmount: {
      currencyCode: charge.chargeAmount.currencyCode,
      value: charge.chargeAmount.value,
    },
    chargeType: charge.chargeType,
  };
};

const transformToOrderItem = (item: ElectricFeelModel.OrderItem): OrderItem => {
  return {
    asin: item.asin,
    title: item.title,
    quantity: item.quantity,
    estimatedArrivalDate: item.estimatedArrivalDate,
  };
};

export const transformToOrder = (
  response: ElectricFeelModel.PreviewOrderResponse | ElectricFeelModel.ConfirmOrderResponse,
): Order => {
  const order = response.orders[0];
  const item = order.items[0];

  // Only one payment method will always be returned, which is the default payment method
  const paymentMethod = response.paymentMethods[0];

  return {
    orderId: order.id,
    purchaseId: response.purchaseId,
    items: [transformToOrderItem(item)],
    charges: response.charges.map((charge) => transformToOrderCharge(charge)),
    shippingAddress: {
      city: response.shippingAddress.city,
      zipCode: response.shippingAddress.zipCode,
    },
    paymentMethod: {
      type: paymentMethod.type,
      tail: paymentMethod.tail,
    },
  };
};
