import { SVGProps } from 'react';

const PauseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M9 22H6C5.45 22 5 21.55 5 21V3C5 2.45 5.45 2 6 2H9C9.55 2 10 2.45 10 3V21C10 21.55 9.55 22 9 22ZM19 21V3C19 2.45 18.55 2 18 2H15C14.45 2 14 2.45 14 3V21C14 21.55 14.45 22 15 22H18C18.55 22 19 21.55 19 21Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default PauseIcon;
