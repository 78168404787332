import { getImageStyle, getImageThumbnailStyle } from 'src/components/ImageThumbnail/style';

export enum ImageThumbnailPreset {
  CIRCLE = 'circle',
  SQUARE = 'square',
}

export interface ImageThumbnailProps {
  src: string;
  preset: ImageThumbnailPreset;
  size: number;
  alt: string;
  testId?: string;
}

const ImageThumbnail = (props: ImageThumbnailProps) => {
  return (
    <div style={getImageThumbnailStyle(props.preset, props.size)} data-testid={props.testId}>
      <img src={props.src} alt={props.alt} style={getImageStyle()} />
    </div>
  );
};

export default ImageThumbnail;
