import axios from 'axios';
import { getEndpointForStage, Stage } from 'src/api/util';
import { logger } from 'src/logger';
import { Campaign } from 'src/models/campaign';

const ENDPOINTS: Record<Stage, string> = {
  Dev: 'https://alpha.fan-commerce-app.music.amazon.dev',
  Beta: 'https://beta.fan-commerce-app.music.amazon.dev',
  Gamma: 'https://gamma.fan-commerce-app.music.amazon.dev',
  Prod: 'https://fan-commerce-app.music.amazon.dev',
};

export const getCampaignData = async (id: string): Promise<Campaign> => {
  logger.info(`Fetching campaign data file ${id}`);
  const response = await axios
    .get<Campaign>(`${getEndpointForStage(ENDPOINTS)}/${id}.json`)
    .catch((error) => {
      logger.error(`Error fetching campaign data file ${id}`, error);
      throw error;
    });

  return response.data;
};

export const isCampaignExpired = (campaign: Campaign): boolean => {
  const currentDate = Date.now();
  const campaignDate = Date.parse(campaign.expirationDate);
  return campaignDate < currentDate;
};
