import KatalLogger, { Level } from '@amzn/katal-logger';

const katalLoggerConfig = {
  url: 'https://r5tzxoxdy0.execute-api.us-east-1.amazonaws.com/prod/v1/log',
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
