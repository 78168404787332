import { useLocalizationContext } from '@amzn/react-arb-tools';
import React, { useState } from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import Icon from 'src/components/Icon/Icon';
import OrderDetailBox from 'src/components/OrderDetailBox/OrderDetailBox';
import {
  orderChargesItemStyle,
  orderSummaryItemContainerStyle,
  orderTotalContainerStyle,
} from 'src/components/OrderSummaryBox/style';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { DEFAULT_LOCALE } from 'src/localization/locales';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ElementName } from 'src/metrics/constants';
import { Order, OrderChargeType } from 'src/models/order';
import {
  findOrderChargeByType,
  formatOrderChargeAmount,
  formatShippingAddressText,
} from 'src/models/utils';

interface OrderChargeItemProps {
  label: string;
  amount: string;
  testId?: string;
}

export interface OrderSummaryBoxProps {
  image: string;
  order: Order;
}

const OrderChargeItem = (props: OrderChargeItemProps) => {
  return (
    <div style={orderChargesItemStyle} data-testid={props.testId}>
      <Text text={props.label} preset={TextPreset.BODY_2} color={BauhausColor.primary.solid} />
      <Text text={props.amount} preset={TextPreset.BODY_2} color={BauhausColor.primary.solid} />
    </div>
  );
};

const OrderSummaryBox = ({ image, order }: OrderSummaryBoxProps) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({
    elementName: ElementName.ORDER_SUMMARY_BOX,
    entityId: order.items[0].asin,
    entityIdType: 'ASIN',
    entityType: 'MERCH',
    responseId: order.orderId,
    responseIdType: 'ORDER_ID',
  });

  const { localizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;
  const bundle = useStringsBundle();

  const [isChargeTotalExpanded, setIsChargeTotalExpanded] = useState(false);

  // Since our app only supports buying one or more the same item, we know that we will always have
  // only one item in the list of order items.
  const orderItem = order.items[0];

  return (
    <div>
      <div
        style={orderSummaryItemContainerStyle('top')}
        data-testid={formatTestId(TestIds.OrderSummaryBox)}
      >
        <Text
          text={bundle.getMessage(StringIds.OrderCheckoutShipTo)}
          preset={TextPreset.BODY_1_BOLD}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderSummaryBox, 'ShipToLabelText')}
        />
        <Text
          text={formatShippingAddressText(order.shippingAddress)}
          preset={TextPreset.BODY_2}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderSummaryBox, 'AddressText')}
        />
      </div>
      <div style={orderSummaryItemContainerStyle('middle')}>
        <Text
          text={bundle.getMessage(StringIds.OrderCheckoutDelivery)}
          preset={TextPreset.BODY_1_BOLD}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderSummaryBox, 'DeliveryLabelText')}
        />
        <OrderDetailBox image={image} textPreset={TextPreset.BODY_2} orderItem={order.items[0]} />
      </div>
      <div style={orderSummaryItemContainerStyle('bottom')}>
        <Text
          text={bundle.getMessage(StringIds.OrderCheckoutPayWith)}
          preset={TextPreset.BODY_1_BOLD}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderSummaryBox, 'PayWithLabelText')}
        />
        <div>
          <Text
            text={order.paymentMethod.type}
            preset={TextPreset.BODY_2}
            color={BauhausColor.primary.solid}
            testId={formatTestId(TestIds.OrderSummaryBox, 'PaymentMethodTypeLabelText')}
          />
          <Text
            text={`**** ${order.paymentMethod.tail}`}
            preset={TextPreset.BODY_2}
            color={BauhausColor.primary.solid}
            testId={formatTestId(TestIds.OrderSummaryBox, 'PaymentMethodTailText')}
          />
        </div>
      </div>
      <Spacer size='huge' />
      <div style={orderTotalContainerStyle}>
        <Text
          text={bundle.getMessage(StringIds.OrderCheckoutTotal)}
          preset={TextPreset.BODY_1_BOLD}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderSummaryBox, 'OrderTotalLabelText')}
        />
        <div style={{ display: 'flex' }}>
          <Text
            text={formatOrderChargeAmount(
              OrderChargeType.PURCHASE_TOTAL,
              order.charges,
              currentLocale,
            )}
            preset={TextPreset.BODY_1}
            color={BauhausColor.primary.solid}
            testId={formatTestId(TestIds.OrderSummaryBox, 'OrderTotalText')}
          />
          <Icon
            icon={isChargeTotalExpanded ? 'caretUp' : 'caretDown'}
            onClick={() => {
              setIsChargeTotalExpanded((chargeDetailExpand) => !chargeDetailExpand);
            }}
            size={'micro'}
            color={BauhausColor.primary.solid}
            marginLeft={BauhausSpacing.small}
            testId={formatTestId(TestIds.OrderSummaryBox, 'OrderChargesExpandIcon')}
          />
        </div>
      </div>
      {isChargeTotalExpanded && (
        <div>
          <OrderChargeItem
            label={bundle.formatMessage(StringIds.OrderCheckoutTotalItemsQuantity, {
              itemQuantity: orderItem.quantity,
            })}
            amount={formatOrderChargeAmount(
              OrderChargeType.ITEMS_SUBTOTAL,
              order.charges,
              currentLocale,
            )}
            testId={formatTestId(TestIds.OrderSummaryBox, 'ItemsQuantityText')}
          />
          <OrderChargeItem
            label={bundle.getMessage(StringIds.OrderCheckoutTotalShipping)}
            amount={formatOrderChargeAmount(
              OrderChargeType.SHIPPING_FEE_TOTAL,
              order.charges,
              currentLocale,
            )}
            testId={formatTestId(TestIds.OrderSummaryBox, 'ShippingText')}
          />
          <OrderChargeItem
            label={bundle.getMessage(StringIds.OrderCheckoutTotalShippingDiscount)}
            amount={formatOrderChargeAmount(
              OrderChargeType.SHIPPING_DISCOUNT_TOTAL,
              order.charges,
              currentLocale,
            )}
            testId={formatTestId(TestIds.OrderSummaryBox, 'ShippingDiscountText')}
          />
          <OrderChargeItem
            label={bundle.getMessage(StringIds.OrderCheckoutTotalTax)}
            amount={formatOrderChargeAmount(
              OrderChargeType.ESTIMATED_TAX_TOTAL,
              order.charges,
              currentLocale,
            )}
            testId={formatTestId(TestIds.OrderSummaryBox, 'TaxText')}
          />
          {findOrderChargeByType(OrderChargeType.IMPORT_EXPORT_FEE_TOTAL, order.charges) && (
            <OrderChargeItem
              label={bundle.getMessage(StringIds.OrderCheckoutImportExportFee)}
              amount={formatOrderChargeAmount(
                OrderChargeType.IMPORT_EXPORT_FEE_TOTAL,
                order.charges,
                currentLocale,
              )}
              testId={formatTestId(TestIds.OrderSummaryBox, 'ImportExportText')}
            />
          )}
          {findOrderChargeByType(OrderChargeType.REGULATORY_FEE_TOTAL, order.charges) && (
            <OrderChargeItem
              label={bundle.getMessage(StringIds.OrderCheckoutRegulatoryFee)}
              amount={formatOrderChargeAmount(
                OrderChargeType.REGULATORY_FEE_TOTAL,
                order.charges,
                currentLocale,
              )}
              testId={formatTestId(TestIds.OrderSummaryBox, 'RegulatoryFeeText')}
            />
          )}
          {findOrderChargeByType(OrderChargeType.ITEMS_DISCOUNT_TOTAL, order.charges) && (
            <OrderChargeItem
              label={bundle.getMessage(StringIds.OrderCheckoutDiscounts)}
              amount={formatOrderChargeAmount(
                OrderChargeType.ITEMS_DISCOUNT_TOTAL,
                order.charges,
                currentLocale,
              )}
              testId={formatTestId(TestIds.OrderSummaryBox, 'DiscountsText')}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OrderSummaryBox;
