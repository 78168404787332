import { MonetaryValue } from 'src/models/common';

// Reference for backend definition https://tiny.amazon.com/fgu5dp1
export enum OrderChargeType {
  PURCHASE_TOTAL = 'PURCHASE_TOTAL',
  ITEMS_SUBTOTAL = 'ITEMS_SUBTOTAL',
  ESTIMATED_TAX_TOTAL = 'ESTIMATED_TAX_TOTAL',
  IMPORT_EXPORT_FEE_TOTAL = 'IMPORT_EXPORT_FEE_TOTAL',
  REGULATORY_FEE_TOTAL = 'REGULATORY_FEE_TOTAL',
  SHIPPING_FEE_TOTAL = 'SHIPPING_FEE_TOTAL',
  ITEMS_DISCOUNT_TOTAL = 'ITEMS_DISCOUNT_TOTAL',
  SHIPPING_DISCOUNT_TOTAL = 'SHIPPING_DISCOUNT_TOTAL',
}

export interface Order {
  orderId: string;
  purchaseId: string;
  items: OrderItem[];
  paymentMethod: PaymentMethod;
  shippingAddress: ShippingAddress;
  charges: OrderCharge[];
}

export interface OrderCharge {
  chargeAmount: MonetaryValue;
  chargeType: string;
}

export interface OrderItem {
  asin: string;
  title: string;
  quantity: number;
  estimatedArrivalDate: string;
}

export interface PaymentMethod {
  type: string;
  tail: string;
}

export interface ShippingAddress {
  city?: string;
  zipCode?: string;
}
