import React from 'react';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const $menuStyle: React.CSSProperties = {
  backgroundColor: BauhausColor.primary.solid,
  borderRadius: BauhausBorder.radius.MD,
  boxShadow: '0px 10px 20px 0px #14191A4D',
  padding: BauhausSpacing.mini,
  minWidth: 250,
  zIndex: 1,
};

export const $menuDividerStyle: React.CSSProperties = {
  border: `1px solid ${BauhausColor.base.glass1}`,
  margin: BauhausSpacing.small,
};

export const $menuOptionStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: BauhausSpacing.small,
};
