import React from 'react';
import { formatTestId, TestIds } from 'src/components/testIds';
import {
  containerStyle,
  leftMenuStyle,
  rightMenuStyle,
  titleStyle,
} from 'src/components/TopBar/style';

export interface TopBarProps {
  title?: React.JSX.Element;
  leftMenu?: React.JSX.Element;
  rightMenu?: React.JSX.Element;
  testId?: string;
}

export const TopBar = (props: TopBarProps) => {
  const testId = props.testId ?? TestIds.TopBar;

  return (
    <div style={containerStyle} data-testid={formatTestId(testId)}>
      {props.leftMenu && (
        <div style={leftMenuStyle} data-testid={formatTestId(testId, TestIds.Menu, 'Left')}>
          {props.leftMenu}
        </div>
      )}
      {props.title && (
        <div style={titleStyle} data-testid={formatTestId(testId, TestIds.TitleText)}>
          {props.title}
        </div>
      )}
      {props.rightMenu && (
        <div style={rightMenuStyle} data-testid={formatTestId(testId, TestIds.Menu, 'Right')}>
          {props.rightMenu}
        </div>
      )}
    </div>
  );
};
