import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import { formatTestId, PageTestIds, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { UiMetricsWrapper } from 'src/components/UiMetricsWrapper/UiMetricsWrapper';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName, PageName } from 'src/metrics/constants';
import { goToAmazonMusic } from 'src/navigation/sites';
import {
  $pageContentStyle,
  $pageContentTextStyle,
  $pageStyle,
} from 'src/pages/ExpiredCampaignPage/style';

const ExpiredCampaignPage = () => {
  // TODO: Add session id and campaign id
  const campaignId = '';

  const bundle = useStringsBundle();

  return (
    <UiMetricsWrapper
      pageName={PageName.EXPIRED_CAMPAIGN_PAGE}
      pageSubName={''}
      campaignId={campaignId}
      isLoading={false}
    >
      <div style={$pageStyle} data-testid={PageTestIds.ExpiredCampaignPage}>
        <div style={$pageContentStyle}>
          <div style={$pageContentTextStyle}>
            <Text
              text={bundle.getMessage(StringIds.ExpiredCampaignSubtitle)}
              preset={TextPreset.HEADLINE_4}
              color={BauhausColor.primary.solid}
              testId={formatTestId(TestIds.SubtitleText)}
            />
            <Text
              text={bundle.getMessage(StringIds.ExpiredCampaignText)}
              preset={TextPreset.BODY_1}
              color={BauhausColor.primary.solid}
              testId={formatTestId(TestIds.BodyText)}
            />
          </div>
          <Button
            text={bundle.getMessage(StringIds.CtaCheckoutMoreOnAmazonMusic)}
            preset={ButtonPreset.SOLID}
            fullWidth
            onClick={() => goToAmazonMusic('_blank')}
            testId={formatTestId(TestIds.CtaButton)}
            metrics={{
              elementName: ElementName.CTA_BUTTON,
              actionName: ActionName.GO_AMZN_MUSIC,
            }}
          />
        </div>
      </div>
    </UiMetricsWrapper>
  );
};

export default ExpiredCampaignPage;
