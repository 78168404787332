import React from 'react';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const $pageStyle: React.CSSProperties = {
  background: 'linear-gradient(90deg, #8A80FF 0.01%, #25D1DA 100%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export const $pageContentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: BauhausSpacing.epic,
  margin: BauhausSpacing.medium,
};

export const $pageContentTextStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: BauhausSpacing.small,
};
