/**
 * Breakpoints
 * For Bauhaus breakpoints guidelines please see:
 * https://quip-amazon.com/K3bhA7zeXX9g#RMC9AAMjYGh
 */

export const BauhausBreakpoints = {
  xs: 0,
  sm: 360,
  md: 480,
  lg: 640,
  xl: 840,
  '2x': 1024,
  '3x': 1280,
  '4x': 1440,
};
