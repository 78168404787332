import Modal from '@mui/material/Modal';
import React from 'react';
import { BauhausBlur } from 'src/bauhaus/blur';
import { BauhausColor } from 'src/bauhaus/color';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';

export interface LoadingSpinnerWrapperProps {
  size?: number;
}

const $wrapperStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: BauhausColor.secondary.glass4,
  backdropFilter: `blur(${BauhausBlur.blur1}px)`,
  WebkitBackdropFilter: `blur(${BauhausBlur.blur1}px)`,
  zIndex: 99999,
};

const LoadingSpinnerWrapper = (props: LoadingSpinnerWrapperProps) => {
  return (
    <Modal open style={$wrapperStyle}>
      <>
        <LoadingSpinner size={props.size} />
      </>
    </Modal>
  );
};

export default LoadingSpinnerWrapper;
