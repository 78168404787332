import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StringsBundleContextProvider } from 'src/context/StringsBundleContext';
import BaseCampaignPage from 'src/pages/BaseCampaignPage/BaseCampaignPage';
import InfoPage from 'src/pages/InfoPage/InfoPage';
import { checkAuthState } from 'src/store/authSlice';
import { AppDispatch } from 'src/store/store';

const App = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  return (
    <StringsBundleContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/info' Component={InfoPage} />
          <Route path='/:id' Component={BaseCampaignPage} />
        </Routes>
      </BrowserRouter>
    </StringsBundleContextProvider>
  );
};

export default App;
