import { PropsWithChildren } from 'react';
import { BauhausBlurType } from 'src/bauhaus/blur';
import { getStyle } from 'src/components/ImageBackground/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ElementName } from 'src/metrics/constants';

export interface ImageBackgroundProps {
  image: string;
  blur?: BauhausBlurType;
}

export const ImageBackground = (props: PropsWithChildren<ImageBackgroundProps>) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({ elementName: ElementName.BACKGROUND_IMAGE });

  return (
    <div
      style={getStyle(props.image, props.blur)}
      data-testid={formatTestId(TestIds.ImageBackground)}
    />
  );
};
