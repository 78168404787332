import { IsEligibleRequest, IsEligibleResponse } from '@amzn/electricfeel-gateway-client';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { getEndpointForStage, MAX_RETRIES, Stage, TIME_OUT_MILI_SEC } from 'src/api/util';

export const ENDPOINTS: Record<Stage, string> = {
  Dev: 'https://gateway-fan-alpha.aka.amazon.com/',
  Beta: 'https://gateway.beta.fan.amazon.com/',
  Gamma: 'https://gateway.beta.fan.amazon.com/',
  Prod: 'https://gateway.fan.amazon.com/',
};

export class ElectricFeelGatewayService {
  private axiosClient: AxiosInstance;

  constructor() {
    const endpoint = getEndpointForStage(ENDPOINTS);
    this.axiosClient = axios.create({
      baseURL: endpoint,
      timeout: TIME_OUT_MILI_SEC,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    axiosRetry(this.axiosClient, {
      retries: MAX_RETRIES,
      retryDelay: axiosRetry.exponentialDelay,
      shouldResetTimeout: true,
    });
  }

  public async isEligible(request: IsEligibleRequest): Promise<IsEligibleResponse> {
    const path = `/isEligible`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'post',
      data: request,
      withCredentials: true,
    });
    return response.data as IsEligibleResponse;
  }
}
