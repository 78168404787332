import {
  ConfirmOrderRequest,
  ConfirmOrderResponse,
  GetProductDetailsRequest,
  GetProductDetailsResponse,
  PreviewOrderRequest,
  PreviewOrderResponse,
} from '@amzn/electricfeel-purchase-client';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { getEndpointForStage, MAX_RETRIES, Stage, TIME_OUT_MILI_SEC } from 'src/api/util';

export const ENDPOINTS: Record<Stage, string> = {
  Dev: 'https://purchase-fan-alpha.aka.amazon.com/',
  Beta: 'https://purchase.beta.fan.amazon.com/',
  Gamma: 'https://purchase.beta.fan.amazon.com/',
  Prod: 'https://purchase.fan.amazon.com/',
};

export class ElectricFeelPurchaseService {
  private readonly axiosClient: AxiosInstance;

  constructor() {
    const endpoint = getEndpointForStage(ENDPOINTS);
    this.axiosClient = axios.create({
      baseURL: endpoint,
      timeout: TIME_OUT_MILI_SEC,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    axiosRetry(this.axiosClient, {
      retries: MAX_RETRIES,
      retryDelay: axiosRetry.exponentialDelay,
      shouldResetTimeout: true,
    });
  }

  // Refer https://tiny.amazon.com/954wx26w
  public async confirmOrder(request: ConfirmOrderRequest): Promise<ConfirmOrderResponse> {
    const path = `/confirmOrder`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'post',
      data: request,
      withCredentials: true,
    });
    return response.data as ConfirmOrderResponse;
  }

  // Refer https://tiny.amazon.com/1f1syp5l2
  /**
   * Get product details supports both un-auth and authenticated path.
   * @param request
   * @param isAuthenticated
   */
  public async getProductDetails(
    request: GetProductDetailsRequest,
    isAuthenticated: boolean,
  ): Promise<GetProductDetailsResponse> {
    const path = `/getProductDetails`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'get',
      params: request,
      withCredentials: isAuthenticated,
    });
    return response.data as GetProductDetailsResponse;
  }

  // Refer https://tiny.amazon.com/k78xfbmx
  public async previewOrder(request: PreviewOrderRequest): Promise<PreviewOrderResponse> {
    const path = `/previewOrder`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'post',
      data: request,
      withCredentials: true,
    });
    return response.data as PreviewOrderResponse;
  }
}
