import { BauhausColor } from 'src/bauhaus/color';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';

import ButtonGroup from '../ButtonGroup/ButtonGroup';
import { Spacer } from '../Spacer/Spacer';

export interface VariationBoxProps {
  label: string;
  values: string[];
  onSelect: (index: number) => void;
  selectedIndex?: number;
  grayOuts?: boolean[];
  testId?: string;
}

const VariationBox = (props: VariationBoxProps) => {
  const testId = props.testId ?? TestIds.VariationBox;
  return (
    <div data-testid={formatTestId(testId)}>
      <Text
        text={props.label}
        preset={TextPreset.BODY_2}
        color={BauhausColor.primary.solid}
        testId={formatTestId(testId, TestIds.LabelText)}
      />
      <Spacer size='small' />
      <ButtonGroup
        values={props.values}
        onSelect={props.onSelect}
        selectedIndex={props.selectedIndex}
        grayOuts={props.grayOuts}
        testId={formatTestId(testId, TestIds.ButtonGroup)}
      />
    </div>
  );
};

export default VariationBox;
