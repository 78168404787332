import { useLocalizationContext } from '@amzn/react-arb-tools';
import React, { Ref } from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import LegalFooter from 'src/components/LegalFooter/LegalFooter';
import OrderSummaryBox from 'src/components/OrderSummaryBox/OrderSummaryBox';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { DEFAULT_LOCALE } from 'src/localization/locales';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName, EntityIdType, EntityType } from 'src/metrics/constants';
import { Order } from 'src/models/order';
import { goToAmazonAccount } from 'src/navigation/sites';
import {
  $sheetContainerStyle,
  $sheetHeaderStyle,
} from 'src/pages/ProductDetailPage/ActionSheet/style';

export interface OrderCheckoutSheetProps {
  previewImage: string;
  previewOrder: Order;
  additionalTerms?: Record<string, string>;
  onClickBackButton: () => void;
  onClickCtaButton: () => void;
  ref?: Ref<HTMLDivElement>;
}

const OrderCheckoutSheet = ({
  previewImage,
  previewOrder,
  additionalTerms,
  onClickBackButton,
  onClickCtaButton,
  ref,
}: OrderCheckoutSheetProps) => {
  const { uiContentView, uiClick } = useUiMetrics();
  uiContentView({
    elementName: ElementName.ACTION_SHEET_CHECKOUT,
    entityId: previewOrder.items[0].asin,
    entityIdType: 'ASIN' as EntityIdType,
    entityType: 'MERCH' as EntityType,
  });

  const { localizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;
  const bundle = useStringsBundle();

  return (
    <div
      style={$sheetContainerStyle}
      data-testid={formatTestId(TestIds.OrderCheckoutSheet)}
      ref={ref}
    >
      <div style={$sheetHeaderStyle}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: BauhausSpacing.base,
          }}
        >
          <Icon
            icon={'goBack'}
            color={BauhausColor.primary.solid}
            size={'SM'}
            onClick={onClickBackButton}
            testId={formatTestId(TestIds.OrderCheckoutSheet, TestIds.GoBackIcon)}
          />
          <Text
            text={bundle.getMessage(StringIds.OrderCheckoutTitle)}
            preset={TextPreset.HEADLINE_4}
            testId={formatTestId(TestIds.OrderCheckoutSheet, TestIds.TitleText)}
          />
        </div>
        <Button
          text={bundle.getMessage(StringIds.CtaChangeCheckoutInfo).toLocaleUpperCase()}
          textColor={BauhausColor.accent.solid}
          preset={ButtonPreset.TRANSPARENT}
          horizontalPadding={0}
          onClick={() => {
            goToAmazonAccount('_blank');
            uiClick({
              actionType: ActionName.GO_AMZN_ACCOUNT,
              elementName: ElementName.CHANGE_CHECKOUT_INFO_BUTTON,
              entityId: previewOrder.items[0].asin,
              entityIdType: 'ASIN',
              entityType: 'MERCH',
            });
          }}
          testId={formatTestId(TestIds.OrderCheckoutSheet, TestIds.ChangeCheckoutInfoButton)}
        />
      </div>
      <OrderSummaryBox image={previewImage} order={previewOrder} />
      <Spacer size={'large'} />
      <Button
        preset={ButtonPreset.SOLID}
        size={'LG'}
        horizontalPadding={BauhausSpacing.large}
        text={bundle.getMessage(StringIds.CtaPlaceOrder)}
        onClick={onClickCtaButton}
        testId={formatTestId(TestIds.OrderCheckoutSheet, TestIds.PlaceOrderButton)}
        metrics={{
          actionName: ActionName.PLACE_ORDER,
          elementName: ElementName.PLACE_ORDER_BUTTON,
          entityId: previewOrder.items[0].asin,
          entityIdType: 'ASIN',
          entityType: 'MERCH',
        }}
      />
      <LegalFooter
        prefix={bundle.getMessage(StringIds.OrderTermsPrefix)}
        suffix={
          additionalTerms &&
          additionalTerms[currentLocale] && (
            <div
              dangerouslySetInnerHTML={{
                __html: decodeURI(additionalTerms[currentLocale]),
              }}
              data-testid={formatTestId(TestIds.OrderCheckoutSheet, 'AdditionalTerms')}
            />
          )
        }
      />
    </div>
  );
};

export default OrderCheckoutSheet;
