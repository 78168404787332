import MuiModal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import IconButton from 'src/components/IconButton/IconButton';
import {
  getCloseButtonStyle,
  getInnerContainerStyle,
  outerContainerStyle,
} from 'src/components/Modal/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import { UiMetricsWrapper } from 'src/components/UiMetricsWrapper/UiMetricsWrapper';
import { ActionName, ElementName, PageName } from 'src/metrics/constants';
export interface ModalProps {
  children: ReactNode;
  /**
   * Optional, if not provided this Modal is not cancellable
   */
  onClose?: () => void;
  metrics?: {
    pageName: PageName;
    campaignId: string;
  };
}
const Modal = (props: ModalProps) => {
  return (
    <UiMetricsWrapper
      pageName={props.metrics?.pageName ?? PageName.MODAL}
      campaignId={props.metrics?.campaignId ?? ''}
      isLoading={false}
    >
      <MuiModal
        sx={{ border: 0 }}
        disableEnforceFocus
        key={'modal'}
        open={true}
        onClose={props.onClose}
        data-testid={formatTestId(TestIds.Modal)}
        style={outerContainerStyle}
      >
        <Box style={outerContainerStyle}>
          <div style={getInnerContainerStyle()}>
            {props.onClose && (
              <div style={getCloseButtonStyle()}>
                <IconButton
                  icon={'cancel'}
                  size={'SM'}
                  onClick={props.onClose}
                  testId={formatTestId(TestIds.Modal, TestIds.CloseButton)}
                  metrics={{
                    elementName: ElementName.CLOSE_BUTTON,
                    actionName: ActionName.CLOSE_MODAL,
                  }}
                />
              </div>
            )}
            {props.children}
          </div>
        </Box>
      </MuiModal>
    </UiMetricsWrapper>
  );
};

export default Modal;
