import { getAmazonLoginUrl, getSignOutUrl } from 'src/navigation/auth';

export const AMAZON_PRIVACY_NOTICE =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=468496';
export const AMAZON_CONDITIONS_OF_USE =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=508088';
export const AMAZON_PREORDER_TERMS =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=G4BRP7LCB9DSDNCR';

const AMAZON_MUSIC_HOMEPAGE = 'https://music.amazon.com';
const AMAZON_MUSIC_ARTIST_DETAIL = 'https://music.amazon.com/artists';
const AMAZON_ORDER_HISTORY = 'https://www.amazon.com/gp/css/order-history';
const AMAZON_ACCOUNT = 'https://www.amazon.com/gp/css/homepage.html';
const AMAZON_ADDRESSES = 'https://www.amazon.com/a/addresses';
const AMAZON_HELP_PROVIDE_IDENTITY_DOCUMENT =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=GAV3NYNSGRGTGAJJ';
const AMAZON_YOUR_PAYMENTS = 'https://www.amazon.com/cpe/yourpayments/wallet';
const AMAZON_GET_MUSIC_APP = 'https://www.amazon.com/music/amazon-music-app';

const allowedDomains = ['www.amazon.com', 'music.amazon.com'];

const goToUrl = (url: string, target: string = '_self') => {
  const parsedUrl = new URL(url);
  if (allowedDomains.includes(parsedUrl.hostname)) {
    window.open(url, target);
  }
};

export const goBack = () => {
  window.history.back();
};

export const goGetAmazonMusicApp = (target: string = '_self') => {
  goToUrl(AMAZON_GET_MUSIC_APP, target);
};

export const goToAmazonMusic = (target: string = '_self') => {
  goToUrl(AMAZON_MUSIC_HOMEPAGE, target);
};

export const goToAmazonMusicArtistDetail = (asin: string, target: string = '_self') => {
  goToUrl(`${AMAZON_MUSIC_ARTIST_DETAIL}/${asin}`, target);
};

export const goToAmazonAccount = (target: string = '_self') => {
  goToUrl(AMAZON_ACCOUNT, target);
};

export const goToAmazonOrderHistory = (target: string = '_self') => {
  goToUrl(AMAZON_ORDER_HISTORY, target);
};

export const goToAmazonLogin = (locale: string, target: string = '_self') => {
  goToUrl(`${getAmazonLoginUrl(locale)}`, target);
};

export const goToAmazonLogout = (locale: string, target: string = '_self') => {
  goToUrl(`${getSignOutUrl(locale)}`, target);
};

export const goToAmazonHelpKYC = (target: string = '_self') => {
  goToUrl(AMAZON_HELP_PROVIDE_IDENTITY_DOCUMENT, target);
};

export const goToAmazonAddresses = (target: string = '_self') => {
  goToUrl(AMAZON_ADDRESSES, target);
};

export const goToAmazonYourPayments = (target: string = '_self') => {
  goToUrl(AMAZON_YOUR_PAYMENTS, target);
};
