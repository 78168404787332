export const TestIds = {
  // Buttons
  Button: 'Button',
  BackButton: 'BackButton',
  CtaButton: 'CtaButton',
  CancelButton: 'CancelButton',
  PrimaryButton: 'PrimaryButton',
  SecondaryButton: 'SecondaryButton',
  CheckoutButton: 'CheckoutButton',
  ChangeCheckoutInfoButton: 'ChangeCheckoutInfoButton',
  PlaceOrderButton: 'PlaceOrderButton',
  ViewOrderButton: 'ViewOrderButton',

  // IconButtons
  IconButton: 'IconButton',
  CloseButton: 'CloseButton',
  NavigationIconButton: 'NavigationIconButton',

  // Icons
  Icon: 'Icon',
  GoBackIcon: 'GoBackIcon',
  HelpIcon: 'HelpIcon',

  // Single-Purpose Components
  ArtistTopBar: 'ArtistTopBar',
  ArtistCelebrationBox: 'ArtistCelebrationBox',
  ButtonGroup: 'ButtonGroup',
  DescriptionBox: 'DescriptionBox',
  HintBox: 'HintBox',
  ImageBackground: 'ImageBackground',
  ImageCarousel: 'ImageCarousel',
  ImageThumbnail: 'ImageThumbnail',
  LanguageSelector: 'LanguageSelector',
  LegalFooter: 'LegalFooter',
  LoadingSpinner: 'LoadingSpinner',
  Menu: 'Menu',
  Option: 'Option',
  Modal: 'Modal',
  OrderConfirmationBox: 'OrderConfirmationBox',
  OrderDetailBox: 'OrderDetailBox',
  OrderSummaryBox: 'OrderSummaryBox',
  SelectionBox: 'SelectionBox',
  TopBar: 'TopBar',
  VariationBox: 'VariationBox',

  // Multi-Purpose Components
  ActionSheet: 'ActionSheet',
  ProductSelectionSheet: 'ProductSelectionSheet',
  OrderCheckoutSheet: 'OrderCheckoutSheet',
  OrderConfirmationSheet: 'OrderConfirmationSheet',

  // Texts
  Text: 'Text',
  TitleText: 'TitleText',
  SubtitleText: 'SubtitleText',
  BodyText: 'BodyText',
  LabelText: 'LabelText',
};

export const PageTestIds = {
  ExpiredCampaignPage: 'ExpiredCampaignPage',
  InfoPage: 'InfoPage',
  ProductDetailPage: 'ProductDetailPage',
};

export const formatTestId = (id: string, childId?: string, specifier?: string) => {
  if (childId) {
    const formattedTestId = `${id}.${childId}`;
    return specifier ? formattedTestId.concat(`_${specifier}`) : formattedTestId;
  } else if (specifier) {
    return `${id}_${specifier}`;
  } else {
    return id;
  }
};
