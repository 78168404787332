import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { TopBar } from 'src/components/TopBar/TopBar';
import { TopBarMenu } from 'src/components/TopBarMenu/TopBarMenu';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ElementName } from 'src/metrics/constants';
import { Artist } from 'src/models/artist';

export interface ArtistTopBarProps {
  artist: Artist;
  isLoggedIn?: boolean;
}

const ArtistTopBar = (props: ArtistTopBarProps) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({ elementName: ElementName.ARTIST_TOP_BAR });

  return (
    <TopBar
      title={
        <Text
          text={props.artist.name}
          preset={TextPreset.HEADLINE_4}
          truncate={true}
          color={BauhausColor.primary.solid}
          lines={1}
          testId={formatTestId(TestIds.ArtistTopBar, 'ArtistNameText')}
        />
      }
      rightMenu={<TopBarMenu isLoggedIn={props.isLoggedIn} />}
      testId={formatTestId(TestIds.ArtistTopBar)}
    />
  );
};

export default ArtistTopBar;
