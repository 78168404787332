export const DEFAULT_LOCALE = 'en-US';

export enum Locale {
  EN_US = 'en-US',
  ES_US = 'es-US',
}

// Used in LanguageSelector component
export interface LocaleOption {
  value: Locale;
  label: string;
}

export const LocaleOptions: LocaleOption[] = [
  { value: Locale.EN_US, label: 'English (United States)' },
  { value: Locale.ES_US, label: 'Español (Americas)' },
];

export const LOCALE_LOCAL_STORAGE_KEY = 'locale';
