import { MessageBundle } from '@amzn/arb-tools';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import React, { createContext, ReactNode } from 'react';
import LoadingSpinnerWrapper from 'src/components/LoadingSpinner/LoadingSpinnerWrapper';
import { STRINGS_BUNDLE } from 'src/localization/bundles';

export const StringsBundleContext = createContext<MessageBundle>({
  getMessage: () => '',
  formatMessage: () => '',
});

interface StringsBundleContextProviderProps {
  children: ReactNode;
}

export const StringsBundleContextProvider = ({ children }: StringsBundleContextProviderProps) => {
  const { localizationContext } = useLocalizationContext();

  const [bundle, isLoading] = useBundle(STRINGS_BUNDLE, localizationContext);

  return (
    <StringsBundleContext.Provider value={bundle}>
      {isLoading ? <LoadingSpinnerWrapper /> : children}
    </StringsBundleContext.Provider>
  );
};
