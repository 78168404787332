import './SelectionBox.css';

import { FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import {
  quantitySelectorFormControlStyle,
  quantitySelectorOptionStyle,
  quantitySelectorStyle,
  selectionQuantityContainerStyle,
} from 'src/components/SelectionBox/style';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import VariationBox from 'src/components/VariationBox/VariationBox';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName } from 'src/metrics/constants';
import { ProductVariations, Variation } from 'src/models/product';
import { findDimensionValueUnavailabilities, findVariation } from 'src/utils/variations';

export interface SelectionBoxProps {
  maxQuantity?: number;
  productVariations?: ProductVariations;
  selectedQuantity: number;
  onChangeQuantity: (value: number) => void;
  selectedVariation?: Variation;
  onChangeVariation?: (variation: Variation) => void;
}

export interface QuantityOption {
  value: number;
  label: string;
}

const SelectionBox = ({
  maxQuantity,
  selectedQuantity,
  onChangeQuantity,
  productVariations,
  selectedVariation,
  onChangeVariation,
}: SelectionBoxProps) => {
  const elementName = ElementName.SELECTION_BOX;
  const { uiClick, uiContentView } = useUiMetrics();
  uiContentView({ elementName });

  const quantityOptions: QuantityOption[] = Array.from(
    { length: maxQuantity ?? 1 },
    (_, index) => ({
      value: index + 1,
      label: (index + 1).toString(),
    }),
  );
  const handleQuantitySelection = (event: SelectChangeEvent) => {
    if (event) {
      const quantity = parseInt(event.target.value);
      onChangeQuantity(quantity);
    }
  };

  const [currentDimensionValueIndexes, setCurrentDimensionValueIndexes] = useState<number[]>([]);
  const [dimensionValueUnavailabilities, setDimensionValueUnavailabilities] = useState<boolean[][]>(
    [],
  );

  const updateVariation = (
    productVariations: ProductVariations | undefined,
    variation: Variation | undefined,
    newVariation: boolean,
  ) => {
    if (!variation || !variation.dimensionValueIndexes) {
      return;
    }
    setCurrentDimensionValueIndexes(variation.dimensionValueIndexes);
    setDimensionValueUnavailabilities(
      findDimensionValueUnavailabilities(
        productVariations?.variations ?? [],
        productVariations?.variationDimensions ?? [],
        variation.dimensionValueIndexes ?? [],
      ),
    );
    if (newVariation) {
      onChangeVariation && onChangeVariation(variation);
      uiClick({
        actionType: ActionName.SELECT_PRODUCT_VARIATION,
        elementName,
        entityId: variation.asin,
        entityIdType: 'ASIN',
        entityType: 'MERCH',
      });
    }
  };

  useEffect(() => {
    updateVariation(productVariations, selectedVariation, false);
  }, [productVariations, selectedVariation]);

  const bundle = useStringsBundle();

  return (
    <div data-testid={formatTestId(TestIds.SelectionBox)}>
      {maxQuantity != null && maxQuantity > 0 && (
        <div id='selectionBox'>
          <Text
            text={bundle.getMessage(StringIds.OrderSelectionQuantity)}
            preset={TextPreset.BODY_2}
            color={BauhausColor.primary.solid}
            testId={formatTestId(TestIds.SelectionBox, 'QuantityLabelText')}
          />
          <Spacer size='small' />
          <div
            style={selectionQuantityContainerStyle}
            data-testid={formatTestId(TestIds.SelectionBox, 'QuantitySelector')}
          >
            <FormControl variant='filled' size='small' style={quantitySelectorFormControlStyle}>
              <Select
                id={'QuantitySelector'}
                hiddenLabel
                data-testid={formatTestId(TestIds.SelectionBox, 'QuantitySelectorButton')}
                style={quantitySelectorStyle}
                defaultValue={quantityOptions[selectedQuantity - 1].value.toString()}
                value={quantityOptions[selectedQuantity - 1].value.toString()}
                onChange={handleQuantitySelection}
                onClick={() =>
                  uiClick({
                    actionType: ActionName.SELECT_PRODUCT_QUANTITY,
                    elementName,
                    entityId: selectedVariation ? selectedVariation.asin : 'CAMPAIGN_ASIN',
                    entityIdType: 'ASIN',
                    entityType: 'MERCH',
                  })
                }
              >
                {quantityOptions.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    style={quantitySelectorOptionStyle}
                    data-testid={formatTestId(TestIds.SelectionBox, 'QuantitySelector', item.label)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <Text
                text={bundle.formatMessage(StringIds.OrderSelectionQuantityLimitPerCustomer, {
                  quantityLimit: maxQuantity ?? 1,
                })}
                preset={TextPreset.BODY_2}
                color={BauhausColor.primary.glass4}
                testId={formatTestId(TestIds.SelectionBox, 'QuantityLimitText')}
              />
            </FormControl>
          </div>
        </div>
      )}
      {productVariations &&
        productVariations.variationDimensions.map((dimensions, dimensionIndex) => {
          return (
            <div key={`VariationBox-${dimensionIndex}`}>
              <Spacer size='small' />
              <VariationBox
                label={dimensions.name}
                values={dimensions.values}
                onSelect={(valueIndex) =>
                  updateVariation(
                    productVariations,
                    findVariation(
                      productVariations?.variations ?? [],
                      dimensionIndex,
                      valueIndex,
                      currentDimensionValueIndexes,
                    ),
                    true,
                  )
                }
                selectedIndex={currentDimensionValueIndexes?.[dimensionIndex]}
                grayOuts={dimensionValueUnavailabilities?.[dimensionIndex]}
                testId={formatTestId(
                  TestIds.SelectionBox,
                  TestIds.VariationBox,
                  dimensionIndex.toString(),
                )}
              />
            </div>
          );
        })}
    </div>
  );
};

export default SelectionBox;
