import { useEffect, useState } from 'react';
import {
  buttonGroupStyle,
  getDefaultButtonStyle,
  getGrayOutButtonStyle,
  getSelectedButtonStyle,
  innerContainerBaseStyle,
} from 'src/components/ButtonGroup/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';

export interface ButtonGroupProps {
  values: string[];
  onSelect: (index: number) => void;
  selectedIndex?: number;
  grayOuts?: boolean[];
  testId?: string;
}

const ButtonGroup = (props: ButtonGroupProps) => {
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(props.selectedIndex);

  useEffect(() => {
    setCurrentIndex(props.selectedIndex);
  }, [props.selectedIndex]);

  const isSelected = (index: number) => {
    return currentIndex === index;
  };

  const handleSelect = (index: number) => {
    props.onSelect(index);
  };

  const testId = props.testId ?? TestIds.ButtonGroup;
  return (
    <div data-testid={props.testId} style={buttonGroupStyle}>
      {props.values.map((value, index) => (
        <button
          key={index}
          onClick={() => handleSelect(index)}
          data-testid={formatTestId(testId, TestIds.Button, index.toString())}
          style={
            props.grayOuts?.[index]
              ? getGrayOutButtonStyle
              : isSelected(index)
                ? getSelectedButtonStyle
                : getDefaultButtonStyle
          }
        >
          <div style={innerContainerBaseStyle}>
            <Text text={value} preset={TextPreset.BODY_2} />
          </div>
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
