import axios from 'axios';
import { Stage } from 'src/api/util';
import { logger } from 'src/logger';
import { AppVersion, MetricName } from 'src/metrics/constants';
import { UiClick, UiClickVersion } from 'src/metrics/events/UiClick';
import { UiContentView, UiContentViewVersion } from 'src/metrics/events/UiContentView';
import { UiPageView, UiPageViewVersion } from 'src/metrics/events/UiPageView';

// https://w.amazon.com/bin/view/Amazon_Music/Metrics/Amazon_Music_Metrics_Events/Base_Event_Attributes
const BaseEventVersion = '4';

export interface CirrusLibraryOption {
  deviceId: string;
  deviceType: string;
  musicTerritory: string;
  authToken?: string; // ATNA or ATZA
}

export class CirrusLibraryService {
  public static getBaseRequestUrl(): string {
    return 'https://music.amazon.com/NA/api/cirrus/v3/';
  }

  public static getBaseRequestHeaders(option: CirrusLibraryOption): Record<string, string> {
    const headers: Record<string, string> = {
      'X-Amz-Target':
        'com.amazon.cirrus.libraryservice.v3.CirrusLibraryServiceExternalV3.reportClientActions',
      'Content-Encoding': 'amz-1.0',
      'Content-Type': 'application/json',
    };
    if (option.authToken) {
      headers.Authorization = `Bearer ${option.authToken}`;
    }
    return headers;
  }

  public static getBaseRequestBody(option: CirrusLibraryOption): Record<string, string> {
    return {
      deviceId: option.deviceId,
      deviceType: option.deviceType,
    };
  }

  public static getAppVersion(): string {
    return `${AppVersion} - ${'Prod' as Stage}`;
  }

  public static getBaseAdditionalDetails(option: CirrusLibraryOption): Record<string, string> {
    return {
      appVersion: this.getAppVersion(),
      baseEventVersion: BaseEventVersion,
      deviceTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      musicTerritory: option.musicTerritory,
      userAgent: navigator.userAgent,
    };
  }

  public static async emitUiClick(
    uiClick: UiClick,
    contentInfo: Record<string, any>,
    option: CirrusLibraryOption,
  ): Promise<void> {
    const clientAction = {
      actionName: MetricName.CLICK,
      timestamp: new Date().getTime() / 1000,
      additionalDetails: {
        ...uiClick,
        ...this.getBaseAdditionalDetails(option),
        specificEventVersion: UiClickVersion,
      },
      contentInfo: [contentInfo],
    };
    await this.emitMetrics(clientAction, option);
  }

  public static async emitUiContentView(
    uiContentView: UiContentView,
    contentInfo: Record<string, any>,
    option: CirrusLibraryOption,
  ): Promise<void> {
    const clientAction = {
      actionName: MetricName.CONTENT_VIEW,
      timestamp: new Date().getTime() / 1000,
      additionalDetails: {
        ...uiContentView,
        ...this.getBaseAdditionalDetails(option),
        specificEventVersion: UiContentViewVersion,
      },
      contentInfo: [contentInfo],
    };
    await this.emitMetrics(clientAction, option);
  }

  public static async emitUiPageView(
    uiPageView: UiPageView,
    option: CirrusLibraryOption,
  ): Promise<void> {
    const clientAction = {
      actionName: MetricName.PAGE_VIEW,
      timestamp: new Date().getTime() / 1000,
      additionalDetails: {
        ...uiPageView,
        ...this.getBaseAdditionalDetails(option),
        specificEventVersion: UiPageViewVersion,
      },
    };
    await this.emitMetrics(clientAction, option);
  }

  private static async emitMetrics(clientAction: any, option: CirrusLibraryOption) {
    if (process.env.NODE_ENV === 'test') {
      // Add metrics to metrics array for test environment
      const metricsArray = (window as any).metricsArray ?? [];
      metricsArray.push(clientAction);
      (window as any).metricsArray = metricsArray;
    } else if (process.env.NODE_ENV !== 'production') {
      // Log metrics to console for non production environment
      console.log(clientAction);
    } else {
      // Emit metrics to cirrus library service for production environment
      try {
        logger.info(`Emitting metric events`);
        await axios.request({
          baseURL: this.getBaseRequestUrl(),
          method: 'post',
          timeout: 5000,
          headers: this.getBaseRequestHeaders(option),
          data: {
            ...this.getBaseRequestBody(option),
            clientActionList: [clientAction],
          },
        });
      } catch (error) {
        logger.error(`Cirrus library service error: ${String(error)}`);
      }
    }
  }
}
