import { useLocalizationContext } from '@amzn/react-arb-tools';
import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import Modal from 'src/components/Modal/Modal';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { DEFAULT_LOCALE } from 'src/localization/locales';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { logger } from 'src/logger';
import { ActionName, ElementName, PageName } from 'src/metrics/constants';
import { ErrorCode } from 'src/models/error';
import {
  goToAmazonAccount,
  goToAmazonAddresses,
  goToAmazonHelpKYC,
  goToAmazonLogin,
  goToAmazonMusic,
  goToAmazonYourPayments,
} from 'src/navigation/sites';

import { Spacer } from '../Spacer/Spacer';

export interface ErrorModalProps {
  campaignId: string;

  errorCodes: ErrorCode[];
  /**
   * Optional, if not provided this Modal is not cancellable
   */
  onClose?: () => void;
}

const ErrorModal = (props: ErrorModalProps) => {
  const { localizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;

  const bundle = useStringsBundle();

  let showErrorAckButton = true;
  let errorTitle = bundle.getMessage(StringIds.ErrorGenericTitle);
  let errorMessage;
  try {
    switch (true) {
      case props.errorCodes.includes('PURCHASE_ALREADY_CONFIRMED'):
        errorMessage = bundle.getMessage(StringIds.ErrorPurchaseAlreadyConfirmedMessage);
        break;
      case props.errorCodes.includes('LESS_THAN_PERMITTED_QUANTITY'):
        errorMessage = bundle.getMessage(StringIds.ErrorLessThanPermittedQuantityMessage);
        break;
      case props.errorCodes.includes('EXCEEDED_PERMITTED_QUANTITY'):
        errorMessage = bundle.getMessage(StringIds.ErrorExceededPermittedQuantityMessage);
        break;
      case props.errorCodes.includes('NO_MORE_ORDERING_UNITS'):
        errorMessage = bundle.getMessage(StringIds.ErrorNoMoreOrderingUnitsMessage);
        break;
      case props.errorCodes.includes('PRICE_HAS_CHANGED'):
        errorMessage = bundle.getMessage(StringIds.ErrorPriceHasChangedMessage);
        break;
      case props.errorCodes.includes('CANT_SHIP_TO_ADDRESS'):
        errorMessage = bundle.getMessage(StringIds.ErrorCantShipToAddressMessage);
        break;
      case props.errorCodes.includes('BUYER_IS_SELLER'):
        errorMessage = bundle.getMessage(StringIds.ErrorBuyerIsSellerMessage);
        break;
      case props.errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET') &&
        props.errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
        errorMessage = bundle.getMessage(StringIds.ErrorBothDefaultAddressAndPaymentNotSetMessage);
        break;
      case props.errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET'):
        errorMessage = bundle.getMessage(StringIds.ErrorDefaultShippingAddressNotSetMessage);
        break;
      case props.errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
        errorMessage = bundle.getMessage(StringIds.ErrorDefaultPaymentMethodNotSetMessage);
        break;
      case props.errorCodes.includes('UPDATED_DEFAULT_SHIPPING_ADDRESS'):
        errorMessage = bundle.getMessage(StringIds.ErrorUpdatedDefaultShippingAddressMessage);
        break;
      case props.errorCodes.includes('UPDATED_DEFAULT_PAYMENT_METHOD'):
        errorMessage = bundle.getMessage(StringIds.ErrorUpdatedDefaultPaymentMethodMessage);
        break;
      case props.errorCodes.includes('EXPIRED_DEFAULT_PAYMENT_INSTRUMENT'):
        errorMessage = bundle.getMessage(StringIds.ErrorExpiredDefaultPaymentInstrumentMessage);
        break;
      case props.errorCodes.includes('PAYMENT_ADDRESS_MISMATCH'):
        errorMessage = bundle.getMessage(StringIds.ErrorPaymentAddressMismatchMessage);
        break;
      case props.errorCodes.includes('REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION'):
        errorMessage = bundle.getMessage(
          StringIds.ErrorRequiresAdditionalPaymentConfirmationMessage,
        );
        break;
      case props.errorCodes.includes('KYC_DOCUMENT_UPLOAD_REQUIRED'):
        errorMessage = bundle.getMessage(StringIds.ErrorKycDocumentUploadRequiredMessage);
        break;
      case props.errorCodes.includes('ACCESS_DENIED'):
        errorTitle = bundle.getMessage(StringIds.CtaSignInWithAmazonMusic);
        showErrorAckButton = false;
        break;
      case props.errorCodes.includes('CAMPAIGN_NOT_FOUND'):
        errorMessage = bundle.getMessage(StringIds.ErrorCampaignNotFoundMessage);
        break;
      case props.errorCodes.includes('UNKNOWN'):
      default:
        errorMessage = bundle.getMessage(StringIds.ErrorGenericMessage);
        break;
    }
  } catch (error) {
    logger.error(
      `Error getting translated error content, falling back to error generic message. Error: ${error}`,
    );
    errorMessage = bundle.getMessage(StringIds.ErrorGenericMessage);
  }

  const altActionButton = () => {
    let text, onClick, actionName;
    try {
      switch (true) {
        case props.errorCodes.includes('ACCESS_DENIED'):
          text = bundle.getMessage(StringIds.CtaSignIn);
          onClick = () => {
            // An 'ACCESS_DENIED' error could be due to a stale auth token
            // Direct user to Auth Portal sign In
            goToAmazonLogin(currentLocale);
          };
          actionName = ActionName.GO_SIGN_IN;
          break;
        case props.errorCodes.includes('CAMPAIGN_NOT_FOUND'):
          text = bundle.getMessage(StringIds.CtaCheckoutMoreOnAmazonMusic);
          onClick = () => goToAmazonMusic('_blank');
          actionName = ActionName.GO_AMZN_MUSIC;
          break;
        case props.errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET') &&
          props.errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
          text = bundle.getMessage(StringIds.CtaSetupShippingAndPayment);
          onClick = () => goToAmazonAccount('_blank');
          actionName = ActionName.GO_AMZN_ACCOUNT;
          break;
        case props.errorCodes.includes('CANT_SHIP_TO_ADDRESS') ||
          props.errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET'):
          text = bundle.getMessage(StringIds.CtaUpdateShippingAddress);
          onClick = () => goToAmazonAddresses('_blank');
          actionName = ActionName.GO_AMZN_ACCOUNT;
          break;
        case props.errorCodes.includes('EXPIRED_DEFAULT_PAYMENT_INSTRUMENT') ||
          props.errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET') ||
          props.errorCodes.includes('PAYMENT_ADDRESS_MISMATCH'):
          text = bundle.getMessage(StringIds.CtaUpdatePaymentMethod);
          onClick = () => goToAmazonYourPayments('_blank');
          actionName = ActionName.GO_AMZN_ACCOUNT;
          break;
        case props.errorCodes.includes('KYC_DOCUMENT_UPLOAD_REQUIRED'):
          text = bundle.getMessage(StringIds.CtaHelpAndSupport);
          onClick = () => goToAmazonHelpKYC('_blank');
          actionName = ActionName.GO_AMZN_HELP;
          break;
        default:
          return null;
      }
    } catch (error) {
      logger.error(
        `Error getting translated error content, falling back to no alternative action. Error: ${error}`,
      );
      return null;
    }

    return (
      <Button
        text={text}
        onClick={onClick}
        preset={ButtonPreset.SOLID}
        testId={formatTestId(TestIds.Modal, TestIds.CtaButton)}
        metrics={{
          elementName: ElementName.CTA_BUTTON,
          actionName: actionName,
        }}
      />
    );
  };

  return (
    <Modal
      onClose={props.onClose}
      metrics={{
        pageName: PageName.ERROR_MODAL,
        campaignId: props.campaignId,
      }}
    >
      <Spacer size='base' />
      <Text
        text={errorTitle}
        preset={TextPreset.HEADLINE_4}
        color={BauhausColor.primary.solid}
        textAlign='center'
        testId={formatTestId(TestIds.Modal, TestIds.TitleText)}
      />
      {errorMessage && (
        <>
          <Spacer size='base' />
          <Text
            text={errorMessage}
            preset={TextPreset.BODY_1}
            color={BauhausColor.primary.solid}
            textAlign='center'
            testId={formatTestId(TestIds.Modal, TestIds.BodyText)}
          />
        </>
      )}
      <Spacer size='base' />
      {altActionButton()}
      <Spacer size='base' />
      {props.onClose && showErrorAckButton && (
        <Button
          text={bundle.getMessage(StringIds.ErrorAcknowledgementText).toUpperCase()}
          onClick={props.onClose}
          preset={altActionButton() ? ButtonPreset.TRANSPARENT : ButtonPreset.SOLID}
          testId={formatTestId(TestIds.Modal, TestIds.CancelButton)}
          metrics={{
            actionName: ActionName.CLOSE_MODAL,
            elementName: ElementName.CANCEL_BUTTON,
          }}
        />
      )}
    </Modal>
  );
};

export default ErrorModal;
