import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const $descriptionBoxStyle: React.CSSProperties = {
  color: BauhausColor.base.solid,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  width: '100%',
};

export const $descriptionBoxSubtitleStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: BauhausSpacing.mini,
};
