import React from 'react';
import { ImageThumbnailPreset } from 'src/components/ImageThumbnail/ImageThumbnail';

const $baseStyle: React.CSSProperties = {
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  aspectRatio: 1,
};

// NOTE: These border radius values referenced from Bauhaus foundations

const getCircleImageThumbnailBorderRadius = (size: number) => {
  return size / 2;
};

const getSquareImageThumbnailBorderRadius = (size: number) => {
  if (size <= 80) {
    return 4;
  } else if (size > 80 && size < 250) {
    return 8;
  } else {
    return 16;
  }
};

export const getImageThumbnailStyle = (
  preset: ImageThumbnailPreset,
  size: number,
): React.CSSProperties => {
  let borderRadiusValue;
  if (preset === ImageThumbnailPreset.CIRCLE) {
    borderRadiusValue = getCircleImageThumbnailBorderRadius(size);
  } else {
    borderRadiusValue = getSquareImageThumbnailBorderRadius(size);
  }

  return {
    ...$baseStyle,
    borderRadius: borderRadiusValue,
    aspectRatio: 1,
    maxHeight: size,
    maxWidth: size,
  };
};

export const getImageStyle = (): React.CSSProperties => {
  return {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    flex: '1',
    display: 'flex',
    objectFit: 'cover',
  };
};
