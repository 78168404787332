import React from 'react';
import { BauhausIcons, BauhausIconType } from 'src/bauhaus/iconography';
import { IconSize } from 'src/components/Icon/style';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ActionName, ElementName } from 'src/metrics/constants';

export type IconType = BauhausIconType;

export type IconSizeType = keyof typeof IconSize;

export interface IconProps {
  icon: IconType;
  onClick?: () => void;
  color?: string;
  size?: IconSizeType;
  marginLeft?: number;
  marginRight?: number;
  testId?: string;
  metrics?: {
    elementName: ElementName;
    actionName?: ActionName;
  };
}
const Icon = (props: IconProps) => {
  const elementName = props.metrics?.elementName ?? ElementName.ICON;
  const { uiClick, uiContentView } = useUiMetrics();
  uiContentView({ elementName });

  const IconComponent = BauhausIcons[props.icon];

  const iconSizeType = props.size ?? 'MD';
  const iconSize = IconSize[iconSizeType];

  const iconStyle: React.CSSProperties = {
    cursor: props.onClick ? 'pointer' : 'inherit',
    width: iconSize,
    height: iconSize,
    minWidth: iconSize,
    marginLeft: props.marginLeft ?? 0,
    marginRight: props.marginRight ?? 0,
  };

  return (
    <div
      data-testid={props.testId}
      style={iconStyle}
      onClick={() => {
        props.onClick?.();
        if (props.onClick) {
          uiClick({
            actionType: props.metrics?.actionName ?? ActionName.NONE,
            elementName,
          });
        }
      }}
    >
      <IconComponent fill={props.color} height='100%' width='100%' />
    </div>
  );
};

export default Icon;
