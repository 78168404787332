import { AxiosHeaders, AxiosRequestConfig } from 'axios';

interface ApiErrorResponse extends Error {
  config: AxiosRequestConfig;
  request: XMLHttpRequest;
  response?: {
    data: {
      errorCodes: string[];
      message: string;
    };
    status: number;
    statusText: string;
    headers: AxiosHeaders;
    config: AxiosRequestConfig;
  };
  message: string;
  name: string;
  code?: string;
  stack?: string;
}

/**
 Possible error codes that we want to be explicit in our messaging to customers
 PURCHASE_ALREADY_CONFIRMED: Purchase is already confirmed
 LESS_THAN_PERMITTED_QUANTITY: The quantity selected is less than permitted minimum quantity for the item.
 EXCEEDED_PERMITTED_QUANTITY: The quantity selected exceeds permitted quantity for the item.
 EXCEEDED_AVAILABLE_QUANTITY: The quantity selected exceeds available quantity for the item.
 NO_MORE_ORDERING_UNITS: Cannot deliver this item anymore because available quantity is 0.
 PRICE_HAS_CHANGED: The item price has changed
 CANT_SHIP_TO_ADDRESS: An item cannot be shipped to the selected destination
 BUYER_IS_SELLER: A seller can't buy its own product.
 DEFAULT_SHIPPING_ADDRESS_NOT_SET: Customer does not have a default shipping address set.
 DEFAULT_PAYMENT_METHOD_NOT_SET: Customer does not have a default payment method set.
 UPDATED_DEFAULT_SHIPPING_ADDRESS: Customer updated default shipping address after preview, and before confirm order.
 UPDATED_DEFAULT_PAYMENT_METHOD: Customer updated default payment method after preview, and before confirm order.
 EXPIRED_DEFAULT_PAYMENT_INSTRUMENT: Default payment method has expired.
 PAYMENT_ADDRESS_MISMATCH: Default payment method address is not associated with default shipping address.
 REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION: Order requires additional payment confirmation.
 KYC_DOCUMENT_UPLOAD_REQUIRED: Customer must upload Know Your Customer documentation on Amazon account.
 */
const errorCode = [
  'PURCHASE_ALREADY_CONFIRMED',
  'LESS_THAN_PERMITTED_QUANTITY',
  'EXCEEDED_PERMITTED_QUANTITY',
  'NO_MORE_ORDERING_UNITS',
  'PRICE_HAS_CHANGED',
  'CANT_SHIP_TO_ADDRESS',
  'BUYER_IS_SELLER',
  'DEFAULT_SHIPPING_ADDRESS_NOT_SET',
  'DEFAULT_PAYMENT_METHOD_NOT_SET',
  'UPDATED_DEFAULT_SHIPPING_ADDRESS',
  'UPDATED_DEFAULT_PAYMENT_METHOD',
  'EXPIRED_DEFAULT_PAYMENT_INSTRUMENT',
  'PAYMENT_ADDRESS_MISMATCH',
  'REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION',
  'KYC_DOCUMENT_UPLOAD_REQUIRED',
  'ACCESS_DENIED',
  'CAMPAIGN_NOT_FOUND',
  'UNKNOWN',
] as const;
export type ErrorCode = (typeof errorCode)[number];

const isValidErrorCode = (code: string): code is ErrorCode => {
  return Object.values(errorCode).includes(code as ErrorCode);
};

export const transformToErrorCodes = (error: any): ErrorCode[] => {
  const apiError = error as ApiErrorResponse;
  const status = apiError?.response?.status;
  if (status) {
    switch (true) {
      case status === 403:
        return ['ACCESS_DENIED'];
      case status >= 500 && status < 600:
        return ['UNKNOWN'];
      default:
        break;
    }
  }

  return (
    apiError?.response?.data?.errorCodes
      .filter((code: string) => isValidErrorCode(code))
      .map((code: string) => code as ErrorCode) ?? ['UNKNOWN']
  );
};
