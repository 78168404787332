import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import Icon from 'src/components/Icon/Icon';
import ImageThumbnail, { ImageThumbnailPreset } from 'src/components/ImageThumbnail/ImageThumbnail';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ActionName, ElementName } from 'src/metrics/constants';

export interface HintBoxProps {
  imageSrc: string;
  text: string;
  subtext?: string;
  showHintIcon?: boolean;
  onClickHintIcon?: () => void;
  testId?: string;
}

const HintBox = (props: HintBoxProps) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({ elementName: ElementName.HINT_BOX });

  const hintBoxStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0px',
    gap: 12,
  };

  const hintBoxTextStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <div data-testid={formatTestId(TestIds.HintBox)} style={hintBoxStyle}>
      <ImageThumbnail
        src={props.imageSrc}
        preset={ImageThumbnailPreset.CIRCLE}
        size={56}
        alt={'Artist profile image thumbnail'}
        testId={formatTestId(TestIds.HintBox, TestIds.ImageThumbnail)}
      />
      <div style={hintBoxTextStyle}>
        <Text
          text={props.text}
          preset={TextPreset.BODY_1}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.HintBox, TestIds.TitleText)}
        />
        {props.subtext && (
          <Text
            text={props.subtext}
            preset={TextPreset.BODY_2}
            color={BauhausColor.primary.glass4}
            testId={formatTestId(TestIds.HintBox, TestIds.SubtitleText)}
          />
        )}
      </div>
      {props.showHintIcon && props.onClickHintIcon && (
        <Icon
          icon={'help'}
          onClick={props.onClickHintIcon}
          color={BauhausColor.primary.solid}
          size={'tiny'}
          testId={formatTestId(TestIds.HintBox, TestIds.HelpIcon)}
          metrics={{
            elementName: ElementName.HELP_ICON,
            actionName: ActionName.OPEN_HINT,
          }}
        />
      )}
    </div>
  );
};

export default HintBox;
