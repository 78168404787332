import { EntityIdType, EntityType, PageName } from 'src/metrics/constants';

// https://console.harmony.a2z.com/music-metrics/docs/event/uiPageView
export interface UiPageView {
  activityId?: string;
  activityIdType?: string;
  associateTag?: string;
  blockRef?: string;
  csSessionId?: string;
  customSessionId?: string;
  customSessionType?: string;
  detailPageItemId?: string;
  detailPageItemIdType?: string;
  itemText?: string;
  loadTimeMilliseconds?: string;
  locale?: string;
  pageEntityId?: string;
  pageEntityIdType?: EntityIdType;
  pageEntityType?: EntityType;
  pageSubType?: string;
  refMarker?: string;
  referer?: string;
  responseId?: string;
  responseIdType?: string;
  searchQuery?: string;
  url?: string;
  userAlias?: string;
  viewType?: string;
  pageType: PageName;
}

export const UiPageViewVersion = '9';
