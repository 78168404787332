import { SVGProps } from 'react';

const MaximizeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M16.9401 18.3545L15.147 20.1476C15.015 20.2796 14.967 20.4756 15.024 20.6536C15.081 20.8316 15.233 20.9636 15.418 20.9937L21.4184 21.9937C21.5764 22.0187 21.7394 21.9687 21.8544 21.8537C21.9684 21.7397 22.0194 21.5767 21.9944 21.4177L20.9943 15.4174C20.9633 15.2323 20.8323 15.0803 20.6543 15.0233C20.4753 14.9663 20.2803 15.0133 20.1483 15.1463L18.3552 16.9394L7.06052 5.64584L8.85362 3.85275C8.98563 3.72074 9.03363 3.52473 8.97663 3.34672C8.91963 3.16871 8.76762 3.0367 8.58261 3.0067L2.58226 2.00665C2.42325 1.98065 2.26024 2.03165 2.14624 2.14666C2.03223 2.26066 1.98123 2.42367 2.00623 2.58268L3.00629 8.583C3.03729 8.76801 3.16829 8.92001 3.3463 8.97702C3.52532 9.03402 3.72033 8.98702 3.85233 8.85401L5.64544 7.06091L16.9401 18.3545Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default MaximizeIcon;
