import React from 'react';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const $productContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '880px',
  position: 'relative',
  zIndex: 1,
};

export const $productContentStyle: React.CSSProperties = {
  backgroundColor: BauhausColor.primary.solid,
};

export const $productDescriptionStyle: React.CSSProperties = {
  padding: BauhausSpacing.large,
  maxWidth: '435px',
  margin: 'auto',
};

export const $actionSheetStyle: React.CSSProperties = {
  backgroundColor: BauhausColor.base.solid,
  borderRadius: `0 0 ${BauhausBorder.radius.LG}px ${BauhausBorder.radius.LG}px`,
  color: BauhausColor.primary.solid,
  display: 'flex',
  flexDirection: 'column',
  gap: BauhausSpacing.small,
  alignItems: 'center',
  padding: BauhausSpacing.large,
};
