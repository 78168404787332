import Lottie from 'lottie-react';
import React from 'react';
import { TestIds } from 'src/components/testIds';

const donutAnimation = require('src/assets/animations/donutLoader.json');

export interface LoadingSpinnerProps {
  size?: number;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const size = props.size ?? 64;

  const $spinnerStyle: React.CSSProperties = { width: size, height: size };

  return (
    <Lottie
      data-testid={TestIds.LoadingSpinner}
      autoPlay
      loop
      animationData={donutAnimation}
      style={$spinnerStyle}
    />
  );
};

export default LoadingSpinner;
