const fontFamily = {
  AmazonEmberBold: 'AmazonEmber-Bold',
  AmazonEmberRegular: 'AmazonEmber-Regular',
  SharpGroteskPEMedium: 'SharpGroteskPEMedium-16',
  SharpGroteskPESemibold: 'SharpGroteskPESmBld-20',
  SharpGroteskPEBold: 'SharpGroteskPEBold-20',
};

const lineHeightPercentage = {
  /** Line height 160% */
  XL: '160%',

  /** Line height 140% */
  LG: '140%',

  /** Line height 136% */
  MD: '136%',

  /** Line height 134% */
  SM: '134%',

  /** Line height 126% */
  SM1: '126%',

  /** Line height 113% */
  SM2: '113%',
};

const fontSize: Record<string, number> = {
  /** Font size 11 */
  font50: 11,
  /** Font size 12 */
  font100: 12,
  /** Font size 13 */
  font200: 13,
  /** Font size 14 */
  font300: 14,
  /** Font size 15 */
  font400: 15,
  /** Font size 16 */
  font500: 16,
  /** Font size 18 */
  font600: 18,
  /** Font size 20 */
  font700: 20,
  /** Font size 28 */
  font800: 28,
  /** Font size 32 */
  font900: 32,
  /** Font size 38 */
  font1000: 38,
  /** Font size 60 */
  font1100: 60,
  /** Font size 72 */
  font1200: 72,
};

export const BauhausTypography = {
  fontFamily: fontFamily,
  fontSize: fontSize,
  lineHeight: lineHeightPercentage,
};
