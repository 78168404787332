import React from 'react';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';

export const containerStyle: React.CSSProperties = {
  backgroundColor: BauhausColor.secondary.solid,
  borderRadius: `${BauhausBorder.radius.XL}px ${BauhausBorder.radius.XL}px 0 0`,
  height: 'auto',
  display: 'grid',
  gridTemplateColumns: '60px auto 60px',
};

const menuStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

export const leftMenuStyle: React.CSSProperties = {
  ...menuStyle,
  gridColumn: 1,
  justifyContent: 'center',
};

export const titleStyle: React.CSSProperties = {
  ...menuStyle,
  justifyContent: 'center',
  gridColumn: 2,
  overflow: 'hidden',
};

export const rightMenuStyle: React.CSSProperties = {
  ...menuStyle,
  gridColumn: 3,
  justifyContent: 'center',
};
