import { MonetaryValue } from 'src/models/common';

export interface PrimeBadge {
  altText?: string;
  isPrime: boolean;
  miniPrimeBadgeUrl?: string;
  smallPrimeBadgeUrl?: string;
}

export interface Product {
  asin: string;
  title: string;
  availability: ProductAvailability;
  shortDescriptionBullets: string[];
  // Price may not be defined when product is out of stock
  price?: MonetaryValue;
  primeBadge: PrimeBadge;
  productImages: ProductImages;
  productVariations?: ProductVariations;
  quantity: ProductQuantity;
  offerId?: string;
  merchantId?: string;
  privacyPolicyURL: string;
  conditionsOfUseURL: string;
}

export enum ProductAvailabilityType {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  PREORDER = 'PREORDER',
  UNKNOWN = 'UNKNOWN',
}

export interface ProductAvailability {
  type: string;
  primaryMessage?: string;
}

export interface ProductImage {
  url: string;
}

export interface ProductImages {
  images: ProductImage[];
  altText: string;
}

export interface ProductQuantity {
  minOrderQuantity: number;
  maxOrderQuantity?: number;
}

export interface ProductVariationDimension {
  // The label of the variation category
  // E.g. Color, Size
  name: string;
  // The possible values for a variation category
  // E.g. Red, Blue
  values: string[];
}

export interface Variation {
  // The product ASIN for a specific combination of variation selection
  asin: string;
  // A list that maps to the values selected for each product dimension
  // E.g. For Color with values [Red, Blue] and Size with values [9, 11, 13]
  // the index values for color red and size 13 and red would be [0, 2]
  dimensionValueIndexes: number[];
  // A field used for identifying, retrieving and purchasing customizable products
  // Required only for customizable products
  customId?: string;
  // A field used for identifying, retrieving and purchasing customizable products
  // Required only for customizable products
  customizationToken?: string;
}
export interface ProductVariations {
  variationDimensions: ProductVariationDimension[];
  variations: Variation[];
}
