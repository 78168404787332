import React from 'react';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import { ButtonPreset } from 'src/components/Button/Button';
import { IconSizeType } from 'src/components/Icon/Icon';
import { TextPreset } from 'src/components/Text/Text';

export const ButtonSize = {
  XL: 64,
  LG: 48,
  MD: 36,
  SM: 28,
};

const $baseStyle: React.CSSProperties = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderStyle: 'solid',
};

const $fullWidthStyle: React.CSSProperties = {
  width: '100%',
  justifyContent: 'center',
};

const $disabledStyle: Record<ButtonPreset, React.CSSProperties> = {
  solid: {
    backgroundColor: BauhausColor.accent.secondaryGlass4,
    color: BauhausColor.secondary.solid,
  },
  outlined: {
    backgroundColor: BauhausColor.transparent,
    borderColor: BauhausColor.accent.secondaryGlass4,
    color: BauhausColor.accent.secondaryGlass4,
  },
  glass: {
    backgroundColor: BauhausColor.primary.glass1,
    color: BauhausColor.primary.glass3,
  },
  transparent: {
    backgroundColor: BauhausColor.transparent,
    color: BauhausColor.primary.glass3,
  },
};

const $presetStyle: Record<ButtonPreset, React.CSSProperties> = {
  solid: {
    ...$baseStyle,
    backgroundColor: BauhausColor.accent.solid,
    color: BauhausColor.secondary.solid,
  },
  outlined: {
    ...$baseStyle,
    backgroundColor: BauhausColor.transparent,
    borderColor: BauhausColor.accent.solid,
    color: BauhausColor.accent.solid,
  },
  glass: {
    ...$baseStyle,
    backgroundColor: BauhausColor.primary.glass2,
    color: BauhausColor.primary.solid,
  },
  transparent: {
    ...$baseStyle,
    backgroundColor: BauhausColor.transparent,
    color: BauhausColor.primary.solid,
  },
};

const getButtonBorderRadius = (buttonSize: number): number => {
  if (buttonSize === ButtonSize.XL) {
    return ButtonSize.XL / 2;
  } else {
    return BauhausBorder.radius.XL2;
  }
};
const getButtonBorderWidth = (buttonPreset: ButtonPreset): number => {
  return buttonPreset === ButtonPreset.OUTLINED ? BauhausBorder.width.MD : 0;
};

export const getButtonStyle = (
  buttonPreset: ButtonPreset,
  buttonSize: number,
  buttonPaddingSize: number,
  fullWidth?: boolean,
  disabled?: boolean,
) => {
  const buttonBorderWidth = getButtonBorderWidth(buttonPreset);
  const buttonBorderRadius = getButtonBorderRadius(buttonSize);

  let buttonStyle: React.CSSProperties = {
    ...$presetStyle[buttonPreset],
    borderWidth: buttonBorderWidth,
    borderRadius: buttonBorderRadius,
    height: buttonSize,
    minHeight: buttonSize,
    paddingLeft: buttonPaddingSize ?? buttonPaddingSize - buttonBorderWidth,
    paddingRight: buttonPaddingSize ?? buttonPaddingSize - buttonBorderWidth,
  };

  if (fullWidth) {
    buttonStyle = {
      ...buttonStyle,
      ...$fullWidthStyle,
    };
  }

  if (disabled) {
    buttonStyle = {
      ...buttonStyle,
      ...$disabledStyle[buttonPreset],
    };
  }

  return buttonStyle;
};

export const getButtonPaddingSize = (buttonSize: number) => {
  if (buttonSize === ButtonSize.XL) {
    return BauhausSpacing.medium;
  } else if (buttonSize === ButtonSize.LG) {
    return BauhausSpacing.small;
  } else {
    return BauhausSpacing.mini;
  }
};

export const getButtonSpacingSize = (buttonSize: number): number => {
  if (buttonSize === ButtonSize.XL) {
    return BauhausSpacing.medium;
  } else if (buttonSize === ButtonSize.LG || buttonSize === ButtonSize.MD) {
    return BauhausSpacing.small;
  } else {
    return 0;
  }
};

export const getButtonIconSizeType = (buttonSize: number): IconSizeType | null => {
  if (buttonSize === ButtonSize.XL || buttonSize === ButtonSize.LG) {
    return 'MD';
  } else if (buttonSize === ButtonSize.MD) {
    return 'tiny';
  } else {
    return null;
  }
};

export const getIconMarginSize = (buttonSize: number): number => {
  if (buttonSize === ButtonSize.XL) {
    return BauhausSpacing.small;
  } else if (buttonSize === ButtonSize.LG || buttonSize === ButtonSize.MD) {
    return BauhausSpacing.micro;
  } else {
    return 0;
  }
};

export const getButtonTextPreset = (buttonSize: number): TextPreset => {
  if (buttonSize === ButtonSize.XL) {
    return TextPreset.HEADLINE_4_BOLD;
  } else if (buttonSize === ButtonSize.LG) {
    return TextPreset.BODY_1;
  } else if (buttonSize === ButtonSize.MD) {
    return TextPreset.BODY_2;
  } else {
    return TextPreset.BODY_3_BOLD_CAPS;
  }
};

export const getTextMarginSize = (buttonSize: number): number => {
  if (buttonSize === ButtonSize.XL) {
    return BauhausSpacing.medium;
  } else if (buttonSize === ButtonSize.LG) {
    return BauhausSpacing.small;
  } else {
    return BauhausSpacing.mini;
  }
};
