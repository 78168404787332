// Dictionary of strings ids in src/i18n/strings.puff.json.
// New strings should also be added here.
export const StringIds = {
  ArtistMessageFrom: 'artist-message-from',
  AvailableUntilDate: 'available-until-date',
  ContentLabelExclusive: 'content-label-exclusive',
  CtaBuyNowWithAmazon: 'cta-buy-now-with-amazon',
  CtaCancel: 'cta-cancel',
  CtaChangeCheckoutInfo: 'cta-change-checkout-info',
  CtaCheckItOut: 'cta-check-it-out',
  CtaCheckoutMoreOnAmazonMusic: 'cta-checkout-more-on-amazon-music',
  CtaCheckoutWithAmazon: 'cta-checkout-with-amazon',
  CtaListenOnAmazonMusic: 'cta-listen-on-amazon-music',
  CtaSignInWithAmazonMusic: 'cta-sign-in-with-amazon-music',
  CtaPlaceOrder: 'cta-place-order',
  CtaPreorderNow: 'cta-preorder-now',
  CtaReadLess: 'cta-read-less',
  CtaReadMore: 'cta-read-more',
  CtaSignIn: 'cta-sign-in',
  CtaSoldOut: 'cta-sold-out',
  CtaViewOnAmazon: 'cta-view-on-amazon',
  CtaDownloadAmazonMusicApp: 'cta-download-amazon-music-app',
  CtaGoBack: 'cta-go-back',
  CtaHelpAndSupport: 'cta-amazon-help-and-support',
  CtaSetupShippingAndPayment: 'cta-amazon-setup-shipping-and-payment',
  CtaUpdateShippingAddress: 'cta-amazon-update-shipping-address',
  CtaUpdatePaymentMethod: 'cta-amazon-update-payment-method',
  EligibilityCategoryTopListener: 'eligibility-category-top-listener',
  EligibilityInfoTitle: 'eligibility-info-title',
  EligibilityInfoTextTopListener: 'eligibility-info-text-top-listener',
  EligibilityMessageTopListener: 'eligibility-message-top-listener',
  EligibilityMessageTopListenerEligible: 'eligibility-message-top-listener-eligible',
  EligibilityMessageTopListenerIneligible: 'eligibility-message-top-listener-ineligible',
  EligibilityUnlockMessageMerch: 'eligibility-unlock-message-merch',
  ErrorGenericTitle: 'error-title',
  ErrorPurchaseAlreadyConfirmedMessage: 'error-purchase-already-confirmed-message',
  ErrorLessThanPermittedQuantityMessage: 'error-less-than-permitted-quantity-message',
  ErrorExceededPermittedQuantityMessage: 'error-exceeded-permitted-quantity-message',
  ErrorNoMoreOrderingUnitsMessage: 'error-no-more-ordering-units-message',
  ErrorPriceHasChangedMessage: 'error-price-has-changed-message',
  ErrorCantShipToAddressMessage: 'error-cant-ship-to-address-message',
  ErrorBuyerIsSellerMessage: 'error-buyer-is-seller-message',
  ErrorBothDefaultAddressAndPaymentNotSetMessage:
    'error-default-address-and-payment-not-set-message',
  ErrorDefaultShippingAddressNotSetMessage: 'error-default-shipping-address-not-set-message',
  ErrorDefaultPaymentMethodNotSetMessage: 'error-default-payment-method-not-set-message',
  ErrorUpdatedDefaultShippingAddressMessage: 'error-updated-default-shipping-address-message',
  ErrorUpdatedDefaultPaymentMethodMessage: 'error-updated-default-payment-method-message',
  ErrorExpiredDefaultPaymentInstrumentMessage: 'error-expired-default-payment-instrument-message',
  ErrorPaymentAddressMessage: 'error-payment-address-message',
  ErrorPaymentAddressMismatchMessage: 'error-payment-address-mismatch-message',
  ErrorRequiresAdditionalPaymentConfirmationMessage:
    'error-requires-additional-payment-confirmation-message',
  ErrorKycDocumentUploadRequiredMessage: 'error-kyc-document-upload-required-message',
  ErrorAccessDeniedMessage: 'error-access-denied-message',
  ErrorCampaignNotFoundMessage: 'error-campaign-not-found-message',
  ErrorGenericMessage: 'error-generic-message',
  ErrorOrderFailedMessage: 'error-order-failed-message',
  ErrorAcknowledgementText: 'error-acknowledgement-text',
  ExpiredCampaignSubtitle: 'expired-campaign-subtitle',
  ExpiredCampaignText: 'expired-campaign-text',
  InfoTitle: 'info-title',
  InfoSubtitle: 'info-subtitle',
  InfoText: 'info-text',
  OrderCheckoutCardName: 'order-checkout-card-name',
  OrderCheckoutDelivery: 'order-checkout-delivery',
  OrderCheckoutPayWith: 'order-checkout-pay-with',
  OrderCheckoutShipTo: 'order-checkout-ship-to',
  OrderCheckoutTitle: 'order-checkout-title',
  OrderCheckoutTotal: 'order-checkout-total',
  OrderCheckoutTotalItemsQuantity: 'order-checkout-total-items-quantity',
  OrderCheckoutTotalShipping: 'order-checkout-total-shipping',
  OrderCheckoutTotalShippingDiscount: 'order-checkout-total-shipping-discount',
  OrderCheckoutTotalTax: 'order-checkout-total-tax',
  OrderCheckoutImportExportFee: 'order-checkout-import-export-fee',
  OrderCheckoutRegulatoryFee: 'order-checkout-regulatory-fee',
  OrderCheckoutDiscounts: 'order-checkout-discounts',
  OrderConfirmationQuestion: 'order-confirmation-question',
  OrderDetailArrivalDate: 'order-detail-arrival-date',
  OrderDetailQuantity: 'order-detail-quantity',
  OrderConfirmationText: 'order-confirmation-text',
  OrderConfirmationComplete: 'order-confirmation-complete',
  OrderSelectionQuantity: 'order-selection-quantity',
  OrderSelectionQuantityLimitPerCustomer: 'order-selection-quantity-limit-per-customer',
  OrderTermsAnd: 'order-terms-and',
  OrderTermsConditionsOfUse: 'order-terms-conditions-of-use',
  OrderTermsPrefix: 'order-terms-prefix',
  OrderTermsPrivacyNotice: 'order-terms-privacy-notice',
  PreorderPriceGuarantee: 'preorder-price-guarantee',
  ProductUnavailable: 'product-unavailable',
  Terms: 'terms',
};
