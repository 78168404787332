import 'src/components/DescriptionBox/DescriptionBox.css';

import ShowMoreText from 'react-show-more-text';
import { BauhausColor } from 'src/bauhaus/color';
import {
  $descriptionBoxStyle,
  $descriptionBoxSubtitleStyle,
} from 'src/components/DescriptionBox/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ElementName } from 'src/metrics/constants';
import { PrimeBadge } from 'src/models/product';

export interface DescriptionBoxProps {
  title: string;
  subtitle?: string;
  text: string[];
  primeBadge?: PrimeBadge;
  expandable?: boolean;
}

const DescriptionBox = (props: DescriptionBoxProps) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({ elementName: ElementName.DESCRIPTION_BOX });

  const bundle = useStringsBundle();

  return (
    <div data-testid={formatTestId(TestIds.DescriptionBox)} style={$descriptionBoxStyle}>
      <Text
        text={props.title}
        preset={TextPreset.HEADLINE_4}
        color={BauhausColor.base.solid}
        testId={formatTestId(TestIds.DescriptionBox, TestIds.TitleText)}
        truncate={true}
      />
      <div style={$descriptionBoxSubtitleStyle}>
        {props.subtitle && (
          <Text
            text={props.subtitle}
            preset={TextPreset.HEADLINE_4}
            color={BauhausColor.secondary.glass4}
            testId={formatTestId(TestIds.DescriptionBox, TestIds.SubtitleText)}
          />
        )}
        {props.primeBadge?.isPrime && props.primeBadge?.miniPrimeBadgeUrl && (
          <img
            width={70}
            height={20}
            data-testid={formatTestId(TestIds.DescriptionBox, 'PrimeBadge')}
            src={props.primeBadge?.miniPrimeBadgeUrl}
            alt={props.primeBadge?.altText}
          />
        )}
      </div>
      {props.expandable ? (
        <ShowMoreText
          anchorClass='description-box__anchor'
          className='description-box__text'
          more={bundle.getMessage(StringIds.CtaReadMore)}
          less={bundle.getMessage(StringIds.CtaReadLess)}
          lines={4}
          keepNewLines={props.text.length > 1}
        >
          {props.text.join('\n\n').concat('\n\n')}
        </ShowMoreText>
      ) : (
        props.text.map((t, k) => <Text key={k} text={t} preset={TextPreset.BODY_1} />)
      )}
    </div>
  );
};

export default DescriptionBox;
