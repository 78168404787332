import { SVGProps } from 'react';

const SelectionIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M5.60006 13.5999C5.39526 13.5999 5.19047 13.5215 5.03447 13.3656L0.234594 8.56567C-0.078198 8.25288 -0.078198 7.74729 0.234594 7.4345C0.547386 7.12171 1.05297 7.12171 1.36577 7.4345L5.60006 11.6688L14.6342 2.63462C14.947 2.32183 15.4526 2.32183 15.7654 2.63462C16.0782 2.94741 16.0782 3.453 15.7654 3.76579L6.16565 13.3656C6.00965 13.5215 5.80485 13.5999 5.60006 13.5999Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default SelectionIcon;
