import {
  ConfirmOrderRequest,
  GetProductDetailsRequest,
  MarketplaceIDEnum,
  PreviewOrderRequest,
  RequestItem,
} from '@amzn/electricfeel-purchase-client';
import { ElectricFeelPurchaseService } from 'src/api/electricFeelPurchaseService';
import { logger } from 'src/logger';
import { Order } from 'src/models/order';
import { Product, Variation } from 'src/models/product';
import { transformToSupportedLocale } from 'src/transformers/locale';
import { transformToOrder } from 'src/transformers/order';
import { transformToProduct } from 'src/transformers/product';
import { v4 as uuid } from 'uuid';

const client = new ElectricFeelPurchaseService();

export const getProductDetails = async (
  locale: string,
  asin: string,
  isAuthenticated: boolean = false,
  customId?: string,
  customizationToken?: string,
): Promise<Product> => {
  const request: GetProductDetailsRequest = {
    asin: asin,
    locale: transformToSupportedLocale(locale),
    customId: customId,
    customizationToken: customizationToken,
  };

  logger.info(`Getting product details for asin ${asin}`);

  try {
    const response = await client.getProductDetails(request, isAuthenticated);
    return transformToProduct(response);
  } catch (error) {
    logger.error(`Error getting product details for asin ${asin}`, error as Error);
    throw error;
  }
};

export const checkout = async (
  locale: string,
  campaignId: string,
  product: Product,
  quantity: number,
  variation?: Variation,
): Promise<Order> => {
  if (!product.offerId || !product.merchantId) {
    const errorMessage = 'Unable to preview order due to missing offerId / merchantId';
    logger.error(errorMessage);
    throw new Error(errorMessage);
  }

  const requestItem: RequestItem = {
    asin: product.asin,
    customId: variation?.customId,
    customizationToken: variation?.customizationToken,
    quantity: quantity,
    offerId: product.offerId,
    merchantId: product.merchantId,
  };

  const request: PreviewOrderRequest = {
    campaignId: campaignId,
    marketplaceId: MarketplaceIDEnum.US,
    clientRequestId: uuid(),
    items: [requestItem],
    locale: transformToSupportedLocale(locale),
  };

  logger.info(`Getting order preview for asin ${product.asin}`);

  try {
    const response = await client.previewOrder(request);
    return transformToOrder(response);
  } catch (error) {
    logger.error(`Error getting preview order for asin ${product.asin}`, error as Error);
    throw error;
  }
};

export const placeOrder = async (purchaseId: string) => {
  const request: ConfirmOrderRequest = { purchaseId };

  logger.info(`Confirming order with purchase id ${purchaseId}`);

  try {
    const response = await client.confirmOrder(request);
    return transformToOrder(response);
  } catch (error) {
    logger.error(`Error getting confirming order with purchase id ${purchaseId}`, error as Error);
    throw error;
  }
};
