import { EntityIdType, EntityType, PageName } from 'src/metrics/constants';

// https://console.harmony.a2z.com/music-metrics/docs/event/uiContentView
export interface UiContentView {
  activityId?: string;
  activityIdType?: string;
  csSessionId?: string;
  customSessionId?: string;
  customSessionType?: string;
  pageSubType?: string;
  pageType?: PageName;
  pageEntityId?: string;
  pageEntityIdType?: EntityIdType;
  pageEntityType?: EntityType;
  entityId?: string;
  entityIdType?: EntityIdType;
  entityType?: EntityType;
  responseId?: string;
  responseIdType?: EntityIdType;
  searchQuery?: string;
  contentInfo?: string;
  emittedOnView?: string;
}

export const UiContentViewVersion = '11';
