import React from 'react';
import { formatTestId, TestIds } from 'src/components/testIds';
import { getTextPresetStyle } from 'src/components/Text/style';

export enum TextPreset {
  HEADLINE_1 = 'headline1',
  HEADLINE_2 = 'headline2',
  HEADLINE_3 = 'headline3',
  HEADLINE_4 = 'headline4',
  HEADLINE_4_BOLD = 'headline4Bold',
  BODY_1 = 'body1',
  BODY_1_BOLD = 'body1Bold',
  BODY_1_PARAGRAPH = 'body1Paragraph',
  BODY_2 = 'body2',
  BODY_2_BOLD = 'body2Bold',
  BODY_2_PARAGRAPH = 'body2Paragraph',
  BODY_3 = 'body3',
  BODY_3_BOLD = 'body3Bold',
  BODY_3_CAPS = 'body3Caps',
  BODY_3_BOLD_CAPS = 'body3BoldCaps',
  BODY_3_PARAGRAPH = 'body3Paragraph',
  BODY_INDEX = 'bodyIndex',
  BODY_LABEL = 'bodyLabel',
  BODY_XL_REGULAR = 'bodyXLRegular',
}

export type TextAlignType = 'left' | 'center' | 'right';

export interface TextProps {
  text: string;
  preset: TextPreset;
  color?: string;
  textAlign?: TextAlignType;
  marginLeft?: number;
  marginRight?: number;
  testId?: string;
  truncate?: boolean;
  lines?: number;
}

const Text = (props: TextProps) => {
  const textStyle: React.CSSProperties = {
    ...getTextPresetStyle(props.preset),
    color: props.color,
    textAlign: props.textAlign,
    marginLeft: props.marginLeft ?? 0,
    marginRight: props.marginRight ?? 0,
    textOverflow: props.truncate ? 'ellipsis' : 'initial',
    overflow: props.truncate ? 'hidden' : 'initial',
    display: props.truncate ? '-webkit-box' : 'initial',
    WebkitBoxOrient: props.truncate ? 'vertical' : 'initial',
    WebkitLineClamp: props.lines ? props.lines : props.truncate ? 2 : 'initial',
  };

  const testId = props.testId ?? TestIds.Text;
  return (
    <div data-testid={formatTestId(testId)} style={textStyle}>
      {props.text}
    </div>
  );
};

export default Text;
