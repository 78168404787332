/**
 * Palette
 * Names for all our colors / opacities
 * All colors should be defined here, with human readable names
 * no color names should be related to their function
 *
 * For official Bauhaus color definitions see:
 * https://quip-amazon.com/g3v3A7SQrV1D/Color
 */

export const BauhausColorPalette = {
  neutral0: { R: 255, G: 255, B: 255 },
  neutral700: { R: 20, G: 25, B: 26 },
  neutral900: { R: 0, G: 0, B: 0 },

  cyan50: { R: 168, G: 237, B: 240 },
  cyan100: { R: 37, G: 209, B: 218 },
  cyan200: { R: 26, G: 146, B: 153 },
  cyan300: { R: 15, G: 84, B: 87 },
  cyan400: { R: 11, G: 63, B: 65 },
  cyan500: { R: 6, G: 31, B: 33 },

  primeBlue: { R: 0, G: 168, B: 225 },
  hdYellow: { R: 255, G: 223, B: 0 },
  errorRed: { R: 244, G: 62, B: 33 },
  liveRed: { R: 190, G: 65, B: 42 },
};
