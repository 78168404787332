import React from 'react';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const outerContainerStyle: React.CSSProperties = {
  zIndex: 1000,
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  outline: 'none',
};

export const getInnerContainerStyle = (): React.CSSProperties => {
  return {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    border: 0,
    outline: 'none',
    maxWidth: 540,
    padding: BauhausSpacing.medium,
  };
};

export const getCloseButtonStyle = (): React.CSSProperties => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
  };
};
