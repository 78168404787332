import './ImageCarousel.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import React from 'react';
import { formatTestId, TestIds } from 'src/components/testIds';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ActionName, ElementName } from 'src/metrics/constants';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface ImageCarouselProps {
  // The product ASIN
  asin: string;
  // A list of image URLs
  images: string[];
  // The height of the carousel container
  height: number;
  // The width of the carousel container
  // Defaults to full width
  width?: number | string;
  // Whether to show navigation arrow buttons
  // Defaults to false
  showNavigationButtons?: boolean;
}

const imageStyle: React.CSSProperties = {
  objectFit: 'contain',
  height: '100%',
  width: '100%',
};

/**
 * The images for each carousel item don't span the full height and are wrapped so that there is
 * some white space around it, given it's expected to be partially covered by other components when
 * the carousel is displayed in desktop mode.
 */
const ImageCarouselItem = (props: { src: string; alt: string; testId?: string }) => {
  return (
    <div data-testid={props.testId} className={'image-carousel-item'}>
      <div className={'image-carousel-item__image-wrapper'}>
        <img src={props.src} alt={props.alt} style={imageStyle} />
      </div>
    </div>
  );
};

/**
 * This component uses the Carousel component from react-material-ui-carousel, which is built
 * using the MUI component library.
 * https://github.com/Learus/react-material-ui-carousel/blob/master/README.md
 */
const ImageCarousel = (props: ImageCarouselProps) => {
  const elementName = ElementName.IMAGE_CAROUSEL;
  const { uiContentView, uiClick } = useUiMetrics();

  uiContentView({
    elementName: elementName,
    entityId: props.asin,
    entityIdType: 'ASIN',
    entityType: 'MERCH',
  });

  return (
    <div
      style={{ width: props.width ?? '100%', height: props.height }}
      className={'image-carousel'}
      data-testid={formatTestId(TestIds.ImageCarousel)}
    >
      <Swiper
        navigation={props.showNavigationButtons}
        onProgress={() => {
          uiClick({
            elementName,
            actionType: ActionName.VIEW_IMAGES,
            entityId: props.asin,
            entityIdType: 'ASIN',
            entityType: 'MERCH',
          });
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        modules={[Pagination, Navigation]}
        className={'image-carousel-slider'}
      >
        {props.images.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{ height: '100%' }}
            data-testid={formatTestId(TestIds.ImageCarousel, 'Swiper')}
          >
            <ImageCarouselItem
              src={image}
              alt={`image carousel item ${index}`}
              testId={formatTestId(TestIds.ImageCarousel, 'Item', index.toString())}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageCarousel;
