import React from 'react';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

const outerContainerBaseStyle: React.CSSProperties = {
  borderRadius: BauhausBorder.radius.XL2,
  borderWidth: BauhausBorder.width.MD,
  borderStyle: 'solid',
  color: BauhausColor.primary.solid,
  cursor: 'pointer',
  height: 32,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
};

export const innerContainerBaseStyle: React.CSSProperties = {
  borderRadius: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingBottom: 6,
  paddingRight: BauhausSpacing.mini,
  paddingLeft: BauhausSpacing.mini,
};

const defaultStyle = {
  backgroundColor: BauhausColor.primary.glass2,
  borderColor: BauhausColor.transparent,
};

const selectedStyle = {
  backgroundColor: BauhausColor.primary.glass3,
  borderColor: BauhausColor.secondary.solid,
  outline: '2px solid white',
};

const grayOutStyle = {
  backgroundColor: BauhausColor.primary.glass1,
  borderColor: BauhausColor.transparent,
  color: BauhausColor.primary.glass2,
};

export const getDefaultButtonStyle = {
  ...outerContainerBaseStyle,
  ...defaultStyle,
};

export const getSelectedButtonStyle = {
  ...outerContainerBaseStyle,
  ...selectedStyle,
};

export const getGrayOutButtonStyle = {
  ...outerContainerBaseStyle,
  ...grayOutStyle,
};

export const buttonGroupStyle: React.CSSProperties = {
  display: 'flex',
  gap: BauhausSpacing.small,
  flexWrap: 'wrap',
};
