import TrophyIcon from 'src/assets/icons/TrophyIcon';
import AmazonMusicLogo from 'src/assets/logos/AmazonMusicLogo';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import {
  artistCelebrationBoxStyle,
  artistLogoContainerStyle,
  celebrationTitleContainerStyle,
} from 'src/components/ArtistCelebrationBox/style';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import ImageThumbnail, { ImageThumbnailPreset } from 'src/components/ImageThumbnail/ImageThumbnail';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ElementName } from 'src/metrics/constants';
import { Artist } from 'src/models/artist';

export interface ArtistCelebrationBoxProps {
  artist: Artist;
  title: string;
  subtitle?: string;
  cta?: string;
  onClick?: () => void;
}

const ArtistCelebrationBox = ({
  artist,
  title,
  subtitle,
  cta,
  onClick,
}: ArtistCelebrationBoxProps) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({
    elementName: ElementName.ARTIST_CELEBRATION_BOX,
  });

  return (
    <div style={artistCelebrationBoxStyle} data-testid={formatTestId(TestIds.ArtistCelebrationBox)}>
      <div style={celebrationTitleContainerStyle}>
        <TrophyIcon />
        <Spacer size='small' />
        <Text
          text={title}
          preset={TextPreset.HEADLINE_4}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.ArtistCelebrationBox, TestIds.TitleText)}
        />
      </div>
      <Spacer size='base' />
      <ImageThumbnail
        src={artist.images.portraitUrl}
        preset={ImageThumbnailPreset.CIRCLE}
        size={230}
        alt='artist image thumbnail'
        testId={formatTestId(TestIds.ArtistCelebrationBox, 'ArtistPortraitImage')}
      />
      <Spacer size='base' />
      <div
        style={artistLogoContainerStyle}
        data-testid={formatTestId(TestIds.ArtistCelebrationBox, 'ArtistLogoImage')}
      >
        <img
          src={artist.images.logoUrl}
          alt={'artist logo'}
          height={'100%'}
          width={'100%'}
          style={{ objectFit: 'contain' }}
        />
      </div>
      {subtitle && (
        <>
          <Spacer size='micro' />
          <Text
            text={subtitle}
            textAlign='center'
            preset={TextPreset.HEADLINE_4}
            color={BauhausColor.primary.solid}
            testId={formatTestId(TestIds.ArtistCelebrationBox, TestIds.SubtitleText)}
          />
        </>
      )}
      {cta && onClick && (
        <>
          <Spacer size='huge' />
          <Button
            text={cta}
            preset={ButtonPreset.SOLID}
            horizontalPadding={BauhausSpacing.large}
            onClick={onClick}
            testId={formatTestId(TestIds.ArtistCelebrationBox, TestIds.CtaButton)}
          />
        </>
      )}
      <Spacer size='huge' />
      <AmazonMusicLogo />
    </div>
  );
};

export default ArtistCelebrationBox;
