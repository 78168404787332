import React from 'react';
import { BauhausBlur } from 'src/bauhaus/blur';
import { BauhausBorder } from 'src/bauhaus/border';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const languageSelectorSingleValueImageStyle: React.CSSProperties = {
  width: BauhausSpacing.large,
  height: BauhausSpacing.large,
  marginRight: BauhausSpacing.mini,
};

export const languageSelectorOptionStyle = (isLastPosition?: boolean): React.CSSProperties => {
  return {
    borderBottom: isLastPosition ? 'none' : '1px solid rgba(255, 255, 255, 0.15)',
    color: BauhausColor.primary.solid,
    padding: BauhausSpacing.medium,
    display: 'flex',
    justifyContent: 'space-between',
  };
};

export const languageSelectorOptionImageStyle = (isSelected: boolean): React.CSSProperties => ({
  width: BauhausSpacing.medium,
  height: BauhausSpacing.medium,
  marginLeft: BauhausSpacing.mini,
  display: isSelected ? 'inline' : 'none',
});

export const languageSelectorMenuStyle: React.CSSProperties = {
  position: 'relative',
  backgroundColor: BauhausColor.secondary.glass5,
  borderWidth: BauhausBorder.width.SM,
  borderColor: BauhausColor.primary.glass2,
  backdropFilter: `blur(${BauhausBlur.blur1}px)`,
  WebkitBackdropFilter: `blur(${BauhausBlur.blur1}px)`,
  borderRadius: BauhausSpacing.mini,
  // TODO: Adjust menu width to be full width in mobile view and 404px in desktop view
  // https://jira.music.amazon.dev/browse/ABW-810
  width: 250,
  zIndex: 1,
};
