const borderWidth = {
  SM: 1,
  MD: 2,
};

const borderRadius = {
  XS: 2,
  SM: 4,
  MD: 8,
  LG: 16,
  XL: 20,
  XL2: 24,
};

export const BauhausBorder = {
  width: borderWidth,
  radius: borderRadius,
};
