import { SVGProps } from 'react';

const PlayIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M21.5496 11.2078L7.32713 2.13064C7.05157 1.95464 6.71557 1.95664 6.44179 2.13564C6.168 2.31464 6 2.64365 6 2.99966V21C6 21.354 6.16711 21.683 6.43912 21.862C6.57779 21.954 6.73335 22 6.8889 22C7.03824 22 7.18669 21.958 7.3218 21.873L21.5443 12.9508C21.8243 12.7748 21.9994 12.4418 22.0003 12.0808C22.0012 11.7198 21.8296 11.3858 21.5496 11.2078Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default PlayIcon;
