import React, { Ref } from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import LegalFooter from 'src/components/LegalFooter/LegalFooter';
import OrderConfirmationBox from 'src/components/OrderConfirmationBox/OrderConfirmationBox';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName, EntityIdType, EntityType } from 'src/metrics/constants';
import { Order, OrderChargeType } from 'src/models/order';
import { Product } from 'src/models/product';
import { formatOrderChargeAmount } from 'src/models/utils';
import {
  $sheetContainerStyle,
  $sheetHeaderStyle,
} from 'src/pages/ProductDetailPage/ActionSheet/style';

export interface OrderConfirmationSheetProps {
  product: Product;
  order: Order;
  onClickCtaButton: () => void;
  ref?: Ref<HTMLDivElement>;
}

const OrderConfirmationSheet = ({
  product,
  order,
  onClickCtaButton,
  ref,
}: OrderConfirmationSheetProps) => {
  // We only allow one item per order
  const orderItem = order.items[0];

  const { uiContentView } = useUiMetrics();
  uiContentView({
    elementName: ElementName.ACTION_SHEET_CONFIRM_ORDER,
    entityId: orderItem.asin,
    entityIdType: 'ASIN' as EntityIdType,
    entityType: 'MERCH' as EntityType,
  });

  const bundle = useStringsBundle();

  return (
    <div
      style={$sheetContainerStyle}
      data-testid={formatTestId(TestIds.OrderConfirmationSheet)}
      ref={ref}
    >
      <div style={$sheetHeaderStyle}>
        <Text
          text={bundle.getMessage(StringIds.OrderConfirmationComplete)}
          preset={TextPreset.HEADLINE_3}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderConfirmationSheet, TestIds.TitleText)}
        />
      </div>
      <Spacer size='large' />
      <OrderConfirmationBox
        id={order.orderId}
        image={product.productImages.images[0]?.url}
        totalCharge={formatOrderChargeAmount(OrderChargeType.PURCHASE_TOTAL, order.charges)}
        items={order.items}
        onClickButton={onClickCtaButton}
      />
      <Spacer size='small' />
      <Button
        preset={ButtonPreset.SOLID}
        size={'LG'}
        text={bundle.getMessage(StringIds.CtaViewOnAmazon)}
        onClick={onClickCtaButton}
        icon={'visitExternal'}
        iconTrailing
        testId={formatTestId(TestIds.OrderConfirmationSheet, TestIds.ViewOrderButton)}
        metrics={{
          elementName: ElementName.VIEW_ON_AMAZON_BUTTON,
          actionName: ActionName.GO_AMZN_ORDER,
          entityId: orderItem.asin,
          entityIdType: 'ASIN',
          entityType: 'MERCH',
          responseId: order.orderId,
          responseIdType: 'ORDER_ID',
        }}
      />
      <LegalFooter />
    </div>
  );
};

export default OrderConfirmationSheet;
