export const BauhausIcons = {
  cancel: require('../assets/icons/Cancelcon').default,
  caretDown: require('../assets/icons/CaretDownIcon').default,
  caretLeft: require('../assets/icons/CaretLeftIcon').default,
  caretRight: require('../assets/icons/CaretRightIcon').default,
  caretUp: require('../assets/icons/CaretUpIcon').default,
  goBack: require('../assets/icons/GoBack').default,
  help: require('../assets/icons/HelpIcon').default,
  jumpBack15: require('../assets/icons/JumpBack15Icon').default,
  jumpForward15: require('../assets/icons/JumpForward15Icon').default,
  maximize: require('../assets/icons/MaximizeIcon').default,
  minimize: require('../assets/icons/MinimizeIcon').default,
  pause: require('../assets/icons/PauseIcon').default,
  play: require('../assets/icons/PlayIcon').default,
  visitExternal: require('../assets/icons/VisitExternalIcon').default,
};

export type BauhausIconType = keyof typeof BauhausIcons;
