import React from 'react';

export const outContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

export const orderIdChargeContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
};
