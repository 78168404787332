import {
  ButtonSize,
  getButtonIconSizeType,
  getButtonPaddingSize,
  getButtonSpacingSize,
  getButtonStyle,
  getButtonTextPreset,
  getIconMarginSize,
  getTextMarginSize,
} from 'src/components/Button/style';
import Icon, { IconType } from 'src/components/Icon/Icon';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { ActionName, ElementName, EntityIdType, EntityType } from 'src/metrics/constants';

export enum ButtonPreset {
  SOLID = 'solid',
  OUTLINED = 'outlined',
  GLASS = 'glass',
  TRANSPARENT = 'transparent',
}

export type ButtonSizeType = keyof typeof ButtonSize;

export interface ButtonProps {
  /**
   * Button text
   */
  text: string;

  textColor?: string;

  /**
   * Function triggered upon button click
   */
  onClick: () => void;

  /**
   * Optional icon before or after the text
   */
  icon?: IconType;

  /**
   * @default false
   * If false, displays the icon BEFORE the text
   * If true, displays the icon AFTER the text
   */
  iconTrailing?: boolean;

  /**
   * Button look-and-feel
   */
  preset?: ButtonPreset;

  /**
   * Button size
   */
  size?: ButtonSizeType;

  horizontalPadding?: number;

  /**
   * @default false
   * Whether to make the button disabled
   */
  disabled?: boolean;

  /**
   * @default false
   * Whether to make the button span the screen
   */
  fullWidth?: boolean;

  testId?: string;

  metrics?: {
    elementName: ElementName;
    actionName: ActionName;
    entityId?: string;
    entityIdType?: EntityIdType;
    entityType?: EntityType;
    responseId?: string;
    responseIdType?: EntityIdType;
  };
}

const Button = (props: ButtonProps) => {
  const elementName = props.metrics?.elementName ?? ElementName.CTA_BUTTON;
  const { uiClick, uiContentView } = useUiMetrics();
  uiContentView({ elementName });

  const buttonPreset = props.preset ?? ButtonPreset.SOLID;
  const buttonSizeType = props.size ?? 'MD';
  const buttonSize = ButtonSize[buttonSizeType];

  const buttonPaddingSize = props.horizontalPadding ?? getButtonPaddingSize(buttonSize);
  const buttonSpacingSize = getButtonSpacingSize(buttonSize);

  const iconSize = getButtonIconSizeType(buttonSize);
  const iconMarginSize = getIconMarginSize(buttonSize);

  const textPreset = getButtonTextPreset(buttonSize);
  const textMarginSize = getTextMarginSize(buttonSize);

  const testId = props.testId ?? TestIds.Button;
  return (
    <button
      style={getButtonStyle(
        buttonPreset,
        buttonSize,
        buttonPaddingSize,
        props.fullWidth,
        props.disabled,
      )}
      disabled={props.disabled}
      onClick={() => {
        props.onClick();
        uiClick({
          actionType: props.metrics?.actionName ?? ActionName.NONE,
          elementName,
          entityId: props.metrics?.entityId,
          entityIdType: props.metrics?.entityIdType,
          entityType: props.metrics?.entityType,
          responseId: props.metrics?.responseId,
          responseIdType: props.metrics?.responseIdType,
        });
      }}
      data-testid={formatTestId(testId)}
    >
      {props.icon && !props.iconTrailing && iconSize && buttonSpacingSize && (
        <Icon
          icon={props.icon}
          size={iconSize}
          marginLeft={iconMarginSize}
          testId={formatTestId(testId, TestIds.Icon)}
        />
      )}
      <Text
        text={props.text}
        color={props.textColor}
        preset={textPreset}
        marginLeft={textMarginSize}
        marginRight={textMarginSize}
      />
      {props.icon && props.iconTrailing && iconSize && buttonSpacingSize && (
        <Icon
          icon={props.icon}
          size={iconSize}
          marginRight={iconMarginSize}
          testId={formatTestId(testId, TestIds.Icon)}
        />
      )}
    </button>
  );
};

export default Button;
