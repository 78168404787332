import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import {
  UiClickProps,
  UiContentViewProps,
  UiMetricsContext,
} from 'src/components/UiMetricsWrapper/useUiMetrics';
import { CirrusLibraryOption, CirrusLibraryService } from 'src/metrics/CirrusLibraryService';
import { DeviceType, EntityIdType, EntityType, PageName } from 'src/metrics/constants';
import { getUserDeviceId, getUserSessionId } from 'src/navigation/auth';

export const MusicTerritory = 'US';

export interface UiMetricsWrapperProps {
  isLoading: boolean;
  pageName: PageName;
  pageSubName?: string;
  campaignId: string;
  refMarker?: string;
}

export const UiMetricsWrapper = (props: PropsWithChildren<UiMetricsWrapperProps>) => {
  const [pageLoadStart, setPageLoadStart] = useState<number | undefined>();
  const [pageLoadEnd, setPageLoadEnd] = useState<number | undefined>();

  useEffect(() => {
    if (props.isLoading) {
      setPageLoadStart(Date.now());
      setPageLoadEnd(undefined);
    } else {
      setPageLoadEnd(Date.now());
    }
  }, [props.isLoading]);

  const options = (): CirrusLibraryOption => {
    return {
      deviceId: getUserDeviceId(),
      deviceType: DeviceType,
      musicTerritory: MusicTerritory,
    };
  };

  const commonProps = () => {
    return {
      csSessionId: getUserSessionId(),
      pageEntityId: props.campaignId,
      pageEntityIdType: 'CAMPAIGN_ID' as EntityIdType,
      pageEntityType: 'CAMPAIGN' as EntityType,
      pageSubType: props.pageSubName,
      pageType: props.pageName,
    };
  };

  useEffect(() => {
    if (!pageLoadEnd) {
      return;
    }
    CirrusLibraryService.emitUiPageView(
      {
        ...commonProps(),
        loadTimeMilliseconds: (pageLoadStart ? pageLoadEnd - pageLoadStart : 0).toString(),
        refMarker: props.refMarker,
      },
      options(),
    );
  }, [props.pageName, props.pageSubName, props.campaignId, pageLoadEnd]);

  const uiClick = useCallback(
    (payload: UiClickProps): void => {
      CirrusLibraryService.emitUiClick(
        {
          ...payload,
          ...commonProps(),
          interactionType: 'CLICK',
        },
        {
          contentName: payload.elementName,
        },
        options(),
      );
    },
    [props.pageName, props.pageSubName, props.campaignId],
  );

  const uiContentView = (payload: UiContentViewProps): void => {
    useMemo(() => {
      CirrusLibraryService.emitUiContentView(
        {
          ...payload,
          ...commonProps(),
        },
        {
          contentName: payload.elementName,
        },
        options(),
      );
    }, [props.pageName, props.pageSubName, props.campaignId]);
  };

  const metricsProvider = useMemo(() => {
    return (
      <UiMetricsContext.Provider value={{ uiClick, uiContentView }}>
        {props.children}
      </UiMetricsContext.Provider>
    );
  }, [uiClick, uiContentView, props.children]);

  return metricsProvider;
};
