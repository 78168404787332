import { SVGProps } from 'react';

const MaximizeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M19.9762 14.3471C20.0332 14.5261 19.9862 14.7211 19.8532 14.8531L18.0602 16.6461L21.7082 20.2942C22.0973 20.6832 22.0973 21.3192 21.7082 21.7082C21.3192 22.0973 20.6832 22.0973 20.2942 21.7082L16.6461 18.0602L14.8531 19.8532C14.7211 19.9852 14.5251 20.0332 14.3471 19.9762C14.1691 19.9192 14.0371 19.7672 14.0071 19.5822L13.007 13.582C12.982 13.424 13.032 13.261 13.147 13.146C13.261 13.032 13.424 12.981 13.583 13.006L19.5832 14.0061C19.7672 14.0371 19.9182 14.1691 19.9762 14.3471ZM9.65294 4.0238C9.47394 3.9668 9.27893 4.0138 9.14693 4.1468L7.35388 5.93885L3.70579 2.29176C3.31678 1.90275 2.68077 1.90275 2.29176 2.29176C1.90275 2.68077 1.90275 3.31678 2.29176 3.70579L5.93985 7.35388L4.1458 9.14593C4.0138 9.27793 3.9658 9.47394 4.0228 9.65194C4.0798 9.82995 4.23181 9.96195 4.41681 9.99195L10.417 10.992C10.575 11.017 10.738 10.967 10.853 10.852C10.967 10.738 11.018 10.575 10.993 10.416L9.99295 4.41581C9.96195 4.23281 9.83095 4.0808 9.65294 4.0238Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default MaximizeIcon;
