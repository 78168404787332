import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing, BauhausSpacingType } from 'src/bauhaus/spacing';

export interface SpacerProps {
  size?: BauhausSpacingType;
  testId?: string;
}

export const Spacer = (props: SpacerProps) => {
  const size: BauhausSpacingType = props.size ?? 'base';

  const $viewStyle: React.CSSProperties = {
    backgroundColor: BauhausColor.transparent,
    height: BauhausSpacing[size],
    width: BauhausSpacing[size],
  };

  return <div data-testid={props.testId} style={$viewStyle} />;
};
