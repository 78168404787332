import { SVGProps } from 'react';

const HelpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='current'>
      <g clipPath='url(#clip0_1269_1988)'>
        <path
          d='M12 0C5.37273 0 0 5.37273 0 12C0 18.6273 5.37273 24 12 24C18.6273 24 24 18.6273 24 12C24 5.37273 18.6273 0 12 0ZM12.6971 19.1084C12.3447 19.4596 11.8865 19.6364 11.3236 19.6364C10.7607 19.6364 10.3025 19.4596 9.95127 19.1084C9.6 18.7571 9.42327 18.2989 9.42327 17.736C9.42327 17.1731 9.6 16.7149 9.95127 16.3636C10.3025 16.0124 10.7607 15.8356 11.3236 15.8356C11.8865 15.8356 12.3447 16.0124 12.696 16.3636C13.0473 16.7149 13.224 17.1731 13.224 17.736C13.224 18.2989 13.0484 18.756 12.6971 19.1084ZM15.2324 10.6167C14.6695 11.3356 13.7531 12.0262 12.4865 12.6873V13.9549C12.4865 14.1382 12.4473 14.2713 12.3698 14.3564C12.2924 14.4415 12.1549 14.4829 11.9585 14.4829H10.6058C10.4073 14.4829 10.2676 14.448 10.1825 14.3782C10.0975 14.3084 10.0484 14.1807 10.0353 13.9975L9.90873 12.5607C9.89455 12.4353 9.88364 12.3185 9.87709 12.2127C9.86945 12.1069 9.86727 12.0393 9.86727 12.012C9.86727 11.8571 9.89891 11.7349 9.96218 11.6433C10.0265 11.5516 10.1345 11.4633 10.2905 11.3793L10.9244 11.0411C11.6989 10.6331 12.252 10.2273 12.5825 9.82582C12.9131 9.42436 13.0789 8.94873 13.0789 8.4C13.0789 7.89273 12.9196 7.50109 12.6033 7.22727C12.2869 6.95345 11.8331 6.816 11.2407 6.816C10.4935 6.816 9.70582 6.98509 8.87455 7.32218C8.60618 7.43455 8.40873 7.49018 8.28218 7.49018C8.04327 7.49018 7.92327 7.32873 7.92327 7.00473V5.84291C7.92327 5.63127 7.95164 5.47964 8.00836 5.388C8.064 5.29745 8.19055 5.20909 8.388 5.124C9.47236 4.61782 10.6135 4.36364 11.8102 4.36364C13.1476 4.36364 14.1938 4.69418 14.9465 5.35745C15.7004 6.01964 16.0767 6.92727 16.0767 8.08255C16.0767 9.05346 15.7953 9.89891 15.2324 10.6167Z'
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_1269_1988'>
          <rect width='24' height='24' fill={props.fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpIcon;
