export const DeviceType = 'A3PYDLKVPM2KV3';
export const AppVersion = '1.0';

export type EntityIdType = 'ASIN' | 'CAMPAIGN_ID' | 'ORDER_ID';
export type EntityType = 'CAMPAIGN' | 'MERCH';

export enum MetricName {
  CLICK = 'uiClick',
  PAGE_VIEW = 'uiPageView',
  CONTENT_VIEW = 'uiContentView',
}

export enum ActionName {
  CLOSE_MODAL = 'CloseModal',
  GO_AMZN_MUSIC = 'GoAmazonMusic',
  GO_AMZN_ACCOUNT = 'GoAmazonAccount',
  GO_AMZN_ORDER = 'GoAmazonOrder',
  GO_AMZN_HELP = 'GoAmazonHelp',
  GO_BACK = 'GoBack',
  GO_DOWNLOAD_AMAZON_MUSIC_APP = 'GoDownloadAmazonMusicApp',
  GO_ORDER_CHECKOUT = 'GoOrderCheckout',
  GO_PLAY_VIDEO = 'GoPlayVideo',
  GO_PRIMARY = 'GoPrimary',
  GO_PRODUCT_SELECTION = 'GoProductSelection',
  GO_SIGN_IN = 'GoSignIn',
  OPEN_HINT = 'OpenHint',
  OPEN_LANGUAGE_SELECTOR = 'OpenLanguageSelector',
  OPEN_MENU = 'OpenMenu',
  PLACE_ORDER = 'PlaceOrder',
  SELECT_LANGUAGE = 'SelectLanguage',
  SELECT_PRODUCT_QUANTITY = 'SelectProductQuantity',
  SELECT_PRODUCT_VARIATION = 'SelectProductVariation',
  VIEW_IMAGES = 'ViewImages',
  NONE = 'None',
}

export enum ElementName {
  ACTION_SHEET_VERIFY_ELIGIBILITY = 'ActionSheet_VerifyEligibility',
  ACTION_SHEET_SELECT_PRODUCT = 'ActionSheet_SelectProduct',
  ACTION_SHEET_CHECKOUT = 'ActionSheet_Checkout',
  ACTION_SHEET_CONFIRM_ORDER = 'ActionSheet_ConfirmOrder',
  ARTIST_CELEBRATION_BOX = 'ArtistCelebrationBox',
  ARTIST_TOP_BAR = 'ArtistTopBar',
  BACKGROUND_IMAGE = 'BackgroundImage',
  BACK_BUTTON = 'BackButton',
  BUTTON = 'Button',
  BUY_NOW_BUTTON = 'BuyNowButton',
  CTA_BUTTON = 'CtaButton',
  CANCEL_BUTTON = 'CancelButton',
  CHANGE_CHECKOUT_INFO_BUTTON = 'ChangeCheckoutInfoButton',
  CHECKOUT_BUTTON = 'CheckoutButton',
  CHECKOUT_MORE_ON_AM_BUTTON = 'CheckoutMoreOnAmazonMusicButton',
  CLOSE_BUTTON = 'CloseButton',
  DESCRIPTION_BOX = 'DescriptionBox',
  FLOATING_MESSAGE = 'FloatingMessage',
  HINT_BOX = 'HintBox',
  HELP_ICON = 'HelpIcon',
  ICON = 'Icon',
  ICON_BUTTON = 'IconButton',
  IMAGE_CAROUSEL = 'ImageCarousel',
  LANGUAGE_SELECTOR = 'LanguageSelector',
  LISTEN_ON_AM_BUTTON = 'ListenOnAmazonMusicButton',
  MENU = 'Menu',
  ORDER_CONFIRMATION_BOX = 'OrderConfirmationBox',
  ORDER_SUMMARY_BOX = 'OrderSummaryBox',
  PLACE_ORDER_BUTTON = 'PlaceOrderButton',
  PREORDER_NOW_BUTTON = 'PreorderNowButton',
  SELECTION_BOX = 'SelectionBox',
  SIGN_IN_BUTTON = 'SignInButton',
  SOLD_OUT_BUTTON = 'SoldOutButton',
  VIDEO_PLAYER = 'VideoPlayer',
  VIEW_ON_AMAZON_BUTTON = 'ViewOnAmazonButton',
}

export enum PageName {
  CELEBRATION_MODAL = 'CelebrationModal',
  ELIGIBILITY_INFO_MODAL = 'EligibilityInfoModal',
  ERROR_MODAL = 'ErrorModal',
  EXPIRED_CAMPAIGN_PAGE = 'ExpiredCampaign',
  INFO_PAGE = 'Info',
  MODAL = 'Modal',
  PRODUCT_DETAIL_PAGE = 'ProductDetail',
  VIDEO_PAGE = 'Video',
}
