import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';

export const selectionQuantityContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  gap: BauhausSpacing.small,
};

export const quantitySelectorStyle: React.CSSProperties = {
  background: BauhausColor.primary.glass2,
  minHeight: BauhausSpacing.giant,
  maxHeight: BauhausSpacing.giant,
  borderRadius: BauhausSpacing.base,
  borderColor: BauhausColor.transparent,
  boxShadow: 'none',
  width: 80,
  height: 250,
  color: BauhausColor.primary.solid,
  outlineColor: BauhausColor.primary.solid,
  marginRight: BauhausSpacing.medium,
};

export const quantitySelectorOptionStyle: React.CSSProperties = {};

export const quantitySelectorMenuStyle: React.CSSProperties = {
  backgroundColor: BauhausColor.primary.glass5,
  height: 260,
  width: 80,
  overflow: 'scroll',
};

export const quantitySelectorFormControlStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
