import { SVGProps } from 'react';

const JumpForward15Icon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M19.8505 2.92909C20.0113 2.97727 20.1322 3.11 20.1649 3.27455L21.0745 7.82C21.1045 7.96909 21.0572 8.12364 20.95 8.23091C20.8637 8.31636 20.7473 8.36364 20.6283 8.36364C20.5992 8.36364 20.5692 8.36091 20.5402 8.35455L15.9967 7.44545C15.8313 7.41273 15.6995 7.29182 15.6505 7.13182C15.6014 6.97091 15.645 6.79636 15.764 6.67818L16.9454 5.49636C15.5405 4.42 13.824 3.81818 11.9957 3.81818C7.48672 3.81818 3.8174 7.48909 3.8174 12C3.8174 16.5118 7.48672 20.1818 11.9957 20.1818C14.5918 20.1818 16.9762 18.9891 18.5401 16.9091C18.8427 16.5073 19.4125 16.4273 19.8123 16.7282C20.213 17.03 20.2939 17.6 19.9931 18.0009C18.083 20.5418 15.1688 22 11.9957 22C6.48442 22 2 17.5136 2 12C2 6.48636 6.48442 2 11.9957 2C14.3156 2 16.4919 2.78636 18.243 4.19818L19.3979 3.04273C19.5161 2.92364 19.6933 2.88 19.8505 2.92909Z'
        fill={props.fill}
      />
      <path
        d='M9.6245 14.7273H10.426C10.5623 14.7273 10.6304 14.6591 10.6304 14.5227V9.26181C10.6304 9.12545 10.5623 9.05727 10.426 9.05727H9.74717C9.67629 9.05727 9.61359 9.06272 9.55907 9.07363C9.51 9.08454 9.45003 9.10636 9.37915 9.13908L8.14423 9.70363C8.07335 9.73636 8.02428 9.77181 7.99702 9.80999C7.97521 9.84818 7.9643 9.90545 7.9643 9.98181V10.5218C7.9643 10.6254 8.00247 10.6773 8.0788 10.6773C8.11697 10.6773 8.19057 10.6582 8.29961 10.62L9.42004 10.2518V14.5227C9.42004 14.6591 9.48819 14.7273 9.6245 14.7273Z'
        fill={props.fill}
      />
      <path
        d='M12.6418 14.58C13.1107 14.76 13.6095 14.85 14.1384 14.85C14.5746 14.85 14.9562 14.7736 15.2834 14.6209C15.6105 14.4627 15.864 14.2418 16.0439 13.9582C16.2293 13.6745 16.322 13.3473 16.322 12.9764C16.322 12.6273 16.2457 12.3245 16.093 12.0682C15.9403 11.8064 15.725 11.6045 15.4469 11.4627C15.1689 11.3209 14.8417 11.25 14.4655 11.25C14.351 11.25 14.2338 11.2582 14.1139 11.2745C13.9994 11.2854 13.8821 11.3045 13.7622 11.3318L13.8522 9.98999H15.8558C15.9921 9.98999 16.0603 9.92181 16.0603 9.78545V9.26181C16.0603 9.12545 15.9921 9.05727 15.8558 9.05727H13.0507C12.9144 9.05727 12.8408 9.1309 12.8299 9.27818L12.699 11.4873V12.0027C12.699 12.1391 12.7672 12.2073 12.9035 12.2073C12.9362 12.2073 12.9771 12.2045 13.0261 12.1991C13.0807 12.1882 13.157 12.18 13.2551 12.1745C13.3587 12.1636 13.4814 12.1554 13.6232 12.15C13.7649 12.1445 13.904 12.1418 14.0403 12.1418C14.411 12.1418 14.6809 12.2127 14.8499 12.3545C15.0244 12.4909 15.1116 12.7064 15.1116 13.0009C15.1116 13.6009 14.7681 13.9009 14.0811 13.9009C13.874 13.9009 13.6668 13.8818 13.4596 13.8436C13.2579 13.8054 13.0207 13.7482 12.7481 13.6718C12.6663 13.65 12.6172 13.6391 12.6009 13.6391C12.5082 13.6391 12.4618 13.7018 12.4618 13.8273V14.3345C12.4618 14.4054 12.4728 14.4573 12.4946 14.49C12.5218 14.5227 12.5709 14.5527 12.6418 14.58Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default JumpForward15Icon;
