import { ActionName, EntityIdType, EntityType, PageName } from 'src/metrics/constants';

// https://console.harmony.a2z.com/music-metrics/docs/event/uiClick
export interface UiClick {
  actionDetail?: string;
  activityId?: string;
  activityIdType?: string;
  castingProtocol?: string;
  containerIndex?: string;
  contentInfo?: string;
  contentName?: string;
  csSessionId?: string;
  customSessionId?: string;
  customSessionType?: string;
  durationRemainingSeconds?: string;
  itemId?: string;
  itemPos?: string;
  itemText?: string;
  pageSubType?: string;
  refMarker?: string;
  referer?: string;
  responseId?: string;
  responseIdType?: string;
  searchQuery?: string;
  trackCount?: string;
  url?: string;
  userAlias?: string;
  interactionType: string;
  pageType: PageName;
  entityPos?: string;
  pageEntityId?: string;
  pageEntityIdType?: EntityIdType;
  pageEntityType?: EntityType;
  actionType?: ActionName;
  entityId?: string;
  entityIdType?: EntityIdType;
  entityType?: EntityType;
  blockRef?: string;
  contentInfoInstId?: string;
  contentSrc?: string;
}

export const UiClickVersion = '19';
