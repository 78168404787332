import React from 'react';
import { $legalFooterStyle } from 'src/components/LegalFooter/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { AMAZON_CONDITIONS_OF_USE, AMAZON_PRIVACY_NOTICE } from 'src/navigation/sites';

export interface LegalFooterProps {
  // Element immediately before the Privacy Policy and Conditions of Use
  prefix?: React.ReactNode;
  // Element immediately after the Privacy Policy and Conditions of Use
  suffix?: React.ReactNode;
}

const LegalFooter = ({ prefix, suffix }: LegalFooterProps) => {
  const bundle = useStringsBundle();

  return (
    <div style={$legalFooterStyle} data-testid={formatTestId(TestIds.LegalFooter)}>
      {prefix && <div data-testid={formatTestId(TestIds.LegalFooter, 'PrefixText')}>{prefix} </div>}
      <a
        href={AMAZON_PRIVACY_NOTICE}
        rel='noreferrer'
        target='_blank'
        data-testid={formatTestId(TestIds.LegalFooter, 'PrivacyPolicyLink')}
      >
        {bundle.getMessage(StringIds.OrderTermsPrivacyNotice)}
      </a>
      <span> {bundle.getMessage(StringIds.OrderTermsAnd)} </span>
      <a
        href={AMAZON_CONDITIONS_OF_USE}
        rel='noreferrer'
        target='_blank'
        data-testid={formatTestId(TestIds.LegalFooter, 'ConditionsOfUseLink')}
      >
        {bundle.getMessage(StringIds.OrderTermsConditionsOfUse)}
      </a>
      {suffix && <div data-testid={formatTestId(TestIds.LegalFooter, 'SuffixText')}> {suffix}</div>}
    </div>
  );
};

export default LegalFooter;
