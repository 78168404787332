import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import { formatTestId, PageTestIds, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { UiMetricsWrapper } from 'src/components/UiMetricsWrapper/UiMetricsWrapper';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName, PageName } from 'src/metrics/constants';
import { goBack, goGetAmazonMusicApp } from 'src/navigation/sites';
import {
  $pageContentButtonGroupStyle,
  $pageContentStyle,
  $pageContentTextStyle,
  $pageStyle,
} from 'src/pages/InfoPage/style';

const InfoPage = () => {
  const bundle = useStringsBundle();

  return (
    <UiMetricsWrapper
      pageName={PageName.INFO_PAGE}
      pageSubName={''}
      campaignId={''}
      isLoading={false}
    >
      <div style={$pageStyle} data-testid={PageTestIds.InfoPage}>
        <div style={$pageContentStyle}>
          <div style={$pageContentTextStyle}>
            <Text
              text={bundle.getMessage(StringIds.InfoTitle)}
              preset={TextPreset.HEADLINE_3}
              color={BauhausColor.primary.solid}
              testId={formatTestId(TestIds.TitleText)}
            />
            <Text
              text={bundle.getMessage(StringIds.InfoSubtitle)}
              preset={TextPreset.HEADLINE_4}
              color={BauhausColor.primary.solid}
              testId={formatTestId(TestIds.SubtitleText)}
            />
            <Text
              text={bundle.getMessage(StringIds.InfoText)}
              preset={TextPreset.BODY_1}
              color={BauhausColor.primary.solid}
              testId={formatTestId(TestIds.BodyText)}
            />
          </div>
          <div style={$pageContentButtonGroupStyle}>
            <Button
              text={bundle.getMessage(StringIds.CtaDownloadAmazonMusicApp)}
              preset={ButtonPreset.SOLID}
              fullWidth
              onClick={() => goGetAmazonMusicApp('_blank')}
              testId={formatTestId(TestIds.CtaButton)}
              metrics={{
                elementName: ElementName.CTA_BUTTON,
                actionName: ActionName.GO_DOWNLOAD_AMAZON_MUSIC_APP,
              }}
            />
            <Button
              text={bundle.getMessage(StringIds.CtaGoBack)}
              preset={ButtonPreset.TRANSPARENT}
              fullWidth
              onClick={() => goBack()}
              testId={formatTestId(TestIds.BackButton)}
              metrics={{
                elementName: ElementName.BACK_BUTTON,
                actionName: ActionName.GO_BACK,
              }}
            />
          </div>
        </div>
      </div>
    </UiMetricsWrapper>
  );
};

export default InfoPage;
