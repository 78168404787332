import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { IconSizeType } from 'src/components/Icon/Icon';
import { IconButtonSizeType } from 'src/components/IconButton/IconButton';

export const IconButtonSize = {
  XL: 80,
  LG: 64,
  MD: 48,
  SM: 40,
  XS: 32,
};

const $baseButtonStyle: React.CSSProperties = {
  backgroundColor: BauhausColor.primary.glass2,
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const $iconSizeType: Record<IconButtonSizeType, IconSizeType> = {
  XL: 'LG',
  LG: 'MD',
  MD: 'SM',
  SM: 'tiny',
  XS: 'micro',
};

export const getIconButtonStyle = (iconButtonSizeType: IconButtonSizeType): React.CSSProperties => {
  const size = IconButtonSize[iconButtonSizeType];
  return {
    ...$baseButtonStyle,
    height: size,
    width: size,
    borderRadius: size,
  };
};

export const getIconSize = (iconButtonSizeType: IconButtonSizeType): IconSizeType => {
  return $iconSizeType[iconButtonSizeType];
};
