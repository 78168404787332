import { EligibilityState, IsEligibleRequest } from '@amzn/electricfeel-gateway-client';
import { ElectricFeelGatewayService } from 'src/api/electricFeelGatewayService';
import { logger } from 'src/logger';

const client = new ElectricFeelGatewayService();

export const isUserEligibleForCampaign = async (campaignId: string): Promise<boolean> => {
  const request: IsEligibleRequest = {
    campaignId: campaignId,
  };

  logger.info(`Getting user eligibility status for campaign ${campaignId}`);

  return client
    .isEligible(request)
    .then((response) => {
      return response.eligibilityState === EligibilityState.ELIGIBLE;
    })
    .catch((error) => {
      logger.error(`Error getting user eligibility status for campaign ${campaignId}`, error);
      throw error;
    });
};
