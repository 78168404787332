import { SVGProps } from 'react';

const CancelIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M23.5234 21.2184L14.3046 11.9997L23.5234 2.78094C24.1589 2.14539 24.1589 1.11221 23.5234 0.476663C22.8878 -0.158888 21.8546 -0.158888 21.2191 0.476663L12.0003 9.69541L2.78159 0.476663C2.14603 -0.158888 1.11286 -0.158888 0.477305 0.476663C-0.158246 1.11221 -0.158246 2.14539 0.477305 2.78094L9.69606 11.9997L0.477305 21.2184C-0.159876 21.8556 -0.158246 22.8872 0.477305 23.5227C1.11286 24.1583 2.1444 24.1599 2.78159 23.5227L12.0003 14.304L21.2191 23.5227C21.8546 24.1583 22.8878 24.1583 23.5234 23.5227C24.1589 22.8872 24.1605 21.854 23.5234 21.2184Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default CancelIcon;
