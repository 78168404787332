import { GetProductDetailsResponse } from '@amzn/electricfeel-purchase-client';
import { Product } from 'src/models/product';

export const transformToProduct = (response: GetProductDetailsResponse): Product => {
  const productVariations =
    response.productVariations?.variationDimensions && response.productVariations?.variations
      ? {
          variationDimensions: response.productVariations.variationDimensions.map(
            (variationDimension) => {
              return {
                name: variationDimension.name,
                values: variationDimension.values.map((dimension) => dimension.value),
              };
            },
          ),
          variations: response.productVariations.variations,
        }
      : undefined;

  return {
    asin: response.asin,
    title: response.title,
    availability: {
      type: response.availability.type.toString(),
      primaryMessage: response.availability.primaryMessage,
    },
    shortDescriptionBullets: response.shortDescriptionBullets,
    price: response.price,
    primeBadge: response.primeBadge,
    productImages: {
      images: response.images.imageList.map((image) => {
        return {
          url: image.highRes?.url ?? image.lowRes?.url ?? '',
        };
      }),
      altText: response.images.altText,
    },
    productVariations: productVariations,
    quantity: {
      minOrderQuantity: response.quantity?.minOrderQuantity ?? 0,
      maxOrderQuantity: response.quantity?.maxOrderQuantity ?? 0,
    },
    offerId: response.offerId,
    merchantId: response.merchantId,
    privacyPolicyURL: response.privacyPolicyURL,
    conditionsOfUseURL: response.conditionsOfUseURL,
  };
};
