import { SVGProps } from 'react';

const GoBackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 24 24' fill='none'>
    <path
      d='M12.0002 24C11.693 24 11.3858 23.8824 11.1518 23.6484L0.351896 12.8485C-0.117299 12.3793 -0.117299 11.621 0.351896 11.1518L11.1518 0.351896C11.621 -0.117299 12.3793 -0.117299 12.8485 0.351896C13.3177 0.82109 13.3177 1.57948 12.8485 2.04867L2.89706 12.0002L12.8485 21.9516C13.3177 22.4208 13.3177 23.1792 12.8485 23.6484C12.6145 23.8824 12.3073 24 12.0002 24Z'
      fill={props.fill}
    />
  </svg>
);
export default GoBackIcon;
