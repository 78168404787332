import React from 'react';
import ArtistCelebrationBox from 'src/components/ArtistCelebrationBox/ArtistCelebrationBox';
import Modal from 'src/components/Modal/Modal';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { PageName } from 'src/metrics/constants';
import { Artist } from 'src/models/artist';

export interface CelebrationModalProps {
  campaignId: string;
  artist: Artist;
  onClose: () => void;
}

const CelebrationModal = ({ campaignId, artist, onClose }: CelebrationModalProps) => {
  const bundle = useStringsBundle();

  return (
    <Modal
      metrics={{
        pageName: PageName.CELEBRATION_MODAL,
        campaignId,
      }}
    >
      <ArtistCelebrationBox
        title={bundle.getMessage(StringIds.EligibilityCategoryTopListener)}
        artist={artist}
        subtitle={bundle.getMessage(StringIds.EligibilityUnlockMessageMerch)}
        cta={bundle.getMessage(StringIds.CtaCheckItOut)}
        onClick={onClose}
      />
    </Modal>
  );
};

export default CelebrationModal;
