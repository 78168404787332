import { DEFAULT_LOCALE } from 'src/localization/locales';
import { MonetaryValue } from 'src/models/common';
import { OrderCharge, OrderChargeType, ShippingAddress } from 'src/models/order';

export const findOrderChargeByType = (
  type: OrderChargeType,
  charges: OrderCharge[],
): OrderCharge | undefined => {
  return charges.find((charge) => charge.chargeType === type);
};

export const formatOrderChargeAmount = (
  type: OrderChargeType,
  charges: OrderCharge[],
  locale: string = DEFAULT_LOCALE,
): string => {
  const orderCharge = findOrderChargeByType(type, charges);

  if (orderCharge) {
    const orderChargeAmount = orderCharge.chargeAmount;
    return formatMonetaryValue(locale, orderChargeAmount);
  } else {
    return '-';
  }
};

export const formatMonetaryValue = (locale: string, monetaryValue: MonetaryValue) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: monetaryValue.currencyCode,
  }).format(monetaryValue.value);
};

export const formatShippingAddressText = (shippingAddress: ShippingAddress) => {
  const city = shippingAddress.city;
  const zipCode = shippingAddress.zipCode;

  if (city && zipCode) {
    return `${city}, ${zipCode}`;
  } else if (!city && zipCode) {
    return `${zipCode}`;
  } else if (city && !zipCode) {
    return `${city}`;
  } else {
    return `-`;
  }
};
