import { useLocalizationContext } from '@amzn/react-arb-tools';
import React, { useEffect, useState } from 'react';
import { checkout, placeOrder } from 'src/api/shopping';
import AmazonMusicLogo from 'src/assets/logos/AmazonMusicLogo';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import Button, { ButtonPreset } from 'src/components/Button/Button';
import CelebrationModal from 'src/components/CelebrationModal/CelebrationModal';
import EligibilityInfoModal from 'src/components/EligibilityInfoModal/EligibilityInfoModal';
import HintBox from 'src/components/HintBox/HintBox';
import LegalFooter from 'src/components/LegalFooter/LegalFooter';
import LoadingSpinnerWrapper from 'src/components/LoadingSpinner/LoadingSpinnerWrapper';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { DEFAULT_LOCALE } from 'src/localization/locales';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ActionName, ElementName, EntityIdType, EntityType } from 'src/metrics/constants';
import { Campaign } from 'src/models/campaign';
import { Order } from 'src/models/order';
import { Product, ProductAvailabilityType, Variation } from 'src/models/product';
import {
  AMAZON_PREORDER_TERMS,
  goToAmazonLogin,
  goToAmazonMusicArtistDetail,
  goToAmazonOrderHistory,
} from 'src/navigation/sites';
import {
  $sheetContainerStyle,
  preorderTextLinkStyle,
  preorderTextStyle,
} from 'src/pages/ProductDetailPage/ActionSheet/style';
import OrderCheckoutSheet from 'src/pages/ProductDetailPage/OrderCheckoutSheet/OrderCheckoutSheet';
import OrderConfirmationSheet from 'src/pages/ProductDetailPage/OrderConfirmationSheet/OrderConfirmationSheet';
import ProductSelectionSheet from 'src/pages/ProductDetailPage/ProductSelectionSheet/ProductSelectionSheet';
import { $actionSheetStyle } from 'src/pages/ProductDetailPage/style';

export type ActionSheetState = 'VerifyEligibility' | 'SelectProduct' | 'Checkout' | 'ConfirmOrder';

export interface ActionSheetProps {
  campaign: Campaign;
  product?: Product;
  productVariation?: Variation;
  onChangeVariation?: (variation: Variation) => void;
  onError: (error: any) => void;
  isLoggedIn: boolean;
  isEligible: boolean;
}

/**
 * The ActionSheet is a dynamic and core component of the ProductDetailPage,
 * responsible for displaying the action buttons given the following user's states:
 * 1. Logged out
 * 2. Logged in and ineligible
 * 3. Logged in and eligible
 *
 * For the last state, it will also render the full product checkout flow.
 */
const ActionSheet = ({
  campaign,
  product,
  productVariation,
  onChangeVariation,
  onError,
  isLoggedIn,
  isEligible,
}: ActionSheetProps) => {
  // NOTE: Due to the nature of the ProductDetailPage, we cannot record the different stages of the
  // action sheet purchase flow as subpages. Therefore, we will track it by emitting UIContentView
  // for each state.
  const { uiContentView } = useUiMetrics();
  uiContentView({
    elementName: ElementName.ACTION_SHEET_VERIFY_ELIGIBILITY,
  });

  // Localization variables
  const { localizationContext } = useLocalizationContext();
  const currentLocale = localizationContext?.getLocale() || DEFAULT_LOCALE;
  const bundle = useStringsBundle();

  // User experience variables
  const scrollToRef = React.useRef<HTMLDivElement>(null);
  const [actionSheetState, setActionSheetState] = useState<ActionSheetState>('VerifyEligibility');
  const [showCelebrationModal, setShowCelebrationModal] = useState<boolean>(false);
  const [showEligibilityInfoModal, setShowEligibilityInfoModal] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  // Checkout variables
  const [currentProduct, setCurrentProduct] = useState<Product | undefined>(product);
  const [quantity, setQuantity] = useState<number>(1);
  const [previewOrder, setPreviewOrder] = useState<Order>();
  const [confirmOrder, setConfirmOrder] = useState<Order>();

  // Campaign variables
  const artist = campaign.artist;
  const campaignId = campaign.campaignId;

  // If eligibility state changes, verify if the user is eligible to show the celebration box
  useEffect(() => {
    if (isEligible) {
      setShowCelebrationModal(true);
    }
  }, [isEligible]);

  // If the product changes at the parent level
  useEffect(() => {
    if (product) {
      setCurrentProduct(product);
      setQuantity(1);
    }
  }, [product]);

  // When the action sheet state changes, we will scroll down to that portion of the page
  useEffect(() => {
    // If `scrollToRef` points to an element, then scroll it into view.
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [actionSheetState]);

  const getButtons = (product: Product, isLoggedIn: boolean, isEligible: boolean) => {
    // Sold Out Case
    let primaryButtonText: string = bundle.getMessage(StringIds.CtaSoldOut);
    let primaryButtonAction: () => void = () => {
      goToAmazonMusicArtistDetail(artist.asin, '_blank');
    };
    let primaryButtonActionName = ActionName.GO_AMZN_MUSIC;
    let primaryButtonElementName = ElementName.CHECKOUT_MORE_ON_AM_BUTTON;

    const clickMetrics = {
      elementName: primaryButtonElementName,
      actionName: primaryButtonActionName,
      entityId: product.asin,
      entityIdType: 'ASIN' as EntityIdType,
      entityType: 'MERCH' as EntityType,
    };

    // NOTE: There are two possible cases for a sold out product:
    // 1. A product without variations: In this case, we will not let the customer proceed to buy.
    // 2. A product with variations: In this case, it's possible the primary ASIN is out of stock,
    // but one of the variations may be available. We will allow the customer to proceed to product selection.
    const isSoldOut = product.availability.type === ProductAvailabilityType.OUT_OF_STOCK;
    if (isSoldOut && !product.productVariations) {
      return (
        <>
          <Button
            preset={ButtonPreset.SOLID}
            size={'LG'}
            horizontalPadding={BauhausSpacing.large}
            text={primaryButtonText}
            disabled
            fullWidth
            onClick={() => {}}
            testId={formatTestId(TestIds.PrimaryButton)}
            metrics={{
              ...clickMetrics,
              elementName: ElementName.SOLD_OUT_BUTTON,
              actionName: ActionName.NONE,
            }}
          />
          <Spacer size={'small'} />
          <Button
            preset={ButtonPreset.OUTLINED}
            size={'LG'}
            horizontalPadding={BauhausSpacing.large}
            text={bundle.getMessage(StringIds.CtaCheckoutMoreOnAmazonMusic)}
            fullWidth
            onClick={primaryButtonAction}
            testId={formatTestId(TestIds.SecondaryButton)}
            metrics={clickMetrics}
          />
        </>
      );
    }

    const isPreorder = product.availability.type === ProductAvailabilityType.PREORDER;
    if (isLoggedIn) {
      // Logged In & Eligible Case
      if (isEligible) {
        primaryButtonText = isPreorder
          ? bundle.getMessage(StringIds.CtaPreorderNow)
          : bundle.getMessage(StringIds.CtaBuyNowWithAmazon);
        primaryButtonAction = () => {
          setActionSheetState('SelectProduct');
        };
        primaryButtonElementName = isPreorder
          ? ElementName.PREORDER_NOW_BUTTON
          : ElementName.BUY_NOW_BUTTON;
        primaryButtonActionName = ActionName.GO_PRODUCT_SELECTION;
        // Logged In & Ineligible Case
      } else {
        primaryButtonText = bundle.getMessage(StringIds.CtaListenOnAmazonMusic);
        primaryButtonElementName = ElementName.LISTEN_ON_AM_BUTTON;
      }
      // Logged Out Case
    } else {
      primaryButtonText = bundle.getMessage(StringIds.CtaSignIn);
      primaryButtonAction = () => {
        goToAmazonLogin(currentLocale);
      };
      primaryButtonElementName = ElementName.SIGN_IN_BUTTON;
      primaryButtonActionName = ActionName.GO_SIGN_IN;
    }

    return (
      <>
        <Button
          preset={ButtonPreset.SOLID}
          size={'LG'}
          horizontalPadding={BauhausSpacing.large}
          text={primaryButtonText}
          onClick={primaryButtonAction}
          testId={formatTestId(TestIds.PrimaryButton)}
          metrics={{
            ...clickMetrics,
            elementName: primaryButtonElementName,
            actionName: primaryButtonActionName,
          }}
        />
        {isPreorder && (
          <>
            <div style={preorderTextStyle}>
              <span>
                {bundle.getMessage(StringIds.PreorderPriceGuarantee)}
                {'. '}
              </span>
              <a style={preorderTextLinkStyle} href={AMAZON_PREORDER_TERMS}>
                {bundle.getMessage(StringIds.Terms)}
                {'. '}
              </a>
            </div>
            <Text
              text={product.availability.primaryMessage ?? ''}
              textAlign={'center'}
              preset={TextPreset.BODY_2}
              color={BauhausColor.primary.glass4}
            />
          </>
        )}
      </>
    );
  };

  const getContent = () => {
    if (currentProduct) {
      if (actionSheetState === 'SelectProduct') {
        return (
          <ProductSelectionSheet
            product={currentProduct}
            quantity={quantity}
            onChangeQuantity={setQuantity}
            variation={productVariation}
            onChangeVariation={(variation) => {
              if (currentProduct.productVariations) {
                onChangeVariation && onChangeVariation(variation);
              }
            }}
            onClickBackButton={() => setActionSheetState('VerifyEligibility')}
            onClickCtaButton={() => {
              setShowLoading(true);
              checkout(currentLocale, campaignId, currentProduct, quantity, productVariation)
                .then((response) => {
                  setPreviewOrder(response);
                  setActionSheetState('Checkout');
                })
                .catch((error) => onError(error))
                .finally(() => {
                  setShowLoading(false);
                });
            }}
            ref={scrollToRef}
          />
        );
      } else if (actionSheetState === 'Checkout' && previewOrder) {
        return (
          <OrderCheckoutSheet
            previewImage={currentProduct.productImages.images[0].url}
            previewOrder={previewOrder}
            additionalTerms={campaign.additionalTerms}
            onClickBackButton={() => setActionSheetState('SelectProduct')}
            onClickCtaButton={() => {
              setShowLoading(true);
              placeOrder(previewOrder.purchaseId)
                .then((response) => {
                  setConfirmOrder(response);
                  setActionSheetState('ConfirmOrder');
                })
                .catch((error) => onError(error))
                .finally(() => {
                  setShowLoading(false);
                });
            }}
            ref={scrollToRef}
          />
        );
      } else if (actionSheetState === 'ConfirmOrder' && confirmOrder) {
        return (
          <OrderConfirmationSheet
            product={currentProduct}
            order={confirmOrder}
            onClickCtaButton={() => goToAmazonOrderHistory('_blank')}
            ref={scrollToRef}
          />
        );
      } else {
        return (
          <>
            {showCelebrationModal && (
              <CelebrationModal
                campaignId={campaignId}
                artist={artist}
                onClose={() => setShowCelebrationModal(false)}
              />
            )}
            {showEligibilityInfoModal && (
              <EligibilityInfoModal
                campaignId={campaignId}
                onClose={() => setShowEligibilityInfoModal(false)}
              />
            )}
            <div style={{ ...$sheetContainerStyle, alignItems: 'center' }}>
              <HintBox
                imageSrc={artist.images.portraitUrl}
                text={
                  isLoggedIn && isEligible
                    ? bundle.formatMessage(StringIds.EligibilityMessageTopListenerEligible, {
                        artistName: artist.name,
                      })
                    : bundle.formatMessage(StringIds.EligibilityMessageTopListener, {
                        artistName: artist.name,
                      })
                }
                subtext={
                  isLoggedIn && !isEligible
                    ? bundle.getMessage(StringIds.EligibilityMessageTopListenerIneligible)
                    : ''
                }
                showHintIcon={!isLoggedIn}
                onClickHintIcon={() => {
                  setShowEligibilityInfoModal(true);
                }}
                testId={formatTestId(TestIds.HintBox)}
              />
              {getButtons(currentProduct, isLoggedIn, isEligible)}
              <Spacer size={'mini'} />
              <AmazonMusicLogo />
            </div>
            <LegalFooter />
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div style={$actionSheetStyle} data-testid={formatTestId(TestIds.ActionSheet)}>
        {getContent()}
      </div>
      {showLoading && <LoadingSpinnerWrapper />}
    </>
  );
};

export default ActionSheet;
