import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausTypography } from 'src/bauhaus/typography';
import { TextPreset } from 'src/components/Text/Text';

/**
 * Mobile
 */
const $headline1Style: React.CSSProperties = {
  color: BauhausColor.primary.solid,
  fontFamily: BauhausTypography.fontFamily.SharpGroteskPEBold,
  fontSize: BauhausTypography.fontSize.font1000,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $headline2Style: React.CSSProperties = {
  color: BauhausColor.primary.solid,
  fontFamily: BauhausTypography.fontFamily.SharpGroteskPEBold,
  fontSize: BauhausTypography.fontSize.font900,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $headline3Style: React.CSSProperties = {
  color: BauhausColor.primary.solid,
  fontFamily: BauhausTypography.fontFamily.SharpGroteskPESemibold,
  fontSize: BauhausTypography.fontSize.font800,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $headline4Style: React.CSSProperties = {
  color: BauhausColor.primary.solid,
  fontFamily: BauhausTypography.fontFamily.SharpGroteskPESemibold,
  fontSize: BauhausTypography.fontSize.font600,
  lineHeight: BauhausTypography.lineHeight.MD,
};

const $body1Style: React.CSSProperties = {
  color: BauhausColor.primary.solid,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberRegular,
  fontSize: BauhausTypography.fontSize.font400,
  lineHeight: BauhausTypography.lineHeight.MD,
};

const $body1BoldStyle: React.CSSProperties = {
  ...$body1Style,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberBold,
};

const $body2Style: React.CSSProperties = {
  color: BauhausColor.primary.glass4,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberRegular,
  fontSize: BauhausTypography.fontSize.font300,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body2BoldStyle: React.CSSProperties = {
  ...$body2Style,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberBold,
};

const $body3Style: React.CSSProperties = {
  color: BauhausColor.primary.glass4,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberRegular,
  fontSize: BauhausTypography.fontSize.font100,
  lineHeight: BauhausTypography.lineHeight.MD,
};

const $body3BoldStyle: React.CSSProperties = {
  ...$body3Style,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberBold,
};

const $body3CapsStyle: React.CSSProperties = {
  ...$body3Style,
  textTransform: 'uppercase',
};

const $body3BoldCapsStyle: React.CSSProperties = {
  ...$body3CapsStyle,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberBold,
};

const $bodyIndexStyle: React.CSSProperties = {
  color: BauhausColor.primary.glass4,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberBold,
  fontSize: BauhausTypography.fontSize.font50,
  lineHeight: BauhausTypography.lineHeight.SM2,
};

const $bodyLabelStyle: React.CSSProperties = {
  color: BauhausColor.accent.solid,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberBold,
  fontSize: BauhausTypography.fontSize.font50,
  lineHeight: BauhausTypography.lineHeight.SM2,
  textTransform: 'uppercase',
};

const $headline4BoldStyle: React.CSSProperties = {
  ...$headline4Style,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberBold,
  color: BauhausColor.primary.solid,
};

const $bodyXLRegularStyle: React.CSSProperties = {
  fontSize: BauhausTypography.fontSize.font700,
  lineHeight: BauhausTypography.lineHeight.SM2,
  fontFamily: BauhausTypography.fontFamily.AmazonEmberRegular,
  color: BauhausColor.primary.solid,
};

/**
 * Web
 */
const $headline1WebStyle: React.CSSProperties = {
  ...$headline1Style,
  fontSize: BauhausTypography.fontSize.font1200,
  lineHeight: BauhausTypography.lineHeight.SM,
};

const $headline2WebStyle: React.CSSProperties = {
  ...$headline2Style,
  fontSize: BauhausTypography.fontSize.font1100,
  lineHeight: BauhausTypography.lineHeight.SM,
};

const $headline3WebStyle: React.CSSProperties = {
  ...$headline3Style,
  fontSize: BauhausTypography.fontSize.font900,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $headline4WebStyle: React.CSSProperties = {
  ...$headline4Style,
  fontSize: BauhausTypography.fontSize.font700,
  lineHeight: BauhausTypography.lineHeight.LG,
};

const $body1WebStyle: React.CSSProperties = {
  ...$body1Style,
  fontSize: BauhausTypography.fontSize.font500,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body1BoldWebStyle: React.CSSProperties = {
  ...$body1BoldStyle,
  fontSize: BauhausTypography.fontSize.font500,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body1ParagraphWebStyle: React.CSSProperties = {
  ...$body1WebStyle,
  lineHeight: BauhausTypography.lineHeight.XL,
};

const $body2WebStyle: React.CSSProperties = {
  ...$body2Style,
  fontSize: BauhausTypography.fontSize.font300,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body2BoldWebStyle: React.CSSProperties = {
  ...$body2BoldStyle,
  fontSize: BauhausTypography.fontSize.font300,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body2ParagraphWebStyle: React.CSSProperties = {
  ...$body2WebStyle,
  lineHeight: BauhausTypography.lineHeight.XL,
};

const $body3WebStyle: React.CSSProperties = {
  ...$body3Style,
  fontSize: BauhausTypography.fontSize.font200,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body3BoldWebStyle: React.CSSProperties = {
  ...$body3BoldStyle,
  fontSize: BauhausTypography.fontSize.font200,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body3CapsWebStyle: React.CSSProperties = {
  ...$body3CapsStyle,
  fontSize: BauhausTypography.fontSize.font200,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body3BoldCapsWebStyle: React.CSSProperties = {
  ...$body3BoldCapsStyle,
  fontSize: BauhausTypography.fontSize.font200,
  lineHeight: BauhausTypography.lineHeight.SM1,
};

const $body3ParagraphWebStyle: React.CSSProperties = {
  ...$body3WebStyle,
  lineHeight: BauhausTypography.lineHeight.XL,
};

const $bodyIndexWebStyle: React.CSSProperties = {
  ...$bodyIndexStyle,
  fontSize: BauhausTypography.fontSize.font100,
  lineHeight: BauhausTypography.lineHeight.MD,
};

const $bodyLabelWebStyle: React.CSSProperties = {
  ...$bodyLabelStyle,
  fontSize: BauhausTypography.fontSize.font100,
  lineHeight: BauhausTypography.lineHeight.MD,
};

export const getTextPresetStyle = (textPreset: TextPreset) => {
  const textPresetStyles = {
    headline1: $headline1WebStyle,
    headline2: $headline2WebStyle,
    headline3: $headline3WebStyle,
    headline4: $headline4WebStyle,
    body1: $body1WebStyle,
    body1Bold: $body1BoldWebStyle,
    body1Paragraph: $body1ParagraphWebStyle,
    body2: $body2WebStyle,
    body2Bold: $body2BoldWebStyle,
    body2Paragraph: $body2ParagraphWebStyle,
    body3: $body3WebStyle,
    body3Bold: $body3BoldWebStyle,
    body3Caps: $body3CapsWebStyle,
    body3BoldCaps: $body3BoldCapsWebStyle,
    body3Paragraph: $body3ParagraphWebStyle,
    bodyIndex: $bodyIndexWebStyle,
    bodyLabel: $bodyLabelWebStyle,
    headline4Bold: $headline4BoldStyle,
    bodyXLRegular: $bodyXLRegularStyle,
  };

  return textPresetStyles[textPreset];
};
