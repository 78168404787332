import moment from 'moment';
import { BauhausColor } from 'src/bauhaus/color';
import {
  orderDetailContainerStyle,
  orderDetailContentStyle,
} from 'src/components/OrderDetailBox/style';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { OrderItem } from 'src/models/order';

import { Spacer } from '../Spacer/Spacer';

export interface OrderDetailBoxProps {
  image: string;
  orderItem: OrderItem;
  textPreset: TextPreset;
  testId?: string;
}

const OrderDetailBox = (props: OrderDetailBoxProps) => {
  const bundle = useStringsBundle();

  const date = new Date(0).setUTCSeconds(parseInt(props.orderItem.estimatedArrivalDate));
  const localDateTime = moment(date).format('MMM DD, YYYY');

  return (
    <div style={orderDetailContainerStyle} data-testid={formatTestId(TestIds.OrderDetailBox)}>
      <div style={orderDetailContentStyle}>
        <Text
          text={bundle.formatMessage(StringIds.OrderDetailArrivalDate, {
            arrivalDate: localDateTime,
          })}
          preset={props.textPreset}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderDetailBox, 'ArrivalDateText')}
        />
        <Spacer size='mini' />
        <Text
          lines={2}
          truncate
          text={props.orderItem.title}
          preset={props.textPreset}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderDetailBox, 'ProductTitleText')}
        />
        <Text
          text={bundle.formatMessage(StringIds.OrderDetailQuantity, {
            itemQuantity: props.orderItem.quantity,
          })}
          preset={props.textPreset}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderDetailBox, 'OrderQuantityText')}
        />
      </div>
    </div>
  );
};

export default OrderDetailBox;
