import { createContext, useContext } from 'react';
import { ElementName } from 'src/metrics/constants';
import { UiClick } from 'src/metrics/events/UiClick';
import { UiContentView } from 'src/metrics/events/UiContentView';

type ExcludedProps =
  | 'contentInfo'
  | 'csSessionId'
  | 'interactionType'
  | 'pageEntityId'
  | 'pageEntityIdType'
  | 'pageEntityType'
  | 'pageSubType'
  | 'pageType';

export interface UiClickProps extends Omit<UiClick, ExcludedProps> {
  elementName: ElementName;
}

export interface UiContentViewProps extends Omit<UiContentView, ExcludedProps> {
  elementName: ElementName;
}

export interface UiMetricsStore {
  uiClick: (payload: UiClickProps) => void;
  uiContentView: (payload: UiContentViewProps) => void;
}

export const UiMetricsContext = createContext<UiMetricsStore>({
  uiClick: () => undefined,
  uiContentView: () => undefined,
});

export const useUiMetrics = (): UiMetricsStore => useContext(UiMetricsContext);
