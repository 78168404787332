const TrophyIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='45' height='44' viewBox='0 0 45 44' fill='none'>
      <path
        d='M42.5179 18.75C43.5897 20.6064 43.5897 22.8936 42.5179 24.75L35.1071 37.5861C34.0353 39.4425 32.0545 40.5861 29.9109 40.5861L15.0891 40.5861C12.9455 40.5861 10.9647 39.4425 9.89295 37.5861L2.48205 24.75C1.41026 22.8936 1.41026 20.6064 2.48205 18.75L9.89295 5.91395C10.9647 4.05754 12.9455 2.91395 15.0891 2.91395L29.9109 2.91395C32.0545 2.91395 34.0353 4.05754 35.1071 5.91395L42.5179 18.75Z'
        fill='#FFFFF2'
      />
      <path
        d='M26.4394 32.6999L26.935 33.6912H18.0645L18.5601 32.6999C18.936 31.9481 19.7044 31.4735 20.5438 31.4735H24.4557C25.2951 31.4735 26.0635 31.9481 26.4394 32.6999Z'
        fill='black'
      />
      <path
        d='M34.6969 17.0588V21.4941C34.6969 22.7194 33.7045 23.7118 32.4792 23.7118H29.5042C28.4553 25.9172 26.4029 27.5483 23.9324 28.0096L24.7174 30.3647H20.2821L21.0672 28.0096C18.5967 27.5483 16.5443 25.9172 15.4954 23.7118H12.5204C11.2951 23.7118 10.3027 22.7194 10.3027 21.4941V17.0588C10.3027 15.8336 11.2951 14.8412 12.5204 14.8412H14.738V13.7324C14.738 12.5071 15.7304 11.5147 16.9557 11.5147H28.0439C29.2692 11.5147 30.2616 12.5071 30.2616 13.7324V14.8412H32.4792C33.7045 14.8412 34.6969 15.8336 34.6969 17.0588Z'
        fill='black'
      />
      <path d='M14.7372 17.0589H12.5195V21.4942H14.7372V20.3854V17.0589Z' fill='#FFFFF2' />
      <path
        d='M26.5246 17.7663C26.4503 17.5378 26.2374 17.3826 25.9968 17.3826H23.727L23.0262 15.2248C22.8777 14.768 22.1203 14.768 21.9718 15.2248L21.271 17.3826H19.0023C18.7617 17.3826 18.5488 17.5378 18.4745 17.7663C18.4002 17.9947 18.4812 18.2453 18.6763 18.3861L20.5114 19.72L19.8107 21.8789C19.7364 22.1073 19.8173 22.3579 20.0125 22.4987C20.1089 22.5675 20.2231 22.6029 20.3373 22.6029C20.4516 22.6029 20.5658 22.5675 20.6633 22.4965L22.4996 21.1637L24.3347 22.4976C24.5298 22.6395 24.7915 22.6384 24.9866 22.4976C25.1807 22.3568 25.2616 22.1062 25.1884 21.8778L24.4877 19.7189L26.3228 18.385C26.5168 18.2442 26.5978 17.9947 26.5246 17.7663Z'
        fill='#FFFFF2'
      />
      <path d='M32.4794 17.0589H30.2617V20.3854V21.4942H32.4794V17.0589Z' fill='#FFFFF2' />
    </svg>
  );
};

export default TrophyIcon;
