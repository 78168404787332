import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import {
  orderIdChargeContainerStyle,
  outContainerStyle,
} from 'src/components/OrderConfirmationBox/style';
import OrderDetailBox from 'src/components/OrderDetailBox/OrderDetailBox';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatTestId, TestIds } from 'src/components/testIds';
import Text, { TextPreset } from 'src/components/Text/Text';
import { useUiMetrics } from 'src/components/UiMetricsWrapper/useUiMetrics';
import { StringIds } from 'src/localization/strings';
import { useStringsBundle } from 'src/localization/useStringsBundle';
import { ElementName } from 'src/metrics/constants';
import { OrderItem } from 'src/models/order';

export interface OrderConfirmationBoxProps {
  id: string;
  totalCharge: string;
  image: string;
  items: OrderItem[];
  onClickButton: () => void;
}

const OrderConfirmationBox = (props: OrderConfirmationBoxProps) => {
  const { uiContentView } = useUiMetrics();
  uiContentView({
    elementName: ElementName.ORDER_CONFIRMATION_BOX,
    entityId: props.items[0].asin,
    entityIdType: 'ASIN',
    entityType: 'MERCH',
    responseId: props.id,
    responseIdType: 'ORDER_ID',
  });

  const bundle = useStringsBundle();

  return (
    <div style={outContainerStyle} data-testid={formatTestId(TestIds.OrderConfirmationBox)}>
      <div style={orderIdChargeContainerStyle}>
        <Text
          text={`#${props.id}`}
          preset={TextPreset.HEADLINE_4}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderConfirmationBox, 'OrderIdText')}
        />
        <Text
          text={props.totalCharge}
          preset={TextPreset.HEADLINE_4}
          color={BauhausColor.primary.solid}
          testId={formatTestId(TestIds.OrderConfirmationBox, 'OrderTotalChargeText')}
        />
      </div>
      <Spacer size='mini' />
      <Text
        text={bundle.getMessage(StringIds.OrderConfirmationText)}
        preset={TextPreset.BODY_1}
        color={BauhausColor.primary.solid}
        testId={formatTestId(TestIds.OrderConfirmationBox, 'OrderNotificationText')}
      />
      <Spacer size='large' />
      {props.items[0] && (
        <OrderDetailBox
          image={props.image}
          textPreset={TextPreset.BODY_3}
          orderItem={props.items[0]}
          testId={formatTestId(TestIds.OrderDetailBox)}
        />
      )}
      <Spacer size='huge' />
      <Text
        text={bundle.getMessage(StringIds.OrderConfirmationQuestion)}
        preset={TextPreset.BODY_2}
        textAlign='center'
        color={BauhausColor.primary.solid}
        testId={formatTestId(TestIds.OrderConfirmationBox, 'UpdateOrderText')}
      />
    </div>
  );
};

export default OrderConfirmationBox;
