import { SupportedLocaleEnum } from '@amzn/electricfeel-purchase-client';
import { Locale } from 'src/localization/locales';

export const transformToSupportedLocale = (locale: string) => {
  if (locale === Locale.ES_US) {
    return SupportedLocaleEnum.ES_US;
  } else {
    return SupportedLocaleEnum.EN_US;
  }
};
