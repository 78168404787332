import React from 'react';
import { BauhausBlur, BauhausBlurType } from 'src/bauhaus/blur';

export const getStyle = (image: string, blur?: BauhausBlurType): React.CSSProperties => {
  const blurValue = blur ? `blur(${BauhausBlur[blur]}px)` : undefined;

  return {
    backgroundImage: `url("${image}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    filter: blurValue,
    WebkitFilter: blurValue,
  };
};
