/**
 * Roles for colors.
 *
 * Different from the palette in that the palette are simply the names of the colors.
 * Names in colors should be function-based
 *
 * Learn more about Bauhaus colors here:
 * https://quip-amazon.com/g3v3A7SQrV1D/Color
 */

import { BauhausColorPalette } from 'src/bauhaus/palette';

const getRGBA = (colorPalette: { R: number; G: number; B: number }, alpha: number = 1) => {
  return `rgba(${colorPalette.R}, ${colorPalette.G}, ${colorPalette.B}, ${alpha})`;
};

/**
 * Glass opacity levels for semi-transparent components
 */
const opacity = {
  alpha0: 0,
  alpha50: 0.05,
  alpha150: 0.15,
  alpha300: 0.3,
  alpha600: 0.6,
  alpha750: 0.75,
  alpha950: 0.95,
};

/**
 * Neutral. Only neutral have glass values.
 * Primary. The default color of text in many components.
 */
const primary = {
  solid: getRGBA(BauhausColorPalette.neutral0),
  /** Primary glass with opacity.alpha50 */
  glass1: getRGBA(BauhausColorPalette.neutral0, opacity.alpha50),
  /** Primary glass with opacity.alpha150 */
  glass2: getRGBA(BauhausColorPalette.neutral0, opacity.alpha150),
  /** Primary glass with opacity.alpha300 */
  glass3: getRGBA(BauhausColorPalette.neutral0, opacity.alpha300),
  /** Primary glass with opacity.alpha600 */
  glass4: getRGBA(BauhausColorPalette.neutral0, opacity.alpha600),
  /** Primary glass with opacity.alpha750 */
  glass5: getRGBA(BauhausColorPalette.neutral0, opacity.alpha750),
  /** Primary glass with opacity.alpha7950 */
  glass6: getRGBA(BauhausColorPalette.neutral0, opacity.alpha950),
};

const secondary = {
  solid: getRGBA(BauhausColorPalette.neutral900),
  /** Secondary glass with opacity.alpha50 */
  glass1: getRGBA(BauhausColorPalette.neutral900, opacity.alpha50),
  /** Secondary glass with opacity.alpha150 */
  glass2: getRGBA(BauhausColorPalette.neutral900, opacity.alpha150),
  /** Secondary glass with opacity.alpha300 */
  glass3: getRGBA(BauhausColorPalette.neutral900, opacity.alpha300),
  /** Secondary glass with opacity.alpha600 */
  glass4: getRGBA(BauhausColorPalette.neutral900, opacity.alpha600),
  /** Secondary glass with opacity.alpha750 */
  glass5: getRGBA(BauhausColorPalette.neutral900, opacity.alpha750),
  /** Secondary glass with opacity.alpha950 */
  glass6: getRGBA(BauhausColorPalette.neutral900, opacity.alpha950),
};

const base = {
  solid: getRGBA(BauhausColorPalette.neutral700),
  /** Base glass with opacity.alpha50 */
  glass1: getRGBA(BauhausColorPalette.neutral700, opacity.alpha50),
  /** Base glass with opacity.alpha150 */
  glass2: getRGBA(BauhausColorPalette.neutral700, opacity.alpha150),
  /** Base glass with opacity.alpha300 */
  glass3: getRGBA(BauhausColorPalette.neutral700, opacity.alpha300),
  /** Base glass with opacity.alpha600 */
  glass4: getRGBA(BauhausColorPalette.neutral700, opacity.alpha600),
  /** Base glass with opacity.alpha750 */
  glass5: getRGBA(BauhausColorPalette.neutral700, opacity.alpha750),
  /** Base glass with opacity.alpha950 */
  glass6: getRGBA(BauhausColorPalette.neutral700, opacity.alpha950),
};

/**
 * Expressive Palette
 */
const accent = {
  solid: getRGBA(BauhausColorPalette.cyan100),
  primaryGlass4: getRGBA(BauhausColorPalette.cyan50),
  secondaryGlass3: getRGBA(BauhausColorPalette.cyan200),
  secondaryGlass4: getRGBA(BauhausColorPalette.cyan300),
  onSecondaryGlass3: getRGBA(BauhausColorPalette.cyan400),
  onSecondaryGlass2: getRGBA(BauhausColorPalette.cyan500),
};

/**
 * Badges
 */
const prime = {
  solid: getRGBA(BauhausColorPalette.primeBlue),
};

const hd = {
  solid: getRGBA(BauhausColorPalette.hdYellow),
};

/**
 * Functional
 */
const error = {
  solid: getRGBA(BauhausColorPalette.errorRed),
};

const live = {
  solid: getRGBA(BauhausColorPalette.liveRed),
};

const transparent = getRGBA(BauhausColorPalette.neutral900, opacity.alpha0);

export const BauhausColor = {
  primary,
  secondary,
  base,
  accent,
  prime,
  hd,
  error,
  live,
  transparent,
  opacity,
};
