import React from 'react';
import { BauhausColor } from 'src/bauhaus/color';
import { BauhausSpacing } from 'src/bauhaus/spacing';
import { getTextPresetStyle } from 'src/components/Text/style';
import { TextPreset } from 'src/components/Text/Text';

export const $sheetContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '500px',
};

export const $sheetHeaderStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: BauhausSpacing.base,
  justifyContent: 'space-between',
  marginBottom: BauhausSpacing.small,
  width: '100%',
};

export const preorderTextStyle: React.CSSProperties = {
  marginTop: BauhausSpacing.base,
  ...getTextPresetStyle(TextPreset.BODY_2),
  color: BauhausColor.primary.glass4,
  textAlign: 'center',
};

export const preorderTextLinkStyle: React.CSSProperties = {
  color: BauhausColor.accent.solid,
};
