import { SVGProps } from 'react';

const CaretUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M21.9996 17.9999C21.4876 17.9999 20.9756 17.8039 20.5856 17.4139L12 8.8283L3.41436 17.4139C2.63239 18.1959 1.36844 18.1959 0.586476 17.4139C-0.195492 16.632 -0.195492 15.368 0.586476 14.5861L10.5861 4.58648C11.368 3.80451 12.632 3.80451 13.4139 4.58648L23.4135 14.5861C24.1955 15.368 24.1955 16.632 23.4135 17.4139C23.0235 17.8039 22.5116 17.9999 21.9996 17.9999Z'
        fill={props.fill}
      />
    </svg>
  );
};

export default CaretUpIcon;
