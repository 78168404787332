import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

// See https://gears.corp.amazon.com/Association/amzn_music_fancommerce_preprod_us_us
const ASSOCIATION_HANDLE_PREPROD = 'amzn_music_fancommerce_preprod_us_us';

// See https://gears.corp.amazon.com/Association/amzn_music_fancommerce_us
const ASSOCIATION_HANDLE_PROD = 'amzn_music_fancommerce_us';

// See https://gears.corp.amazon.com/PageId/amzn_music_fancommerce
const UI_CUSTOMIZATION_HANDLE = 'amzn_music_fancommerce';

// See https://w.amazon.com/index.php/IdentityServices/AuthenticationPortal/Cookies#Example_cookie_names_by_region
export const AmazonLoginCookies = {
  // Authentication cookie used as a token for secure transactions
  atMain: 'at-main',
  // Recognition cookie, used to load customer-specific data server-side in cases where the user has authenticated previously in that browser,
  // but the operation does not require an authentication
  xMain: 'x-main',
  // A unique ID which is used to maintain and represent data created by the customer’s actions within the browser
  // The value of session-id is maintained when the customer logs in
  sessionId: 'session-id',
  // A unique browser ID which is meant to permanently identify the browser and assist with security and fraud checks,
  // as well as tracking information on shared browsers
  ubidMain: 'ubid-main',
};

const hasCookie = (name: string) => {
  return !!Cookies.get(name);
};

export const isUserAuthenticated = () => hasCookie(AmazonLoginCookies.xMain);

export const getUserSessionId = () => Cookies.get(AmazonLoginCookies.sessionId);

export const getUserDeviceId = () => {
  // Set deviceId to Ubid if exists, otherwise generate a random deviceId and set it to cookie
  let deviceId = Cookies.get(AmazonLoginCookies.ubidMain);
  if (!deviceId) {
    const localDeviceIdKey = 'deviceId';
    const localDeviceId = Cookies.get(localDeviceIdKey);
    if (localDeviceId) {
      deviceId = localDeviceId;
    } else {
      deviceId = uuidv4();
      Cookies.set(localDeviceIdKey, deviceId || '');
    }
  }
  return deviceId;
};

export const getAmazonLoginUrl = (locale: string) => {
  const associationHandle = window.location.hostname.includes('aka.amazon.com')
    ? ASSOCIATION_HANDLE_PREPROD
    : ASSOCIATION_HANDLE_PROD;

  return (
    'https://www.amazon.com/ap/signin?_encoding=UTF8' +
    `&pageId=${UI_CUSTOMIZATION_HANDLE}` +
    `&openid.assoc_handle=${associationHandle}` +
    '&openid.claimed_id=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0%2fidentifier_select' +
    '&openid.identity=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0%2fidentifier_select' +
    '&openid.mode=checkid_setup' +
    '&openid.ns=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0' +
    '&openid.ns.pape=http%3A%2F%2Fspecs.openid.net%2Fextensions%2Fpape%2F1.0' +
    '&openid.pape.max_auth_age=1200' +
    `&openid.return_to=${window.location.href}` +
    `&language=${locale.replace('-', '_')}`
  );
};

export const getSignOutUrl = (locale: string) => {
  const associationHandle = window.location.hostname.includes('aka.amazon.com')
    ? ASSOCIATION_HANDLE_PREPROD
    : ASSOCIATION_HANDLE_PROD;
  return (
    'https://www.amazon.com/ap/signin?_encoding=UTF8' +
    `&pageId=${UI_CUSTOMIZATION_HANDLE}` +
    '&openid.mode=logout' +
    `&openid.assoc_handle=${associationHandle}` +
    '&openid.claimed_id=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0%2fidentifier_select' +
    '&openid.identity=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0%2fidentifier_select' +
    '&openid.mode=checkid_setup' +
    '&openid.ns=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0' +
    '&openid.ns.pape=http%3A%2F%2Fspecs.openid.net%2Fextensions%2Fpape%2F1.0' +
    '&openid.pape.max_auth_age=1200' +
    `&openid.return_to=${window.location.href}` +
    `&language=${locale.replace('-', '_')}`
  );
};
