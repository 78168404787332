/**
 * Spacing
 * For Bauhaus spacing guidelines please see:
 * https://quip-amazon.com/K3bhA7zeXX9g#RMC9AAMjYGh
 */

export const BauhausSpacing = {
  nano: 2,
  micro: 4,
  mini: 8,
  small: 12,
  base: 16,
  medium: 20,
  large: 24,
  huge: 32,
  giant: 36,
  epic: 48,
};

export type BauhausSpacingType = keyof typeof BauhausSpacing;
